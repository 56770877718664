<div class="container mt-4 gdpn">
  <h2>General Data Protection Notice for Customers and Business Partners</h2>

  <h2>Data Processing Information for the ZF Websites</h2>
  <p>
    This Data Processing Information explains how personal data about you is
    collected, processed and used by ZF Friedrichshafen AG (hereinafter “ZF,”
    “we,” or “us”) as data controller when you use the ZF Websites available
    under zf.com.
  </p>

  <p>
    At ZF, we take our responsibility to protect your personal data serious. As
    used in this Data Processing Information, “personal data” means any
    information – either alone or in combination with other information we can
    access – that relates to you as an identified or identifiable individual. We
    respect your right to privacy and will only process your personal data in
    accordance with applicable data protection laws. This Data Processing
    Information applies to infor-mation we collect when you use the ZF Websites
    and its functionalities.
  </p>

  <h2>1. Information on data collection and use</h2>
  <p>
    We collect certain information every time you visit our ZF Websites. This
    information is used for the purposes which we list below. The specific types
    of personal data we collect depend upon your activities on the ZF Websites,
    as also further explained below.
  </p>
  <h3>1.1 Information that is automatically collected</h3>

  <p>
    When you use the ZF Websites without registration, you are not required to
    actively provide certain personal data; however, we automatically collect
    and process the following information:
  </p>

  <p>
    technical session and connection information including IP address of your
    device; browser type; information about your device, hardware and software;
    preferences; web pages you visited just before the ZF Websites; pages you
    viewed on the ZF Websites and dates and times of your visit.
  </p>

  <p>
    We process and use the data specified above for the purposes of (1)
    providing the ZF Websites and its functionalities; (2) improving the ZF
    Website’s features and functionalities; and (3) preventing and detecting
    misuse and malfunction of the ZF Websites including troubleshooting.
  </p>

  <p>
    Collection of this type of information occurs inter alia by means of
    cookies. For more detailed information on the types of cookies and their
    specific purposes, please see Sec. 2 below.
  </p>

  <p>
    The processing and use of the data is based on statutory provisions which
    justify those operations on the grounds that (1) the processing is necessary
    for the performance of the contract about the use of the ZF Websites; or (2)
    ZF has a legitimate interest in ensuring the functionality and error-free
    operation of the ZF Websites and that it is tailored to the user’s needs.
  </p>

  <h3>1.2 User requests, account creation and signing-in</h3>

  <p>
    When you create a ZF Account (which is required for some parts of the ZF
    Websites), we collect the information you provide in the registration
    process, which may include:
  </p>

  <p>
    First name and surname, sex, email address, phone numbers, area of activity,
    industry category, name and type of your organisation, company’s address.
  </p>

  <p>
    The same data is processed when the user fills in the contact form on the
    website, e.g. the user sends a request for technical information.
  </p>

  <p>
    We process and use the information specified above for the purposes of (1)
    checking your eligibility for and the administration of your ZF Account; (2)
    performing the Terms of Use regarding the ZF Websites and any and all rights
    and obligations thereunder; (3) tailoring the ZF Websites and the content to
    your needs; and (4) communicating with you by sending you technical notices,
    updates, security alerts and support and administrative messages, respond to
    your questions and requests and provide customer service.
  </p>

  <p>
    The processing and use of the data is based on statutory provisions which
    justify those operations on the grounds that (1) the processing is necessary
    for the performance of the Agreement including the Terms of Use regarding
    the ZF Websites; or (2) ZF has a legitimate interest in ensuring the
    functionality of the ZF Websites and that it is tailored to the user’s
    needs.
  </p>

  <p>
    If you create or log in to a ZF Account we use your login data (user name
    and password) for the purpose of providing and administrating your access to
    the ZF Websites and – as the case maybe – further online services by ZF or
    third parties which are accessible through the ZF Websites. The information
    you provide in order to register (user name and password) is processed to
    check and verify your authorisation and to handle requests from you to reset
    your password.
  </p>

  <h3>1.3 Further services</h3>

  <p>
    You may provide further information to us when you order further services
    through the ZF Websites; such information may include: delivery address;
    payment information, etc. The collection and use of such additional
    information is governed by a separate Data Processing Information for the
    particular service.
  </p>

  <h2>2. Cookies</h2>
  <p>
    We use cookies, a standard Internet technology that allows us to store and
    retrieve login and other usage information for every user of the ZF
    Websites. “Cookies” are small text files that are stored by your web browser
    on your computer's hard drive. They allow ZF to store such things as user
    preferences. The ZF Websites uses both session cookies and persistent
    cookies. Session cookies are temporary cookies that expire (and are
    automatically erased) when you close your browser. Persistent cookies stay
    in your browser until they expire, or until you delete them.
  </p>

  <p>
    Cookies allow us to recognise your device (e.g. your laptop or mobile
    device) so that your experience of our ZF Websites can be tailored to your
    device. Cookies do not typically identify you as an individual, just the
    device you are using. We use cookies to save information such as passwords
    and preferences so you don’t have to re-enter it each time you visit our ZF
    Websites, to deliver content tailored to your interests and to optimise the
    performance of the ZF Websites. The information connected with the cookie
    can also be visitors' preferences and record session information, such as
    pages visited, interactions with the ZF Websites’ features, time spent on
    our ZF Websites and language preference.
  </p>

  <p>
    Most internet browsers allow you to block cookies from your browser. If you
    do not agree to the use of these cookies please disable them by following
    the instructions for your browser set out here:
    https://www.allaboutcookies.org/ or use the automated disabling tool where
    available. However, some of the services in relation to the ZF Websites will
    not function well if cookies are disabled.
  </p>

  <p>
    Below, we have provided a list of the categories of cookies used on our ZF
    Websites, and a description of what these cookies do. Some of these cookies
    are essential to the operation of the ZF Websites. Others are not essential,
    but help us to improve our ZF Websites by collecting anonymous user
    information, or try and improve your experience of our ZF Websites by
    remembering your choices.
  </p>

  <p>We use the following types of cookies:</p>

  <p>
    Strictly necessary cookies: – which enable services and applications you
    have specifically asked for
  </p>

  <p>
    We use certain cookies which are essential to the operation of our ZF
    Websites. For example, these types of cookies enable you to log into secure
    areas of our ZF Websites, provide the necessary security your visit to our
    ZF Websites requires, and help ensure the content of the pages you request
    loads quickly. Without these cookies, ZF Websites services that you have
    asked for cannot be provided as required.
  </p>

  <p>
    The use of these cookies implicates processing the following type of data:
    [anonymous browser session ID, permission groups, user ID]. As far as this
    data must be considered personal data, the processing is based on statutory
    provisions which justify this on the ground that the processing is necessary
    to provide the requested ZF Websites services.
  </p>

  <p>
    Functionality cookies: – which remember your choices to improve your
    experience
  </p>

  <p>
    These cookies allow our ZF Websites to remember choices you make, such as
    remembering your preferred language, the type of device you are using, the
    region you are in. The aim of these cookies is to provide you with a more
    personal experience so that you don't have to reset your preferences each
    time you visit the ZF Websites. As described above, you may disable any of
    these functional cookies; but if you do so, then various functions of our ZF
    Websites may be unavailable to you or may not work the way you want them to.
  </p>

  <p>
    The use of these cookies implicates processing the following type of data:
    [device ID, preferred language]. As far as this data must be considered
    personal data, the processing is based on statutory provisions which justify
    this on the ground that (1) the processing is necessary to provide the
    requested ZF Websites services; or (2) ZF has a legitimate interest in
    ensuring the functionality of the ZF Websites and that it is tailored to the
    user’s needs.
  </p>

  <p>
    Performance/analytics cookies: – which collect pseudonymous information
    about the web pages you visit
  </p>

  <p>
    These cookies collect information about how visitors use our ZF Websites,
    for example, which pages our visitors go to most often, and if they get
    error messages on those pages. These performance cookies don't collect
    information that identifies you as an individual – all information they
    collect is aggregated and pseudonymous (inter alia we remove the last two
    octets of your IP address). We do not associate usage data collected through
    these cookies with personal data which you may have provided when
    registering for a ZF Account (e. g. your name).
  </p>

  <p>
    On the ZF Websites, we use technologies of Piwik PRO GmbH to collect and
    store data about your use of the ZF Websites for the purposes of
    optimization of the ZF Websites services and marketing. Usage profiles may
    be created from this data under a pseudonym using cookies.
  </p>

  <p>
    As far as this data must be considered personal data, the processing is
    based on statutory provisions which justify this on the ground that (1) ZF
    has a legitimate interest that the ZF Websites is tailored to the user’s
    needs; or (2) the data is used in pseudonymous form for the purpose of
    marketing and optimization purposes and the user has not objected to this
    use. You may object to the collection and processing by Piwik PRO’s tracking
    technology at any time with effect for the future by clicking this link
  </p>

  <h3>Cookie List</h3>
  <p>
    A cookie is a small piece of data (text file) that a website – when visited
    by a user – asks your browser to store on your device in order to remember
    information about you, such as your language preference or login
    information. Those cookies are set by us and called first-party cookies.
  </p>
  <h4>Strictly Necessary Cookies</h4>
  <p>
    These cookies are necessary for the website to function and cannot be
    switched off in our systems. They are usually only set in response to
    actions made by you which amount to a request for services, such as setting
    your privacy preferences, logging in or filling in forms. You can set your
    browser to block or alert you about these cookies, but some parts of the
    site will not then work.
  </p>
  <p>
    In Local Storage:<br />
    accessToken, email, expires, iss, realname, refreshToken, roles, tenantid,
    tenantName, username<br />
    Cookies:<br />
    cookieconsent_status
  </p>
  <h2>3. Sharing data within the ZF Group</h2>
  <p>
    ZF is a company located in Germany which operates globally and is part of
    the ZF group of companies, the current members of which are specified here
    (“ZF Group”). The data you provide to us in the course of registration for a
    ZF Account (see no. 1.2 above), will be stored in our centralised customer
    data base in Germany and shared within the ZF Group for the purpose of
    managing your relationship with ZF and the ZF Group.
  </p>

  <p>
    The sharing and use of the data is based on statutory provisions which
    justify those operations on the grounds that (1) the processing is necessary
    for the performance of the Agreement including the Terms of Use regarding
    the ZF Websites; or (2) ZF has a legitimate interest in sharing this data
    within the ZF Group for internal administrative purposes.
  </p>

  <p>
    The ZF Account information relating to you might be processed outside of
    your local jurisdiction and the European Economic Area including, in the
    following countries/regions e.g. US, Mexico, Canada, Ukraine, Turkey,
    Russia, Greater China, Switzerland, Serbia, Japan, UAE, India, Australia,
    Singapore, Indonesia, Korea, Thailand, Malaysia, Vietnam, Philippines, South
    Africa, Algeria, Tunisia, Mauritius, Argentina, Brazil and Columbia. Where
    we are required not to transfer your personal data out of your local
    jurisdiction as per the applicable laws on data protection in your local
    jurisdiction, we will take appropriate measures to ensure the compliance
    with such requirements.
  </p>

  <p>
    Any transfer of your personal data to a third country (i. e. which is not a
    member of EU/EEA) will continue to be in compliance with all applicable data
    protection laws.
  </p>

  <p>
    As far as a third country is not recognized by the European Commission or in
    your local jurisdiction to provide an adequate level of protection, ZF will
    take appropriate safeguards to ensure adequate protection of your data; e.g.
    by concluding data transfer agreements with third country recipients
    comprising standard contractual clauses which in accordance with the
    decisions of the European Commission provide adequate safeguards and can be
    requested under dataprotection@zf.com
  </p>

  <h3>Sharing data outside of the ZF Group</h3>

  <p>
    In order to provide certain Applications and Services we share your data
    with particular external parties which perform services on our behalf. We
    may use third party service providers to offer or facilitate services on our
    behalf, such as a newsletter service provider, IT-provider, web hosting
    provider as well as any other service provider which act as data processors
    and therefore process the data on our behalf and under our instructions.
    These third parties are prohibited from using your personal data for their
    own commercial, promotional or any other purposes than those explicitly
    instructed for and they have to follow our express instructions and comply
    with appropriate security measures to protect your personal data.
  </p>

  <p>
    We might disclose personal information when required by law to comply with a
    current judicial proceeding, a court order or legal process served on us, or
    as otherwise required by any applicable law, rule or regulation.
  </p>

  <h2>4. Detecting and prosecute misuse</h2>
  <p>
    We retain usage data including IP-addresses for the purpose of detecting and
    – as the case maybe – prosecuting misuse of the ZF Websites. Unless required
    for prosecution purposes, the data will be deleted after 7 days. The
    retention and use of the data is based on statutory provisions which justify
    those operations on the grounds that (1) the processing is necessary for the
    performance of the Agreement including the Terms of Use regarding the ZF
    Websites; or (2) ZF has a legitimate interest in ensuring the functionality
    and security of the ZF Websites.
  </p>

  <h2>5. Deletion of data</h2>
  <p>
    Generally, we delete personal data as soon as it is no longer necessary in
    relation to the purposes for which the data was initially collected or
    processed. In case the processing is based on consent and you withdraw your
    consent we will delete your personal data without undue delay after the
    withdrawal unless we are legally required to retain the data.
  </p>

  <p>
    With respect to registered users, ZF will annually send a reminder to
    re-confirm the information he provided to ZF when registering for the ZF
    Account. Should the user not confirm his Account information, the Account
    will be deactivated. ZF will delete the ZF Account and related data after
    another year after the deactivation in case the user does not provide the
    relevant confirmation.
  </p>

  <h2>6. Your rights</h2>
  <p>
    You are generally entitled to request from ZF access to and rectification or
    erasure of personal data that we store about you or – as the case maybe -
    restriction of processing as far as it concerns you or to object to
    processing as well as the assert your right to data portability; all as
    granted by applicable data protection laws. You also have the right to lodge
    a complaint with a supervisory authority.
  </p>

  <h2>7. Contact</h2>
  <p>
    In case of any request concerning our use, integrity and/or accuracy of your
    personal data or in case you want to assert any of your rights mentioned
    under Sec. 9, you may contact us by email at datenschutz@zf.com or ZF
    Privacy Webform.
  </p>

  <p>The contact details of our data protection officer are as follows:</p>

  <p>
    ZF Friedrichshafen AG<br />
    Data Protection Officer<br />
    Löwentaler Straße 20<br />
    88046 Friedrichshafen
  </p>
  <p>Germany</p>
  <h2>8. Changes to this data protection information</h2>
  <p>
    As our collection and processing of your data may change over time, we might
    also modify this Data Protection Information in order for it to always
    correctly reflect our data processing practices. We encourage you to review
    it from time to time.
  </p>
</div>
