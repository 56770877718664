import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { patch, append } from '@ngxs/store/operators';
// Module
import { NotificationMessage } from './../../models/notification-message.model';
import { Notifications } from './../../store/notifications/notifications.action';

export interface NotificationsStateModel {
  messages: NotificationMessage[];
}
const INITIAL_STATE: NotificationsStateModel = { messages: [] };
const NOTIFICATIONS_STATE_TOKEN = new StateToken<NotificationsStateModel>(
  'notifications'
);

@State<NotificationsStateModel>({
  name: NOTIFICATIONS_STATE_TOKEN,
  defaults: INITIAL_STATE,
})
@Injectable()
export class NotificationsState {
  @Selector()
  static messages(state: NotificationsStateModel): NotificationMessage[] {
    return state.messages;
  }
  @Selector()
  static lastMessage(state: NotificationsStateModel): NotificationMessage {
    return state.messages[state.messages.length - 1];
  }
  @Action(Notifications.AddNotification)
  addNotification(
    ctx: StateContext<NotificationsStateModel>,
    { payload }: Notifications.AddNotification
  ): void {
    ctx.setState(
      patch({
        messages: append([payload]),
      })
    );
  }
}
