import * as moment from 'moment';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { MAINTENANCE_STATES } from './../../../core';

@Component({
  selector: 'idm-incident-maintenance',
  templateUrl: './incident-maintenance.component.html',
  styleUrls: ['./incident-maintenance.component.scss'],
})
export class IncidentMaintenanceComponent implements OnChanges {
  @Output()
  updateStateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() minDate: any;
  @Input() initData: any;

  private today: string = moment.utc(Date.now()).toISOString();

  public selectedState: boolean;
  public selectedDate: string;
  public datepickerDisabled = true;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    let state = false;
    // Set to initialdata on default
    if (this.initData) {
      state =
        this.initData.state === MAINTENANCE_STATES.REPAIRED ? true : false;
      this.selectedDate = this.initData.repairDate;
    }
    // Reset the data if the component is disabled
    if (this.disabled) {
      state = false;
      this.initData = null;
      this.selectedDate = null;
    }
    this.setState(state);
  }

  updateDate(date: string): void {
    const setDate = date !== '1899-11-30T00:00:00.000Z' ? date : this.today;
    if (setDate !== this.selectedDate) {
      this.updateState(setDate);
    }
  }

  setState(state?: boolean): void {
    if (typeof state !== 'undefined') {
      this.selectedState = state;
    } else {
      this.selectedState = !this.selectedState;
    }
    this.datepickerDisabled = !this.selectedState;
    this.updateState();
  }

  updateState(date?: string): void {
    const valid = !(this.selectedState && !date);
    this.cdr.markForCheck();
    if (this.disabled === true) {
      date = null;
    }
    if (typeof date !== 'undefined') {
      this.selectedDate = date;
    }
    // Dont update on initial data load
    if (!this.initData && valid) {
      this.updateStateEvent.emit({
        type: 'maintenance',
        state:
          this.selectedState === true
            ? MAINTENANCE_STATES.REPAIRED
            : MAINTENANCE_STATES.UNREPAIRED,
        date: this.selectedState === true ? this.selectedDate : null,
      });
    } else {
      this.initData = null;
    }
    this.cdr.detectChanges();
  }
}
