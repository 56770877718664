import * as moment from 'moment-timezone';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CustomHttpParamEncoder, Train } from './../../core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { COMPONENT_STATES } from './../enums/component-states.enum';
import { MaintenanceStateModel } from './../models/maintenance-state.model';
import { environment } from './../../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService implements OnDestroy {
  private incidentsApi = environment.api + '/im/incidents';
  private maintenanceApi = environment.api + '/maintenanceReport';
  private configurationApi = environment.api + '/tcm';

  private _vehicle$: BehaviorSubject<Train | any> = new BehaviorSubject<Train>(
    null
  );
  private ngUnsubscribe$: Subject<boolean> = new Subject();
  private _maintenanceComponentState: COMPONENT_STATES;

  constructor(private http$: HttpClient) {}

  public setSelectedVehicle(vehicle: Train | any) {
    this._vehicle$.next(vehicle);
  }

  public getActiveIncidentsByVehicle(vehicle: string): Observable<any> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: {
        trainId: vehicle,
        // UNREPAIRED und CONFIRMED relevant (zusätzlich zu OPEN)
        // kann auch ein Zug sein, der aktuell keine Incidents hat! (false-negative)
        // AUTO_REMOVED nicht relevant
        // UND/ODER: Brauchen wir Markierungen
        // BEI CONFIRMED: OPEN und CONFIRMED erhalten "Häkchen", bei batch save state change OPEN -> CONFIRMED
        // CONFIRMNED bleiben erhalten, ggf. noch Maintenance done
        // Wartungsablauf am besten mal visualisieren.
        // User Journey: (Was passiert, wenn der Wagen in die Werkstatt kommt. Welche Abläufe könnten relevant sein?)
        // false positive => REJECTED
        cStates: ['OPEN', 'CONFIRMED', 'REJECTED'],
        dateTo: moment.utc().toISOString(),
        dateFrom: moment.utc().subtract(1, 'years').toISOString(),
      },
    });
    return this.http$.get(this.incidentsApi + '/byTrain/byFilter?' + params);
  }

  /**
   * The default set of the date is not self-explanatory
   * We have to set it to ensafe API compatibility and the repairdate is not allowed to be
   * before the inspection date
   * But the date with no matching checked element will be ignored in the backend
   * @param data
   * @returns
   */
  public saveMaintenanceReport(
    data: MaintenanceStateModel
  ): Observable<unknown> {
    if (!data.inspectionDate) {
      data.inspectionDate = new Date().toISOString();
      if (data.repairDate) data.inspectionDate = data.repairDate;
    }
    if (!data.repairDate) {
      data.repairDate = new Date().toISOString();
      if (data.inspectionDate) data.repairDate = data.inspectionDate;
    }
    return this.http$.post(this.maintenanceApi, data);
  }

  public loadConfiguration(trainId: string): Observable<Train | any> {
    const params = new HttpParams().set('id', trainId);
    return this.http$
      .get(this.configurationApi + '/configurations/byTrain', {
        params: params,
      })
      .pipe(first());
  }

  set maintenanceComponentState(state: COMPONENT_STATES) {
    this._maintenanceComponentState = state;
  }
  get maintenanceComponentState(): COMPONENT_STATES {
    return this._maintenanceComponentState;
  }

  get vehicle$(): Observable<Train> {
    return this._vehicle$.asObservable();
  }

  set train$(value: Train) {
    this._vehicle$.next(value);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
