import { fadeInAndOutAnimation } from '@idm/core';
import { FleetService } from '@idm/fleet/services/fleet.service';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { delayWhen, distinctUntilChanged, tap } from 'rxjs/operators';
@Component({
  selector: 'idm-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FleetDetailsComponent implements OnDestroy, AfterViewInit {
  public details: any = null;
  private subs: Array<Subscription> = new Array<Subscription>();

  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay);

  constructor(private fleetService: FleetService) {}

  public ngAfterViewInit(): void {
    this.subs.push(
      this.fleetService.details$
        .pipe(
          tap(() => (this.details = null)),
          distinctUntilChanged(),
          delayWhen(this.delayFor)
        )
        .subscribe(details => {
          this.details = details;
        })
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
