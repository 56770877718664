export interface AuthenticationStateModel {
  accessToken: string | null;
  refreshToken: string | null;
  iss: string | null;
  tenantName: string | null;
  tenantId: string | null;
  username: string | null;
  realname: string | null;
  email: string | null;
  roles: string[] | null;
  expires: number | null;
}
// tslint:disable-next-line: no-namespace
export namespace Authentication {
  export class Login {
    static readonly type = '[IAM] Authentication Login';
    constructor(public payload: { username: string; password: string }) {}
  }
  export class Logout {
    static readonly type = '[IAM] Authentication Logout';
  }
  export class Refresh {
    static readonly type = '[IAM] Authentication Token Refreshed';
  }
}
