<div class="p-5">
  <h4>Current configuration</h4>
  <div
    class="pt-3"
    [@fade]="
      (model$ | async)?.trainStructure?.bogies &&
      (model$ | async)?.gateways?.length > 0
    "
    *ngIf="
      (model$ | async)?.trainStructure?.bogies &&
        (model$ | async)?.gateways?.length > 0;
      else emptyConfig
    ">
    <div id="pdf-temp"></div>
    <div class="w-100 pb-5" id="train-configuration">
      <div class="hidden-for-pdf mb-4">
        <div class="header d-flex flex-row">
          <div class="mr-3">
            <img alt="ZF Logo" src="assets/images/zf-logo.svg" />
          </div>
          <div class="flex-column">
            <h4 class="mb-0" id="print-out-header">
              ZF IDM System | Train Configuration Print Out
            </h4>
            <div class="d-flex flex-row">
              <div class="vehicle">
                {{ (vehicle$ | async).name }} ({{ (model$ | async).type }}) |
                {{ (vehicle$ | async).fleet }}
              </div>
            </div>
          </div>
          <div class="ml-auto text-right date d-flex">
            <div class="mr-3 mt-auto align-self-baseline">
              {{ today | date: 'medium' }}
            </div>
            <div>
              <qrcode
                [qrdata]="qrCode"
                [margin]="0"
                [width]="75"
                elementType="svg"
                errorCorrectionLevel="M"></qrcode>
            </div>
          </div>
        </div>
      </div>
      <idm-train
        [model]="model$ | async"
        [viewConfig]="trainViewConfiguration"
        (tagClicked)="onTagClick($event)"></idm-train>
      <div class="hidden-for-pdf components">
        <table class="table" aria-describedby="print-out-header">
          <thead>
            <tr>
              <th colspan="3" id="spanned-cols"></th>
              <th scope="colgroup">HD-TAG</th>
              <th scope="colgroup">ProPM Gateway</th>
            </tr>
            <tr>
              <th scope="rowgroup" style="text-align: center">Bogie</th>
              <th scope="col">Wheel Position</th>
              <th scope="col">Wheel Name</th>
              <th scope="col">BLE ID</th>
              <th scope="col">Serialnr.</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let bogie of (model$ | async).trainStructure?.bogies;
                let j = index
              ">
              <tr *ngFor="let slot of bogie.slots; let i = index">
                <th
                  class="rowgroup"
                  scope="rowgroup"
                  *ngIf="i === 0"
                  [rowSpan]="bogie.slots?.length"
                  [class.first-row]="i === 0"
                  [class.last]="
                    j % (model$ | async).trainStructure?.bogies.length ===
                    (model$ | async).trainStructure?.bogies.length - 1
                  ">
                  {{ bogie.name }}
                </th>
                <td
                  [class.first-row]="i === 0 && j === 0"
                  [class.even]="i % 2 === 0"
                  [class.break]="i % bogie.slots?.length === 0"
                  [class.last]="
                    j % (model$ | async).trainStructure?.bogies.length ===
                      (model$ | async).trainStructure?.bogies.length - 1 &&
                    i % bogie.slots?.length === bogie.slots?.length - 1
                  ">
                  {{ slot.name }}
                </td>
                <td
                  [class.first-row]="i === 0 && j === 0"
                  [class.even]="i % 2 === 0"
                  [class.break]="i % bogie.slots?.length === 0"
                  [class.last]="
                    j % (model$ | async).trainStructure?.bogies.length ===
                      (model$ | async).trainStructure?.bogies.length - 1 &&
                    i % bogie.slots?.length === bogie.slots?.length - 1
                  ">
                  {{ bogie?.wheel || '-' }}
                </td>
                <td
                  [class.first-row]="i === 0 && j === 0"
                  [class.even]="i % 2 === 0"
                  [class.break]="i % bogie.slots?.length === 0"
                  [class.last]="
                    j % (model$ | async).trainStructure?.bogies.length ===
                      (model$ | async).trainStructure?.bogies.length - 1 &&
                    i % bogie.slots?.length === bogie.slots?.length - 1
                  ">
                  {{ (slot.tag?.bleId | uppercase) || '-' }}
                </td>
                <td
                  [class.first-row]="i === 0 && j === 0"
                  [class.even]="i % 2 === 0"
                  [class.break]="i % bogie.slots?.length === 0"
                  [class.last]="
                    j % (model$ | async).trainStructure?.bogies.length ===
                      (model$ | async).trainStructure?.bogies.length - 1 &&
                    i % bogie.slots?.length === bogie.slots?.length - 1
                  ">
                  {{ slot.tag?.device || '-' }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="action d-flex justify-content-end">
      <button
        class="btn btn-primary mr-4 ml-4 mb-4"
        (click)="openPDF()"
        [disabled]="generatingPdf$ | async">
        <span
          class="material-icons pdf-download"
          *ngIf="!(generatingPdf$ | async)"
          >picture_as_pdf</span
        >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="generatingPdf$ | async"></span>
        Download PDF
      </button>
    </div>
  </div>
  <ng-template #emptyConfig>
    <div class="progress mt-4">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        [ngStyle]="{ 'width.%': progress }">
        Loading the configuration...
      </div>
    </div>
  </ng-template>
</div>

<div #replaceModal></div>
