import { Store } from '@ngxs/store';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
// Modules
import { Loading } from '../store/loading/loading.action';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  /** NOTE: Once ran into a expression has changed error with the loading$ Observable in one template, this was mainly
   * because of preloading svgs before the initial rendering of the page, if this error still occurs: analyze the request,
   * maybe add more filters or change the way of providing the observable for the template:
   *
   * Refer: https://blog.angular-university.io/angular-debugging/
   */

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('.svg') === -1) {
      this.store.dispatch(
        new Loading.Start({
          type: 'global',
          source: request.url,
        })
      );
      return next.handle(request).pipe(
        delay(500),
        finalize(() => {
          this.store.dispatch(new Loading.End());
        })
      );
    }
    return next.handle(request);
  }
}
