import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AbstractVehicleViewComponent } from './../../../abstracts/abstract-vehicle-view.component';
import { TAG_STATE } from './../../../enums/tag-state.enum';

@Component({
  selector: 'idm-train-wheel',
  templateUrl: './train-wheel.component.html',
  styleUrls: ['./train-wheel.component.scss'],
})
export class TrainWheelComponent extends AbstractVehicleViewComponent {
  @Input() fieldPosition: string; // top or bottom / optional combined with left right
  @Input() slot: number;

  @Output() errorMesage = new EventEmitter<string>();

  public TAG_STATE = TAG_STATE;

  public maxLength = 15;
  public minLength = 0;
  public error = false;
  public suspicious = false;
  public maintained = false;
  // Currently uncoupled, can be added to input options if an action is possible and
  // the wheel clickable in that case
  public action = false;

  super() {
    if (!this.fieldPosition) this.fieldPosition = 'top';
  }

  public onModelChange(value: string): void {
    const checkDuplicates = this.duplicates(value);
    if (
      value.length >= this.minLength &&
      value.length <= this.maxLength &&
      !checkDuplicates
    ) {
      this.error = false;
      this.model.wheel = value;
      super.onModelChange(value);
    } else if (checkDuplicates) {
      this.error = true;
      this.errorMesage.emit('Duplicate names are not allowed, please change.');
    } else {
      this.error = true;
      this.errorMesage.emit(
        'Please change the marked wheel name, there are max ' +
          this.maxLength +
          ' characters allowed.'
      );
    }
  }

  public duplicates(name: string): boolean {
    const currentModel = this.model;
    return currentModel.trainStructure.bogies.some(bogie =>
      bogie?.slots.some(slot => slot?.wheel === name)
    );
  }

  public onTagClick(): void {
    this.tagClicked.emit({
      wheel: this.model.name,
    });
  }
}
