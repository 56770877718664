import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleFilter',
})
export class RoleFilterPipe implements PipeTransform {
  transform(allRoles: string[], allowedRole: string) {
    return allRoles.some(role => role === allowedRole);
  }
}
