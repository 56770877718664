<div class="mr-3" *ngIf="markers.length > 0">
  <h4 class="mt-0 pt-3">Position</h4>
  <div class="d-flex incident-map w-100">
    <google-map
      height="500px"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options">
      <map-marker
        #markerElem="mapMarker"
        *ngFor="let marker of markers"
        [position]="marker.position"
        [options]="marker.options"
        (mapClick)="openInfo(markerElem)">
      </map-marker>
      <map-info-window>
        <div class="p-2">
          <div class="info-incident-type d-flex flex-row mb-2">
            <div class="m-auto">
              <span class="material-icons failure">info</span>
            </div>
            <div class="w-100 ml-2">{{ incidentType }}</div>
          </div>
          <div class="info-position">
            Lat={{ infoPosition?.latitude }} / Long={{
              infoPosition?.longitude
            }}
          </div>
        </div>
      </map-info-window>
    </google-map>
  </div>
</div>
