import { fadeInAndOutAnimation } from './../../../core/animations/fade-in-and-out.animation';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FleetConfigurationService,
  FleetConfiguratorEditTagsModalComponent,
  FleetService,
  GATEWAY_STATE,
  TAG_ACTION_TYPES,
  TAG_STATE,
} from './../../../fleet';
import { delay, filter, first, takeUntil, tap } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Store } from '@ngxs/store';
import { Modal } from './../../../core';
import {
  TagActivationForm,
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../vehicles';

@Component({
  selector: 'idm-fleet-configuration',
  templateUrl: './fleet-configuration.component.html',
  styleUrls: ['./fleet-configuration.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FleetConfigurationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public model$: BehaviorSubject<TrainConfiguration> =
    new BehaviorSubject<TrainConfiguration>(null);
  public generatingPdf$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public progress = 0;
  public currentVehicleId: string;
  public vehicle$: Observable<unknown>;
  public today: unknown = new Date();
  public qrCode = 'Something';

  public trainViewConfiguration: TrainViewConfiguration = {
    actions: {
      connectAll: false,
      validateConfig: false,
    },
    gateways: {
      editable: false,
      visible: true,
      counter: false,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: true,
          replaceable: true,
          visible: true,
        },
      },
    },
  };

  @ViewChild('replaceModal', { read: ViewContainerRef })
  replaceModal: ViewContainerRef;
  private ngUnsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private fleetService: FleetService,
    private fleetConfigurationService: FleetConfigurationService,
    private router: Router,
    private store: Store,
    private viewContainerRef: ViewContainerRef
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((__event: NavigationStart) => {
        this.unsubsribe();
      });
    this.qrCode = window.location.href;
  }

  ngOnInit(): void {
    this.vehicle$ = this.fleetService.vehicle$;
  }

  ngAfterViewInit(): void {
    this.buildConfiguration();
  }

  private buildConfiguration(): void {
    this.fleetService.vehicle$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        delay(0),
        tap(() => {
          this.model$.next(new TrainConfiguration());
          this.progress = 100;
        }),
        delay(250),
        filter(vehicle => vehicle !== null)
      )
      .subscribe(vehicle => {
        this.currentVehicleId = vehicle.id;
        this.fleetConfigurationService
          .loadConfiguration(vehicle.id)
          .pipe(first())
          .subscribe((config: TrainConfiguration) => {
            if (config?.gateways?.length > 0) {
              config?.gateways.forEach(gateway => {
                gateway['state'] = GATEWAY_STATE.connected;
              });
              this.model$.next(config);
            } else {
              this.model$.next(config);
              this.injectGateways();
            }
          });
      });
  }

  private injectGateways(): void {
    this.fleetService.gateways$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(gateways => gateways !== null),
        first()
      )
      .subscribe((gateways: Array<any>) => {
        const modifiedModel = this.model$.getValue();
        if (!modifiedModel.gateways) modifiedModel.gateways = new Array<any>();
        gateways.forEach(gateway => {
          if (!modifiedModel.gateways.find(g => g.serial === gateway.sn)) {
            modifiedModel.gateways.push({
              serial: gateway.sn,
              state: GATEWAY_STATE.connected,
            });
          }
        });
        this.model$.next(modifiedModel);
      });
  }

  public openPDF(): void {
    this.generatingPdf$.next(true);
    // Subscription and delay are necessary to get the spinner working before
    // the html2 canvas process blocks the operation of switching the state and spinner
    this.generatingPdf$
      .pipe(
        filter(state => state === true),
        delay(25),
        first()
      )
      .subscribe(() => {
        const DATA = document.getElementById('train-configuration');
        const pdfTemp = document.getElementById('pdf-temp');
        // Clone the configuration HTMLElement with its hidden nodes and then
        // change the display property and copy them into the temp container
        // Why this seemms to be neccessary:
        // the onclone hook doesn't update the canvas size after updating the
        // display property, the whole image is then cropped at the height without
        // the now visible elements
        // Maybe there is a better solution available. Getting the html with jspdf.html
        // didn't work either because it would need separate CSS defs.
        const DATAClone = <HTMLElement>DATA.cloneNode(true);
        const hiddenEls: HTMLCollection =
          DATAClone.getElementsByClassName('hidden-for-pdf');
        Array.from(hiddenEls).forEach((el: HTMLElement) => {
          el.style.display = 'block';
        });
        DATAClone.innerHTML = DATAClone.innerHTML.replace(
          /Not Configured/gi,
          'N.Config.'
        );
        pdfTemp.appendChild(DATAClone);
        html2canvas(DATAClone, {
          allowTaint: true,
          useCORS: true,
          logging: true,
          onclone: __clone => {
            // Currently doing nothing, but lets keep it to remember the hook
          },
        })
          .then(canvas => {
            const leftmargin = 15;
            const topmargin = 12;
            const fileWidth = 210 - leftmargin * 2;
            const fileHeight = (canvas.height * fileWidth) / canvas.width;
            const FILEURI = canvas.toDataURL('image/png');
            const PDF = new jsPDF('p', 'mm', 'a4');
            PDF.addImage(
              FILEURI,
              'PNG',
              leftmargin,
              topmargin,
              fileWidth,
              fileHeight
            );
            PDF.save(
              new Date(Date.now()).toISOString() +
                '_' +
                this.fleetService.vehicle$.getValue().name +
                '_configuration' +
                '.pdf'
            );
            this.generatingPdf$.next(false);
            pdfTemp.removeChild(DATAClone);
          })
          .catch(error => {
            console.log('ERROR GENERATING PDF', error);
            pdfTemp.removeChild(DATAClone);
          });
      });
  }

  private unsubsribe(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.model$.complete();
    this.generatingPdf$.complete();
  }

  public onTagClick(value: any): void {
    const currentModel = this.model$.getValue();
    const bogie = currentModel.trainStructure.bogies.find(
      fBogie => fBogie.name === value.bogie
    );
    const slot = bogie.slots.find(fSlot => fSlot.name === value.wheel);
    const bleId = slot?.tag?.bleId ? slot.tag.bleId : '';
    let gateway = currentModel.gateways.find(fGateway => {
      return fGateway?.tags?.find((tag: string) => tag === bleId);
    });
    if (!gateway && currentModel.gateways.length > 0)
      gateway = currentModel.gateways[0];
    this.createModal(
      value?.wheel,
      value?.bogie,
      bleId,
      gateway ? gateway.serial : ''
    ).then(__done =>
      this.store.dispatch(new Modal.Show('fleet-configurator-edit-tags-modal'))
    );
  }

  public onSelect(data: TagActivationForm): any {
    const currentModel = this.model$.getValue();
    const bogies = currentModel.trainStructure.bogies;
    const gateway = currentModel.gateways.find(
      fFateway => fFateway.serial === data.gateway
    );
    const bogie = bogies.find(fBogie => fBogie.name === data.bogie);
    if (typeof bogie !== 'undefined') {
      const slot = bogie.slots.find(fSlot => fSlot.name === data.wheel);
      if (typeof slot !== 'undefined') {
        slot.tag = { bleId: data.mac, status: { type: TAG_STATE.connected } };
      }
    }
    if (typeof gateway?.tags !== 'undefined') {
      gateway.tags.push(data.mac);
    } else {
      gateway.tags = new Array<string>(data.mac);
    }
    // Should detach
    this.replaceModal.clear();
    // Model has changed, reload info and inventory from service
    this.fleetService.loadGatewaysByVehicle(this.currentVehicleId);
  }

  public createModal(
    wheel: string,
    bogie: string,
    bleId?: string,
    gateway?: string
  ): Promise<string> {
    return new Promise((resolve, __reject) => {
      this.removeModal();
      const componentRef = this.viewContainerRef.createComponent(
        FleetConfiguratorEditTagsModalComponent
      );
      componentRef.instance.gateways = this.model$.getValue().gateways;
      componentRef.instance.id = 'fleet-configurator-replace-tags-modal';
      componentRef.instance.actionType = bleId
        ? TAG_ACTION_TYPES.replace
        : TAG_ACTION_TYPES.new;
      componentRef.instance.wheel = wheel;
      componentRef.instance.bogie = bogie;
      componentRef.instance.bleId = bleId;
      componentRef.instance.gateway = gateway;
      componentRef.instance.selected.subscribe(data => {
        this.onSelect(data);
      });
      resolve('done');
    });
  }

  public removeModal() {
    this.viewContainerRef.clear();
  }

  ngOnDestroy(): void {
    this.unsubsribe();
  }
}
