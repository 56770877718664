import { BehaviorSubject, Subscription } from 'rxjs';
import {
  GATEWAY_STATE,
  TAG_STATE,
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../../vehicles';
import { Items, fadeInAndOutAnimation } from './../../../../core';

import { Component } from '@angular/core';
import { FleetConfigurationService } from './../../../../fleet/services/fleet-configuration.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Component({
  selector: 'idm-fleet-configurator-save',
  templateUrl: './fleet-configurator-save.component.html',
  animations: [fadeInAndOutAnimation],
})
export class FleetConfiguratorSaveComponent {
  public done: boolean;
  public model: TrainConfiguration;
  public trainViewConfiguration: TrainViewConfiguration = {
    actions: {
      connectAll: false,
      validateConfig: false,
    },
    gateways: {
      editable: false,
      visible: true,
      counter: true,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: false,
          replaceable: false,
          visible: true,
        },
      },
    },
  };

  public validConfig = !this.trainViewConfiguration.actions.validateConfig;

  public error$: BehaviorSubject<string>;
  public fcs: FleetConfigurationService;

  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(
    private fleetConfigurationService: FleetConfigurationService,
    private store: Store,
    private router: Router
  ) {
    this.fcs = this.fleetConfigurationService;
    // initial for configuration, it depends on the initial configuration state of the button
    this.error$ = this.fleetConfigurationService.error$;
    this.fleetConfigurationService.valid$.next(this.validConfig);
    this.subs.push(
      this.fleetConfigurationService
        .getConfiguration()
        .subscribe(configuration => {
          if (Object.keys(configuration).length > 0) this.model = configuration;
        })
    );
    this.subs.push(
      this.fleetConfigurationService.done$.subscribe(done => {
        this.done = done;
        if (done === true) {
          this.trainViewConfiguration.actions.validateConfig = false;
        }
      })
    );
  }

  public reset(): void {
    this.fleetConfigurationService.reset();
  }

  public generatePdf(): void {
    console.log('CANT DO THIS AT THE MOMENT');
  }

  public gotoTrain(): void {
    this.store
      .dispatch(new Items.Refresh())
      .toPromise()
      .then(() =>
        this.router.navigateByUrl(
          '/fleets/manager/info/vehicle/' +
            this.fcs.lastConfiguredTrainId$.getValue()
        )
      );
  }

  public onConnectGatwaysClicked(): void {
    this.model.gateways.forEach(gateway => {
      gateway.state = GATEWAY_STATE.connected;
    });
  }

  public onConnectTagsClicked(): void {
    this.model.trainStructure.bogies.forEach(bogie => {
      bogie.slots.forEach((slot: any) => {
        slot.status.type = TAG_STATE.connected;
      });
    });
  }

  public onValidateConfigurationClicked(): void {
    this.validConfig = true;
    this.fleetConfigurationService.valid$.next(this.validConfig);
  }
}
