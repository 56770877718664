import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Modal } from '@idm/core';
import { fadeInAndOutAnimation } from '@idm/core/animations/fade-in-and-out.animation';
import { NotificationMessageType } from '@idm/core/models/notification-message-type.enum';
import { ContactService } from '@idm/core/services/contact.service';
import { Notifications } from '@idm/core/store/notifications/notifications.action';
import { Store } from '@ngxs/store';
@Component({
  selector: 'idm-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class ContactComponent implements OnInit {
  public contactForm: FormGroup;
  private error = false;

  constructor(
    private builder: FormBuilder,
    private contactService: ContactService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.contactForm = this.builder.group({
      type: new FormControl('', [Validators.required]),
      message: new FormControl('', [
        Validators.required,
        Validators.maxLength(1000),
      ]),
    });
  }

  submit(data: any): void {
    this.contactService.getRecipient(data).subscribe(
      (response: any) => this.handleSuccess(response),
      (error: any) => this.handleError(error),
      () => this.handleCompletion()
    );
    // Subsribe to and handle response
    // Maybe show Message in Notificationbar and clear fields (depends on api, e.g. handling via http status code or content)
    // Show Errors
    // Reset after submit
  }

  private handleSuccess(__response: any) {
    this.error = false;
    this.store.dispatch(
      new Notifications.AddNotification({
        type: NotificationMessageType.success,
        msg: 'Your email has been send, thanks! Our service team will contact you as soon as possible!',
      })
    );
  }

  private handleError(__error: any) {
    this.error = true;
  }

  private handleCompletion() {
    this.contactForm.reset();
    this.contactForm.controls['type'].setValue('');
    this.hideContact();
  }

  hideContact(): void {
    this.store.dispatch(new Modal.Hide());
  }
}
