<div class="form-group">
  <div class="custom-control custom-switch maintenance-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      id="maintenance"
      [(ngModel)]="selectedState"
      value="confirmed"
      #incidentFeedbackState="ngModel"
      (click)="setState()"
      [disabled]="disabled" />
    <label class="custom-control-label" for="maintenance"
      >Maintenance done</label
    >
  </div>
  <div class="d-flex flex-row w-75">
    <idm-datepicker-popup
      [disabled]="datepickerDisabled"
      [initDate]="selectedDate"
      [minDate]="minDate"
      label="Repair date"
      (dateSelectEvent)="updateDate($event)"></idm-datepicker-popup>
  </div>
</div>
