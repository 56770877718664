import { ConfigurationSteps } from './../models/configuration-steps.model';
import { ValidationStates } from './../models/validation-states.model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { CancelModalComponent } from './../../core/components/cancel-modal/cancel-modal.component';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { FleetService } from './fleet.service';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Injectable, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { Items, Modal } from './../../core';
import { environment } from '../../../environments/environment';
import { CustomHttpError } from '..';
import {
  TrainConfiguration,
  GatewayConfiguration,
  BogieConfiguration,
} from './../../vehicles';

@Injectable({
  providedIn: 'root',
})
export class FleetConfigurationService {
  private fleetApi = environment.api + '/fm';
  private configurationApi = environment.api + '/tcm';
  private activationsApi = environment.activationApi + '/activations';
  private replacementsApi = environment.replacementsApi;

  // NOTE: Shouldn't we procede automatically to the next step? Maybe as an option?
  public active = false;
  public configuration: BehaviorSubject<TrainConfiguration> =
    new BehaviorSubject(null);
  public activeStep$: BehaviorSubject<number> = new BehaviorSubject(1);
  public finalStep$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public prevActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public nextActive$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public validationStates$: BehaviorSubject<ValidationStates> =
    new BehaviorSubject({
      hasTrain: false,
      hasGateways: false,
      hasTags: false,
      hasNoErrors: false,
    });
  public valid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public done$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public error$: BehaviorSubject<string> = new BehaviorSubject(null);
  public lastConfiguredTrainId$: BehaviorSubject<string> = new BehaviorSubject(
    null
  );

  public steps: Array<ConfigurationSteps> = [
    {
      name: 'Train Details',
      target: 'edit-train',
      valid: ['hasTrain', 'hasNoErrors'],
    },
    {
      name: 'Edit Wheel Ids',
      target: 'edit-wheel',
      valid: ['hasTrain', 'hasNoErrors'],
    },
    {
      name: 'Activate Gateways',
      target: 'edit-gateways',
      valid: ['hasTrain', 'hasGateways', 'hasNoErrors'],
    },
    {
      name: 'Configure HD-TAGs',
      target: 'edit-tags',
      valid: ['hasTrain', 'hasGateways', 'hasNoErrors'],
    },
    {
      name: 'Save Configuration',
      target: 'save',
      valid: ['hasTrain', 'hasGateways', 'hasNoErrors'],
    },
  ];

  private subs: Array<Subscription> = new Array<Subscription>();
  private minSteps = 1;
  private maxSteps: number = this.steps.length - 2;

  constructor(
    private http$: HttpClient,
    private router: Router,
    private fleetService: FleetService,
    private store: Store
  ) {}

  public initConfiguration(): void {
    this.activeStep$.next(1);
    this.maxSteps = this.steps.length;
    this.subs.push(
      this.router.events
        .pipe(
          distinctUntilChanged(),
          filter(event => event instanceof NavigationStart)
        )
        .subscribe((event: NavigationStart) => {
          if (event.url.indexOf('configurator') === -1) this.done$.next(true);
        })
    );
    this.subs.push(
      this.done$.subscribe(done => {
        if (done === true) this.subs.forEach(sub => sub.unsubscribe());
      })
    );
    this.subs.push(
      this.error$.subscribe(__error => {
        this.validate(this.configuration.getValue());
      })
    );
    this.lastConfiguredTrainId$.next(null);
    this.setCurrentStep();
    this.subs.push(
      this.configuration.subscribe(config => {
        this.validate(config);
      })
    );
    this.subs.push(
      this.validationStates$.subscribe(() => this.checkButtonStates())
    );
    this.configuration.next(new TrainConfiguration());
    this.done$.next(false);
  }

  public killConfiguration(): void {
    this.done$.next(true);
    this.initConfiguration();
    this.activeStep$.next(0);
    this.prevActive$.next(false);
    this.nextActive$.next(false);
    this.finalStep$.next(false);
    this.done$.next(false);
  }

  // OPERATIONS ON THE CONFIGURATION OBJECT
  public setAttribute(att: Array<string> | string, value: any) {
    const configuration = this.configuration.value;
    let target: any = att;
    let pointer: any = configuration;
    if (att instanceof Array) {
      att.forEach((attribute, index) => {
        if (index < att.length - 1) {
          pointer = pointer[attribute];
        } else {
          target = attribute;
        }
      });
    }
    Object.assign(pointer, { [target]: value });
    this.configuration.next(configuration);
  }

  // NAVIGATIONAL OPERATIONS

  private setCurrentStep(): void {
    this.subs.push(
      this.router.events
        .pipe(
          distinctUntilChanged(),
          filter(event => event instanceof NavigationEnd),
          takeWhile(
            (event: NavigationEnd) =>
              event.urlAfterRedirects.indexOf('configurator') !== -1
          )
        )
        .subscribe((e: NavigationEnd) => {
          const fragments = e.urlAfterRedirects.split('/');
          const selectedStep = fragments.pop();
          const index = this.steps.findIndex(step => {
            return step.target === selectedStep;
          });
          if (selectedStep === 'configurator') {
            this.next();
          }
          this.activeStep$.next(index + 1);
          this.checkButtonStates();
        })
    );
  }

  public getConfiguration(): Observable<any> {
    return this.configuration.pipe(
      takeWhile(() => {
        return this.router.url.indexOf('configurator') > -1;
      }),
      tap(config => {
        if (config) {
          if (
            !Object.keys(config).length &&
            this.router.url.indexOf(this.steps[0].target) === -1
          ) {
            this.router.navigate([
              '/',
              'fleets',
              'manager',
              'configuration',
              'configurator',
              'edit-train',
            ]);
          }
        }
      }),
      distinctUntilChanged()
    );
  }

  public loadConfiguration(trainId: number): Observable<any> {
    const params = new HttpParams().set('id', trainId);
    return this.http$
      .get(this.configurationApi + '/configurations/byTrain', {
        params: params,
      })
      .pipe(first(), catchError(this.handleError));
  }

  public getTrainTypes(): Observable<any> {
    return this.http$.get(this.fleetApi + '/trainTypes');
  }
  public getFleets(): Observable<any> {
    return this.http$.get(this.fleetApi + '/fleets');
  }

  public previous(): void {
    if (this.activeStep$.getValue() > this.minSteps) {
      this.activeStep$.next(this.activeStep$.getValue() - 1);
      if (this.steps[this.activeStep$.getValue() - 1].target !== null) {
        this.router.navigate([
          'fleets/manager/configuration/configurator/' +
            this.steps[this.activeStep$.getValue() - 1].target,
        ]);
      }
      this.checkButtonStates();
    }
  }

  public next(): void {
    if (this.activeStep$.getValue() < this.maxSteps) {
      this.activeStep$.next(this.activeStep$.getValue() + 1);
      if (this.steps[this.activeStep$.getValue() - 1].target !== null) {
        this.router.navigate([
          'fleets/manager/configuration/configurator/' +
            this.steps[this.activeStep$.getValue() - 1].target,
        ]);
      }
      this.checkButtonStates();
    }
  }

  private checkButtonStates(): void {
    const current: any = this.steps[this.activeStep$.value - 1];
    const states: ValidationStates = this.validationStates$.getValue();
    let fullfilled = false;
    if (this.activeStep$.getValue() === this.maxSteps) {
      this.finalStep$.next(true);
    } else {
      this.finalStep$.next(false);
    }
    if (this.activeStep$.getValue() > this.minSteps) {
      this.prevActive$.next(true);
    } else {
      this.prevActive$.next(false);
    }
    // Take the valid attribute of the step to activate the next button
    // About every: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every
    // console.log("CURRENT", current);
    fullfilled = current?.valid?.every(condition => states[condition]);
    this.nextActive$.next(fullfilled);
  }

  public createCancelModal(ref: ViewContainerRef): Promise<string> {
    return new Promise((resolve, __reject) => {
      ref.clear();
      const componentRef = ref.createComponent(CancelModalComponent);
      componentRef.instance.id = 'fleet-configurator-cancel-modal';
      componentRef.instance.headline = 'Cancel Vehicle Configuration';
      componentRef.instance.description =
        'Do you really want to cancel the current configuration to the vehicle?<br />Please be aware that all data will be lost';
      this.subs.push(
        componentRef.instance.canceled.subscribe((state: boolean) => {
          if (state === true) {
            this.store.dispatch(new Modal.Hide());
          }
        })
      );
      this.subs.push(
        componentRef.instance.confirmed.subscribe((state: boolean) => {
          if (state === true) {
            this.rollback();
          }
        })
      );
      resolve('done');
    });
  }

  public cancel(ref: ViewContainerRef): void {
    this.createCancelModal(ref).then(() => {
      this.store.dispatch(new Modal.Show('fleet-configurator-cancel-modal'));
    });
  }

  public rollback(): void {
    this.configuration.next(null);
    this.router.navigateByUrl('/apps/selector');
  }

  public reset(): void {
    this.killConfiguration();
    this.fleetService.startConfiguration();
  }

  public save(): void {
    const configuration = this.configuration.value;
    const clone = JSON.parse(JSON.stringify(configuration));
    this.http$
      .post(this.configurationApi + '/configurations', clone)
      .pipe(first(), catchError(this.handleError))
      .subscribe({
        next: response => {
          this.store.dispatch(new Items.Refresh());
          this.lastConfiguredTrainId$.next(response?.trainId);
          this.done$.next(true);
        },
        error: () => {
          this.error$.next(
            'Could not save the current configuration, please try again or contact our support.'
          );
        },
      });
  }

  public getGateway(sn: string): Observable<any> {
    const params = new HttpParams().set('sn', sn);
    return this.http$
      .get(this.activationsApi + '/device', {
        params: params,
      })
      .pipe(catchError(this.handleError));
  }

  public getGatewayActivation(
    sn: string,
    activationPin: string
  ): Observable<any> {
    const params = {
      sn: sn,
      activation_pin: activationPin,
    };
    return this.http$
      .post(this.activationsApi + '/device', params)
      .pipe(catchError(this.handleError));
  }

  public getGatewayDecomissioning(
    sn: string,
    activationPin: string
  ): Observable<any> {
    const params = {
      sn: sn,
      activation_pin: activationPin,
    };
    return this.http$
      .delete(this.activationsApi + '/device', {
        params: params,
      })
      .pipe(catchError(this.handleError));
  }

  public getGatewayPinCheck(
    sn: string,
    activationPin: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('sn', sn)
      .set('activation_pin', activationPin);
    return this.http$
      .get(this.activationsApi + '/device/pin/check', {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  public getTagValidation(sn: string, bleId: string): Observable<any> {
    const params = new HttpParams().set('sn', sn).set('ble_id', bleId);
    return this.http$
      .get(this.activationsApi + '/tag/validation', {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  public getTagActivation(sn: string, mac: string): Observable<unknown> {
    const params = {
      sn: sn,
      ble_id: mac,
      timeout: 15,
    };
    return this.http$
      .post(this.activationsApi + '/tag', params)
      .pipe(catchError(this.handleError));
  }

  public getTagReplacement(
    oldMac: string,
    newMac: string
  ): Observable<unknown> {
    const params = {
      old_ble_id: oldMac,
      new_ble_id: newMac,
    };
    return this.http$
      .post(this.replacementsApi + '/replacements/tag', params)
      .pipe(catchError(this.handleError));
  }

  public getTagDecomissioning(sn: string, bleId: string): Observable<any> {
    const params = new HttpParams().set('sn', sn).set('ble_id', bleId);
    return this.http$
      .delete(this.activationsApi + '/tag', {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    let errorText: string = null;
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
      errorText =
        'A local error has occured, please check your internet connection.';
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
      // optional condition for unknown error if error.error.message is undefined
      if (typeof error.error === 'object') {
        const err = <CustomHttpError>error.error;
        errorText = err?.message;
      } else {
        // Just for compatibility
        switch (error.status) {
          case 400:
            errorText = "Can't identify the given serial/ids.";
            break;
          case 401:
            errorText = 'You are not authorized for this action.';
            break;
          case 403:
            errorText = 'This action is forbidden, please check your pin';
            break;
          case 404:
            errorText = "Can't identify gateway or tag.";
            break;
          case 409:
            errorText =
              'There is a conflict in your configuration, please reconfigure and try again. ';
            break;
        }
      }
    }
    // Return an observable with a user-facing error message.
    return throwError({ text: errorText, status: error.status });
  }

  /**
   * Validates the current TrainConfiguration Object based on
   * the provided information
   *
   * current states (see ValidationStates Model):
   *
   * trainExists: boolean;
   * gatewaysRegistered: boolean;
   * tagsConfigured: boolean;
   * hasNoErrors: boolean
   *
   * @param config
   */
  private validate(config: TrainConfiguration) {
    if (config) {
      const states: ValidationStates = {
        hasTrain: this.hasTrain(config),
        hasGateways: this.hasGateways(config?.gateways),
        hasTags: this.hasTags(config?.trainStructure?.bogies),
        hasNoErrors: this.hasNoErrors(),
      };
      this.validationStates$.next(states);
    }
  }
  private hasTrain(config: TrainConfiguration): boolean {
    return (
      config?.name?.length > 0 &&
      config?.fleet?.length > 0 &&
      config?.trainStructure != null &&
      config?.type?.length > 0
    );
  }
  private hasGateways(config: Array<GatewayConfiguration>): boolean {
    return config?.length > 0;
  }
  private hasTags(config: Array<BogieConfiguration>): boolean {
    return config?.some(bogie => bogie?.slots?.some(slot => slot.tag));
  }
  private hasNoErrors(): boolean {
    return this.error$.getValue() === null;
  }
}
