<div
  class="container incident-list h-100 m-0 p-0 mr-3 pb-5"
  *ngIf="gateways.length > 0">
  <div class="incident-toolbar-list d-flex flex-row">
    <div class="p-3" *ngIf="lastUpdate">
      Last update: {{ lastUpdate | timeago: live }}
    </div>
    <div class="ml-auto">
      <button class="btn pt-2 pr-3">
        <span class="material-icons pt-1" (click)="loadGatewaySignalQuality()"
          >update</span
        >
      </button>
    </div>
  </div>
  <ul
    class="incident-sidebar-list overflow-auto d-flex flex-column m-0 p-0"
    [@listAnimation]="gateways?.length">
    <li
      class="d-flex flex-row m-0 row-incident ml-1 mr-1 mt-1"
      *ngFor="let gateway of gateways; let i = index"
      [class.active]="i === selected"
      (click)="loadDetails(i)">
      <div class="p-3 d-flex flex-row w-100 h-100 align-items-center">
        <div class="col-incident-icon svg-icon-wrapper mt-0 mb-0 mr-3 ml-1">
          <svg-icon src="assets/graphics/icon-propm-290322.svg"></svg-icon>
        </div>
        <div class="col-incident-msg">
          {{ gateway?.type }} | {{ gateway?.sn }}
        </div>
        <div class="ml-auto pr-2">
          <div
            class="signal-bars mt1 sizing-box {{
              gateway?.signalQuality | lowercase
            }} four-bars"
            *ngIf="gateway?.signalQuality !== '0'"
            [ngbTooltip]="
              (gateway?.signalQuality | lowercase) + ' signal quality'
            "
            tooltipClass="gateway-tooltip">
            <div class="first-bar bar"></div>
            <div class="second-bar bar"></div>
            <div class="third-bar bar"></div>
            <div class="fourth-bar bar"></div>
          </div>
        </div>
        <span
          class="material-icons"
          *ngIf="gateway?.signalQuality === '0'"
          ngbTooltip="signal quality not available">
          block
        </span>
      </div>
    </li>
  </ul>
</div>
