import { Component, Input } from '@angular/core';

@Component({
  selector: 'idm-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() state: string;
  @Input() labelPosition: string;
}
