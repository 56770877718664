<div class="flex-row w-100" [class.disabled]="context.max - fileCounter <= 0">
  <form [formGroup]="form">
    <div class="w-100">
      <div
        class="fileupload text-center p-3"
        idmFileDragDrop
        (click)="fileField.click()"
        (fileDropped)="upload($event)">
        <h6 class="padded">Click here or drag file to this area to upload</h6>
        <p>
          The upload of single and multiple files at once is possible.<br /><span
            *ngIf="maxFileSize"
            >Max. file size {{ maxFileSize / 1000 }} MB</span
          >
          <br /><span *ngIf="context.max"
            >Max. files {{ context.max }} per {{ context.source }}</span
          >
          <br /><span *ngIf="context.max"
            >{{ context.max - fileCounter }} slots available.</span
          >
        </p>
        <h6>Supported:</h6>
        <p class="w-100">
          <span
            class="badge badge-info mr-1"
            *ngFor="let type of allowedFileTypes"
            >.{{ type }}</span
          >
        </p>
        <p class="messages" *ngIf="msg">
          <span *ngFor="let m of msg">{{ m }}<br /></span>
        </p>
        <input
          type="file"
          name="files"
          #fileField
          (change)="upload($event)"
          hidden
          multiple />
      </div>
    </div>
    <!-- Progress Bar -->
    <div class="w-100 pt-4" *ngIf="files?.length > 0" [@fade]>
      <h6>Latest uploads:</h6>
      <div class="file-list" *ngFor="let file of files; let i = index">
        <div class="file w-100" [class.error]="file?.error">
          <div class="profile" *ngIf="preview">
            <img [src]="sanitize(file['url'])" alt="" />
          </div>
          <p class="mb-1">
            {{ file.item.name }} ({{
              file.item.size / 1000 | number: '1.0-0':'de-DE'
            }}
            kB)
          </p>
          <div class="w-100" [class.d-none]="!progress[i]">
            <div class="progress form-group">
              <div
                class="progress-bar progress-bar-striped bg-success"
                role="progressbar"
                [style.width.%]="progress[i]">
                {{ progress[i] }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
