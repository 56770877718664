<div class="pr-4 pl-4 pt-3 pb-3 d-flex flex-row maintenance-content-header">
  <div
    class="mr-3 ml-3 content-header-image"
    [@fade]="model"
    *ngIf="model"></div>
  <div class="flex-column" [@fade]="model" *ngIf="model">
    <h4 class="pb-0 mb-0">{{ vehicle.name }}</h4>
    <p class="m-0 p-0 mt-2">{{ vehicle.fleet }}</p>
  </div>
</div>
<h4 class="pt-3 pl-5 pb-0" *ngIf="model" [@fade]="model">
  Incident Localization
</h4>
<div class="w-100 train-view">
  <idm-train
    [model]="model"
    [viewConfig]="trainViewConfiguration"
    *ngIf="model"
    [@fade]="model"></idm-train>
</div>
<div
  class="d-flex w-100 pl-5 pr-5 pt-3 mb-4 overflow-hidden"
  *ngIf="model"
  [@fade]="model">
  <div class="w-50 h-100 bogie-list overflow-auto">
    <div
      class="bogie"
      *ngFor="let bogie of model?.trainStructure?.bogies; let i = index">
      <h4>{{ bogie.name }}</h4>
      <table class="bogie" class="table table-striped">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Shelling</th>
            <th scope="col">Flatspot</th>
            <th scope="col">No Incident</th>
            <th scope="col">Maintenance done</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let slot of bogie?.slots; let j = index">
            <th scope="row">{{ slot.name }}</th>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [disabled]="
                    disabledStates[model.trainStructure.bogies[i].slots[j].name]
                      ?.incidents
                  "
                  [(ngModel)]="
                    model.trainStructure.bogies[i].slots[j]['shelling']
                  "
                  (change)="
                    updateState(bogie.name, slot.name, 'shelling', $event)
                  "
                  [id]="'shelling_' + bogie.name + '_' + slot.name" />
                <label
                  class="custom-control-label"
                  [for]="'shelling_' + bogie.name + '_' + slot.name"></label>
              </div>
            </td>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [disabled]="
                    disabledStates[model.trainStructure.bogies[i].slots[j].name]
                      ?.incidents
                  "
                  [(ngModel)]="
                    model.trainStructure.bogies[i].slots[j]['flatspot']
                  "
                  (change)="
                    updateState(bogie.name, slot.name, 'flatspot', $event)
                  "
                  [id]="'flatspot_' + bogie.name + '_' + slot.name" />
                <label
                  class="custom-control-label"
                  [for]="'flatspot_' + bogie.name + '_' + slot.name"></label>
              </div>
            </td>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [disabled]="
                    disabledStates[model.trainStructure.bogies[i].slots[j].name]
                      ?.clear
                  "
                  [(ngModel)]="
                    model.trainStructure.bogies[i].slots[j]['noIncident']
                  "
                  (change)="
                    updateState(bogie.name, slot.name, 'noIncident', $event)
                  "
                  [id]="'noincident_' + bogie.name + '_' + slot.name" />
                <label
                  class="custom-control-label"
                  [for]="'noincident_' + bogie.name + '_' + slot.name"></label>
              </div>
            </td>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="
                    model.trainStructure.bogies[i].slots[j]['maintenanceDone']
                  "
                  [disabled]="
                    disabledStates[model.trainStructure.bogies[i].slots[j].name]
                      ?.maintenance
                  "
                  (change)="
                    updateState(
                      bogie.name,
                      slot.name,
                      'maintenanceDone',
                      $event
                    )
                  "
                  [id]="'maintenancedone_' + bogie.name + '_' + slot.name" />
                <label
                  class="custom-control-label"
                  [for]="
                    'maintenancedone_' + bogie.name + '_' + slot.name
                  "></label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="w-50 pl-5 d-flex flex-column global-states overflow-auto">
    <div>
      <h4>Incident Status</h4>
      <div class="d-flex w-100">
        <div class="w-100 mr-2">
          <idm-datepicker-popup
            #inspectionDate
            label="Inspection date"
            [disabled]="disabledInspectionDatepicker$ | async"
            (dateSelectEvent)="updateInspectionDate($event)">
          </idm-datepicker-popup>
        </div>
        <div class="w-100 ml-2">
          <idm-datepicker-popup
            #repairDate
            label="Repair date"
            [disabled]="disabledRepairDatepicker$ | async"
            (dateSelectEvent)="updateRepairDate($event)">
          </idm-datepicker-popup>
        </div>
      </div>
    </div>
    <div
      class="hint mt-2 p-1"
      [ngClass]="{
        disabled:
          (this.disabledInspectionDatepicker$ | async) === true ||
          maintenanceState.inspectionDate !== '1899-11-30T00:00:00.000Z'
      }">
      <ng-container
        *ngTemplateOutlet="
          HintIcon;
          context: { icon: 'warning' }
        "></ng-container>
      <p>Set an inspection date.</p>
    </div>
    <div
      class="hint mt-2 p-1"
      [ngClass]="{
        disabled:
          (this.disabledRepairDatepicker$ | async) === true ||
          maintenanceState.repairDate !== '1899-11-30T00:00:00.000Z'
      }">
      <ng-container
        *ngTemplateOutlet="
          HintIcon;
          context: { icon: 'warning' }
        "></ng-container>
      <p>Set a repair date.</p>
    </div>
    <div
      class="hint mt-2 p-1"
      [ngClass]="{
        disabled: (datePlausibility$ | async) === true
      }">
      <ng-container
        *ngTemplateOutlet="
          HintIcon;
          context: { icon: 'warning' }
        "></ng-container>
      <p>The repair date must be same as the inspection date or later.</p>
    </div>

    <div
      class="flex-grow-1 d-flex flex-wrap align-content-end action-btns pt-4 pb-3 pl-2 pr-2">
      <div class="mr-auto text-right" [ngbTooltip]="SaveToolTipTemplate">
        <span
          class="material-icons mt-2"
          [ngClass]="{
            disabled:
              (this.componentState$ | async) === COMPONENT_STATES.Pristine,
            touched:
              (this.componentState$ | async) === COMPONENT_STATES.Touched,
            dirty: (this.componentState$ | async) === COMPONENT_STATES.Dirty,
            valid: (this.componentState$ | async) === COMPONENT_STATES.Valid,
            saved: (this.componentState$ | async) === COMPONENT_STATES.Saved
          }"
          >save</span
        >
      </div>
      <a
        class="btn btn-outline-primary ml-3"
        role="button"
        aria-disabled="true"
        (click)="onCancelClick(); (false)"
        >Cancel</a
      >
      <a
        href="#"
        class="btn btn-primary ml-3"
        role="button"
        [class.disabled]="hasErrors$ | async"
        (click)="save(); (false)"
        >Save</a
      >
    </div>
  </div>
</div>
<div #cancelModal></div>

<ng-template #SaveToolTipTemplate>
  <div *ngIf="(this.componentState$ | async) === COMPONENT_STATES.Pristine">
    Unedited
  </div>
  <div
    *ngIf="
      (this.componentState$ | async) === COMPONENT_STATES.Touched ||
      (this.componentState$ | async) === COMPONENT_STATES.Dirty
    ">
    Edited but invalid
  </div>
  <div *ngIf="(this.componentState$ | async) === COMPONENT_STATES.Valid">
    Edited, valid and saveable
  </div>
  <div *ngIf="(this.componentState$ | async) === COMPONENT_STATES.Saved">
    Successfully saved
  </div>
</ng-template>

<ng-template #HintIcon let-icon="icon">
  <span class="material-icons hint-icon p-1">{{ icon }}</span>
</ng-template>
