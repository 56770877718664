import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ActionCompletion,
  Actions,
  ofActionCompleted,
  Store,
} from '@ngxs/store';
import { environment } from '@environment/environment';

import { Authentication } from '@idm/iam/store/authentication/authenticaton.actions';

@Component({
  selector: 'iam-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public notify: string;
  public returnUrl: string;
  public env = environment;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.actions$
      .pipe(ofActionCompleted(Authentication.Login))
      .subscribe((__state: ActionCompletion) => {
        // Removed faulty error handling
      });
  }

  private initForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  /**
   * Checks if the given field is valid AFTER it has been manipulated
   * this ensures that it is not checked on initial rendering
   *
   * @param fieldName
   */

  public isValidInput(fieldName: string): boolean {
    const control = this.loginForm.controls[fieldName]
      ? this.loginForm.controls[fieldName].invalid
      : false;
    return (
      control &&
      (this.loginForm.controls[fieldName].dirty ||
        this.loginForm.controls[fieldName].touched)
    );
  }

  /**
   * Dispatches the login event to the ngxs store
   */

  public login(): void {
    this.store.dispatch(new Authentication.Login(this.loginForm.value));
  }
}
