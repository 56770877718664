<div class="container mt-4 mb-5 pb-5 imprint">
  <h2>Provider</h2>
  <h3>ZF Friedrichshafen AG</h3>

  <p>
    Corporate Headquarters / ZF Forum<br />
    Löwentaler Straße 20<br />
    88046 Friedrichshafen<br />
    Germany
  </p>

  <p>
    Phone: +49 7541 77 - 0<br />
    Email: <a href="mailto:postoffice«zf.com">postoffice@zf.com</a><br />
  </p>

  <h4>
    Represented by the
    <a
      href="//www.zf.com/mobile/en/company/corporate_governance/board_of_management/board_of_management.html"
      >ZF Board of Management</a
    >
  </h4>
  <p>
    Commercial Register Ulm, HRB 63020 VAT registration number: DE 145 374
    190<br />
    D.U.N.S.: 31-501-5446
  </p>
  <h4>Contact</h4>
  <p>
    Sales & Product Management Digital Solutions<br />
    Ehlersstraße. 50<br />
    88046 Friedrichshafen<br />
    <a href="mailto:dppm.zf@zf.com">dppm.zf@zf.com</a>
  </p>
  <h3>Legal notes</h3>
  <p>
    Legal notes on using the internet website facilities available at ZF
    Friedrichshafen AG
  </p>
  <p>
    <strong
      >The use of this website facility is subject to the following terms and
      conditions. You indicate your agreement with these terms and conditions
      when you access these web pages:</strong
    >
  </p>
  <h3>Copyright</h3>
  <p>
    These web pages provide information about ZF Friedrichshafen AG and its
    products. These texts, photos, graphics and the layout as well as all
    aspects of graphic design of these pages are protected by copyright.
  </p>
  <p>
    The entire contents of these pages are protected, and cannot be copied,
    disseminated or altered for commercial purposes. You are only permitted to
    download and/or print out the text, photos and graphic elements for
    personal, private and non-commercial purposes. Any reproduction of graphics,
    photos, sound files, video sequences and texts in other electronic or
    printed publications is only permitted with the prior written consent of ZF
    Friedrichshafen AG.
  </p>
  <h3>Brand mentioning</h3>
  <p>
    If not stated otherwise or differently, then all trademarks/brands mentioned
    on the ZF Friedrichshafen AG Internet pages are legally protected trademarks
    and denominative mark descriptions/designations of ZF Friedrichshafen AG and
    its subsidiaries; this holds particularly true for product names, logos, and
    emblems of the company.
  </p>
  <h3>Liability disclaimer for information</h3>
  <p>
    The contents of these web pages are only intended for general information
    purposes. Although all information is verified in terms of accuracy and
    completeness, it may still contain errors or rather inaccuracies in some
    cases. Therefore, ZF Friedrichshafen AG cannot be held liable for claims
    relating to damage of material or immaterial nature arising as a result of
    the accuracy, correctness, completeness, and topicality e.g. use or non-use
    of incorrect or incomplete information provided via the Internet offer of
    its web pages.
  </p>

  <p>
    Moreover, ZF Friedrichshafen AG excludes any liability claims relating to
    the services offered, such as downloads of the files made available on the
    Internet pages of ZF Friedrichshafen AG.
  </p>
  <p>
    The liability disclaimer cannot be applied to all cases where ZF
    Friedrichshafen AG or its agents have to stand in for allegations of
    intention or gross negligence. In addition, the liability disclaimer of ZF
    Friedrichshafen AG is not valid in the case of obligation infringements i.e.
    fulfillment of said obligations is the very prerequisite for the proper
    implementation of the contract, and the visitor is entitled to trust that
    adherence to said obligations is performed (cardinal obligations/duties);
    however, in the case of minor negligence claims will be restricted in terms
    of the payable amount, based on foreseeable contractual damage at the point
    of time of contract conclusion. The liability disclaimer does not apply in
    the case of damage to persons, liability within the context of the Product
    Liability Act, liability for lack of contractually agreed warranties, and
    for malice.
  </p>
  <p>
    ZF Friedrichshafen AG expressly reserves the right to change, supplement,
    delete parts of the pages offered or even the entire offer without
    dispatching an associated notification; the same applies to the temporary or
    final discontinuation of publications/postings.
  </p>
  <h3>Scope</h3>
  <p>
    This site notice refers to the website of ZF Friedrichshafen AG at
    dppm-zf.com as well as to all Internet presences of ZF Friedrichshafen AG at
    <a
      href="https://www.facebook.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.facebook.com</a
    >,
    <a
      href="https://www.youtube.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.youtube.com</a
    >,
    <a
      href="https://www.twitter.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.twitter.com</a
    >,
    <a
      href="https://www.linkedin.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.linkedin.com</a
    >,
    <a
      href="https://www.xing.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.xing.com</a
    >,
    <a
      href="https://www.instagram.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.instagram.com</a
    >,
    <a
      href="https://www.tumblr.com"
      title=""
      class="zfui-color-cyan"
      rel="noopener"
      target="_blank"
      >www.tumblr.com</a
    >.
  </p>
  <p>
    &copy; {{ today | date: 'yyyy':'':'de-DE' }} ZF Friedrichshafen AG. All
    rights reserved.
  </p>
  <h3>Credits:</h3>
  <ul class="pb-5">
    <li>
      <a href="https://www.freepik.com/vectors/web"
        >Web vector created by stories - www.freepik.com</a
      >
    </li>
    <li>
      <span
        >Photo by
        <a
          href="https://unsplash.com/@berg_photo?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
          >Philipp Berg</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/feuerwehr?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
          >Unsplash</a
        ></span
      >
    </li>
  </ul>
</div>
