import { NotLoggedInGuard } from './guards/not-logged-in.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { LoginComponent, JwtInterceptor, AuthenticationState } from '@idm/iam';

const routes: Routes = [
  {
    path: 'authentication',
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [NotLoggedInGuard],
        data: { reuse: false },
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [NotLoggedInGuard],
        data: { animation: 'LoginPage', reuse: false },
      },
    ],
  },
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forChild(routes),
    CommonModule,
    // Also important, has to be after all router implementations
    NgxsModule.forFeature([AuthenticationState]),
    NgxsStoragePluginModule.forRoot({
      key: 'authentication',
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
})
export class IamModule {}
