import { trigger, transition, animate, style } from '@angular/animations';

export const fadeInAndOutAnimation = trigger('fade', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate('250ms ease-in-out', style({ opacity: 1 })),
  ]),
  transition('* => void', [
    animate('250ms ease-in-out', style({ opacity: 0 })),
  ]),
]);
