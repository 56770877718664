
<div class="w-100 h-100" [@routeState]="prepareRoute(outlet)">
  <idm-header></idm-header>
  <idm-sidebar></idm-sidebar>

  <div
    class="content h-100 w-100"
    [class.sidebar-margin]="(authenticated$ | async) === true">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>

  <idm-footer></idm-footer>
  <idm-contact></idm-contact>
  <idm-notifcation></idm-notifcation>
  <idm-size-detector></idm-size-detector>
</div>
<div
  [@fade]
  class="w-100 h-100 masked"
  *ngIf="
    masked && router.url !== '/data-protection' && router.url !== '/imprint'
  "></div>