import { Component, OnDestroy } from '@angular/core';
import { FleetConfigurationService, TAG_STATE } from './../../../../fleet';
import {
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../../vehicles';

import { Subscription } from 'rxjs';

@Component({
  selector: 'idm-fleet-configurator-connect-tags',
  templateUrl: './fleet-configurator-connect-tags.component.html',
})
export class FleetConfiguratorConnectTagsComponent implements OnDestroy {
  public trainViewConfiguration: TrainViewConfiguration = {
    actions: {
      connectTags: true,
    },
    gateways: {
      editable: false,
      visible: true,
      counter: true,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: false,
          replaceable: false,
          visible: true,
        },
      },
    },
  };

  public model: TrainConfiguration;
  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(private fleetConfiguratorService: FleetConfigurationService) {
    // FOR DUMMY HANDLING, REMOVE IF OBSOLETE
    this.model.trainStructure.bogies.forEach(bogie =>
      [...bogie.slots].reverse()
    );
    this.subs.push(
      this.fleetConfiguratorService
        .getConfiguration()
        .subscribe(configuration => {
          if (Object.keys(configuration).length > 0) this.model = configuration;
        })
    );
  }

  onConnectTagsClicked(): void {
    this.model.trainStructure.bogies.forEach((bogie: any) => {
      bogie.slots.forEach((slot: any) => {
        slot.status.type = TAG_STATE.connected;
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
