import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import { DomSanitizer } from '@angular/platform-browser';
import { FileContext } from '../../models/file-context.model';
import { FileManagerService } from '../../services/file-manager.service';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import { take } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'idm-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FileUploaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() allowedFileTypes: Array<string>; // array of allowed fileTypes - currently not implemented
  @Input() maxFileSize: number; // in kByte, default: 2000
  @Input() multipleUploadSupport: boolean; // default: false
  @Input() contentFilter: boolean; // default: true - currently not implemented
  @Input() context: FileContext; // context of the upload, see model
  @Input() preview: boolean; // show preview image, default false

  public files: Array<any> = new Array<any>();
  public images: Array<any> = new Array<any>();
  public fileObj: Array<any> = new Array<any>();
  public form: FormGroup;
  public msg: Array<string> = new Array<string>();
  public progress: Array<number> = new Array<number>();
  public fileCounter: number;

  private subs$: Array<any> = new Array<any>();

  constructor(
    public fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public fileManagerService: FileManagerService
  ) {
    this.form = this.fb.group({
      files: [null],
    });
  }

  ngOnInit() {
    const sub = this.fileManagerService.fileCounter$.subscribe(count => {
      this.fileCounter = count;
    });
    this.subs$.push(sub);

    if (!this.maxFileSize) {
      this.maxFileSize = 2000;
    }
    if (!this.allowedFileTypes) {
      this.allowedFileTypes = [
        'pdf',
        'jpg',
        'jpeg',
        'jiff',
        'pjpeg',
        'pjp',
        'png',
        'tif',
        'tiff',
        'mov',
        'mp4',
        'mpeg',
        'mpg',
        'mpe',
        'wma',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'msg',
        'xlsm',
        'xlsb',
        'odt',
        'fodt',
        'qt',
        'zip',
      ];
    }
    if (!this.contentFilter) {
      this.contentFilter = true;
    }
    // Redundant but explizit
    if (!this.multipleUploadSupport) {
      this.multipleUploadSupport = false;
    }
    if (!this.preview) {
      this.preview = false;
    }
  }

  ngOnChanges(): void {
    this.progress = new Array<number>();
    this.fileObj = new Array<any>();
    this.files = new Array<any>();
    this.msg = Array<string>();
  }

  public upload(e: any) {
    console.log(e);
    let eFiles: any = e;
    if (!eFiles?.length as any) {
      eFiles = e.target.files;
    }

    const fileListAsArray = Array.from(eFiles as FileList);

    this.files = new Array<any>();
    this.fileObj = new Array<any>();
    this.msg = new Array<string>();

    let error = false;

    fileListAsArray.forEach((item: File, i) => {
      const file = eFiles as HTMLInputElement;
      const url = URL.createObjectURL(file[i]);
      if (
        !this.fileManagerService.checkFileExtension(item, this.allowedFileTypes)
      ) {
        this.msg.push('Filetype is not allowed to upload!');
        error = true;
      }
      if (item.size > this.maxFileSize * 1000) {
        this.msg.push('File is too large!');
        error = true;
      }
      if (!error) {
        this.images.push(url);
        this.files.push({ item, url: url });
      } else {
        this.files.push({ item, url, error: true });
      }
    });

    this.files.forEach(item => {
      if (!item.error) {
        this.fileObj.push(item.item);
      }
    });
    this.form.patchValue({
      files: this.fileObj,
    });
    this.form.get('files').updateValueAndValidity();

    // Check if the user doesn't try to upload more files than possible
    if (
      [...this.form.value.files].length + this.fileCounter <=
      this.context.max
    ) {
      // Check for multiple upload support (all files in one request vs separate requests)
      if (!this.multipleUploadSupport) {
        [...this.form.value.files].forEach((file, index) => {
          this.processFiles(file, index);
        });
      } else {
        this.processFiles(this.form.value.files, 0);
      }
    } else {
      this.files = new Array<any>();
      this.fileObj = [];
      this.msg.push(
        "You don't have enough available slots, please select less files"
      );
    }
  }

  private processFiles(files, index: number): void {
    const request = this.fileManagerService.addFiles(
      files,
      this.context,
      this.multipleUploadSupport
    );
    if (request !== null) {
      this.progress[index] = 0;
      const sub = request.subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress[index] = Math.round(
                (event.loaded / event.total) * 100
              );
              console.log(`Uploaded! ${this.progress[index]}%`);
              break;
            case HttpEventType.Response:
              console.log('File uploaded successfully!', event);
          }
        },
        error => {
          console.log('ERROR:', error);
        },
        () => {
          timer(2000)
            .pipe(take(1))
            .subscribe(() => {
              this.reset(files, index);
            });
        }
      );
      this.subs$.push(sub);
    }
  }

  private reset(files: any, index?: number) {
    this.progress[index] = 0;
    this.fileObj = new Array<any>();
    this.files = new Array<any>();
    this.fileManagerService.reportFile(files);
  }

  // Clean Url
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnDestroy(): void {
    if (this.subs$.length > 0) {
      this.subs$.forEach(sub => sub.unsubscribe());
    }
  }
}
