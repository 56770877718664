<idm-basic-modal [id]="id">
  <div class="h-100">
    <h3>{{ headline }}</h3>
    <p [innerHTML]="description"></p>
    <div class="w-100 d-flex justify-content-end mt-5">
      <button type="button" class="btn btn-secondary mr-3" (click)="onCancel()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary" (click)="onConfirm()">
        Yes, confirm
      </button>
    </div>
  </div>
</idm-basic-modal>
