<div class="container incident-list m-0 p-0 mr-3 pb-5 1-100" *ngIf="incidents">
  <div class="incident-toolbar-list d-flex flex-row pl-3">
    <div class="pt-3">
      <div class="stats pt-1">Incidents ({{ incidents?.length }})</div>
    </div>
    <div class="ml-auto">
      <button type="button" class="btn btn-link p-3" (click)="toggleFilter()">
        <span class="material-icons"> filter_list </span>
      </button>
    </div>
  </div>
  <div
    *ngIf="incidents && incidents?.length === 0"
    class="p-3 text-center error-no-incidents">
    <p>There are no registered incidents available with this filter options.</p>
    <p>
      Please try to
      <a href (click)="toggleFilter(); (false)">change the filter settings.</a>
    </p>
  </div>
  <ul
    class="incident-sidebar-list overflow-auto w-100 d-flex flex-column m-0 p-0 ml-auto"
    [@listAnimation]="incidents?.length">
    <li
      class="d-flex flex-row m-0 row-incident ml-1 mr-1 mt-1"
      *ngFor="let incident of incidents; let i = index"
      [class.active]="i === selected"
      (click)="loadDetails(i)">
      <div class="col-incident-marker mb-1 mt-1 ml-2 failure"></div>
      <div
        class="p-3 d-inline-flex flex-row h-100 align-items-center w-100 ml-auto">
        <div class="col-incident-icon pr-3">
          <span class="material-icons failure">info</span>
        </div>
        <div class="d-flex flex-column col-incident-type">
          <div class="row-incident-msg">
            Warning: {{ incident.subtype | titlecase }}
          </div>
          <div class="row-incident-date">
            {{ incident.measured | date: 'YYY-MM-dd, hh:mm:ss a' }}
          </div>
        </div>
        <div
          class="d-flex flex-column col-incident-states align-items-right ml-auto pr-2">
          <div class="row-state-badges">
            <span class="badge badge-pill badge-outline-primary">{{
              (incident.confirmation.state === 'REJECTED'
                ? 'NOT CONFIRMED'
                : incident.confirmation.state
              ) | titlecase
            }}</span>
          </div>
          <div class="row-state-icons text-right pt-2">
            <span
              class="material-icons"
              [ngClass]="{
                inactive: incident?.maintenance?.state === 'UNREPAIRED'
              }"
              >build</span
            >
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<div class="filter-container" [@fade]="filter" *ngIf="filter">
  <span class="arrow left"></span>
  <form [formGroup]="formData" class="h-100 w-100" (ngSubmit)="apply()">
    <div class="d-flex align-item-start h-100 flex-column w-100">
      <div class="incident-toolbar-filter pl-3 w-100">Filter</div>
      <div class="filter-options mb-auto p-3 w-100">
        <div class="form-group mb-0 w-100">
          <div class="d-flex mb-3 w-100">
            <div class="flex-column w-100">
              <fieldset>
                <legend class="mb-2">Incident states:</legend>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="incidents-filter-open"
                    name="incidents-filter-open"
                    class="custom-control-input"
                    formControlName="open" />
                  <label
                    class="custom-control-label"
                    for="incidents-filter-open"
                    >Open</label
                  >
                </div>

                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="incidents-filter-confirmed"
                    name="incidents-filter-confirmed"
                    class="custom-control-input"
                    formControlName="confirmed" />
                  <label
                    class="custom-control-label"
                    for="incidents-filter-confirmed"
                    >Confirmed</label
                  >
                </div>

                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="incidents-filter-rejected"
                    name="incidents-filter-rejected"
                    class="custom-control-input"
                    formControlName="rejected" />
                  <label
                    class="custom-control-label"
                    for="incidents-filter-rejected"
                    >Not confirmed</label
                  >
                </div>
              </fieldset>

              <fieldset>
                <legend class="mb-2">Incident types:</legend>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="incidents-filter-shelling"
                    name="incidents-filter-shelling"
                    class="custom-control-input"
                    formControlName="shelling" />
                  <label
                    class="custom-control-label"
                    for="incidents-filter-shelling"
                    >Shelling</label
                  >
                </div>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="incidents-filter-flatspot"
                    name="incidents-filter-flatspot"
                    class="custom-control-input"
                    formControlName="flatspot" />
                  <label
                    class="custom-control-label"
                    for="incidents-filter-flatspot"
                    >Flatspot</label
                  >
                </div>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="incidents-filter-bearing"
                    name="incidents-filter-bearing"
                    class="custom-control-input"
                    formControlName="bearing" />
                  <label
                    class="custom-control-label"
                    for="incidents-filter-bearing"
                    >Bearing</label
                  >
                </div>
              </fieldset>

              <fieldset id="filter-maintenance">
                <legend class="mb-2">Maintenance state:</legend>
                <div class="btn-group btn-group-toggle mb-2 pr-2 w-100">
                  <label
                    class="btn btn-primary"
                    [ngClass]="{
                      'btn-secondary active':
                        formData?.controls?.maintenance.value === 'unrepaired'
                    }">
                    <input
                      type="radio"
                      formControlName="maintenance"
                      value="unrepaired"
                      id="unrepaired"
                      autocomplete="off"
                      [checked]="
                        formData?.controls?.maintenance.value === 'unrepaired'
                      " />
                    Unrepaired
                  </label>
                  <label
                    class="btn btn-primary"
                    [ngClass]="{
                      'btn-secondary active':
                        formData?.controls?.maintenance.value === 'all'
                    }">
                    <input
                      type="radio"
                      formControlName="maintenance"
                      value="all"
                      id="all"
                      autocomplete="off"
                      [checked]="
                        formData?.controls?.maintenance.value === 'all'
                      " />
                    All
                  </label>
                  <label
                    class="btn btn-primary"
                    [ngClass]="{
                      'btn-secondary active':
                        formData?.controls?.maintenance.value === 'repaired'
                    }">
                    <input
                      type="radio"
                      formControlName="maintenance"
                      value="repaired"
                      id="repaired"
                      autocomplete="off"
                      [checked]="
                        formData?.controls?.maintenance.value === 'repaired'
                      " />
                    &nbsp;Repaired&nbsp;
                  </label>
                </div>
              </fieldset>
              <fieldset>
                <legend class="mb-0">Please select a time period.</legend>
                <div class="range-selection pl-4 pr-4 pt-3 pb-4">
                  <div
                    class="range"
                    [style]="
                      '--active-range-width:' +
                      (+dateRange.value - 1) * (100 / rangeOptions) +
                      '%'
                    ">
                    <input
                      #dateRange
                      type="range"
                      min="1"
                      max="4"
                      steps="1"
                      value="1"
                      (input)="setRange($event)" />
                  </div>
                  <ul class="range-labels">
                    <li
                      (click)="setRange(null, 1)"
                      [class.active]="dateRange.value === '1'"
                      [class.selected]="dateRange.value >= '1'">
                      last 4 weeks
                    </li>
                    <li
                      (click)="setRange(null, 2)"
                      [class.active]="dateRange.value === '2'"
                      [class.selected]="dateRange.value >= '2'">
                      last 3 months
                    </li>
                    <li
                      (click)="setRange(null, 3)"
                      [class.active]="dateRange.value === '3'"
                      [class.selected]="dateRange.value >= '3'">
                      last 12 months
                    </li>
                    <li
                      (click)="setRange(null, 4)"
                      [class.active]="dateRange.value === '4'"
                      [class.selected]="dateRange.value >= '4'">
                      Custom
                    </li>
                  </ul>
                </div>
                <div class="datepicker">
                  <idm-datepicker-popup
                    [disabledWithDate]="datepickerDisabled"
                    [initDate]="dateFrom$ | async"
                    label="From"
                    (dateSelectEvent)="
                      updateDate('dateFrom', $event)
                    "></idm-datepicker-popup>

                  <idm-datepicker-popup
                    [disabledWithDate]="datepickerDisabled"
                    [initDate]="dateTo$ | async"
                    label="To"
                    (dateSelectEvent)="
                      updateDate('dateTo', $event)
                    "></idm-datepicker-popup>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div class="actions p-3 d-flex flex-row">
        <input
          type="button"
          class="btn btn-outline-primary mr-3 w-50"
          role="button"
          (click)="reset(false)"
          value="Reset" />
        <input
          type="submit"
          class="btn btn-primary w-50"
          role="button"
          value="Apply" />
      </div>
    </div>
  </form>
</div>
