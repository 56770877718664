import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  ComponentRef,
  Injectable,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import {
  Coordinates,
  CustomHttpParamEncoder,
  Incident,
  Train,
} from './../../core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  distinctUntilChanged,
  finalize,
  first,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { DateFilter } from './../models/date-filter.model';
import { IncidentExportComponent } from '../components/incident-export/incident-export.component';
import { StatisticIncident } from './../models/statistic-incident.model';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IncidentsService implements OnDestroy {
  private api = environment.api + '/im/incidents';
  private statisticApi = environment.api + '/statistics';

  lastConfirmationData = null;
  lastMaintenanceData = null;

  _train$: BehaviorSubject<Train> = new BehaviorSubject<Train>(null);
  _incidents$: BehaviorSubject<Incident[]> = new BehaviorSubject<Incident[]>(
    new Array<Incident>()
  );
  _details$: Subject<Incident> = new Subject<Incident>();
  _coordinates$: Subject<Coordinates[]> = new Subject<Coordinates[]>();

  dateRange$: BehaviorSubject<DateFilter> = new BehaviorSubject<DateFilter>({
    dateFrom: null,
    dateTo: null,
  });
  public watchIncident$: Subject<any> = new Subject<any>();
  public ngUnsubscribe$: Subject<boolean> = new Subject();

  constructor(private http$: HttpClient) {}

  public getIncidentsByVehicle(vehicle: string): Observable<any> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: {
        trainId: vehicle,
        cStates: ['OPEN', 'CONFIRMED', 'REJECTED'],
        dateTo: this.dateRange$.value.dateTo,
        dateFrom: this.dateRange$.value.dateFrom,
      },
    });
    return this.http$.get(this.api + '/byTrain/byFilter?' + params);
  }

  public getIncidentDetailsById(id: string): Observable<any> {
    return this.http$.get(this.api + '/byId?id=' + id);
  }

  public setConfirmationStatesById(id: string, data: any): Observable<any> {
    if (JSON.stringify(data) === JSON.stringify(this.lastConfirmationData))
      return null;
    this.lastConfirmationData = data;
    return this.http$.put(
      this.api + '/confirmations/byIncident?iid=' + id,
      data
    );
  }

  public setMaintenanceStatesById(id: string, data: any): Observable<any> {
    if (JSON.stringify(data) === JSON.stringify(this.lastMaintenanceData))
      return null;
    this.lastMaintenanceData = data;
    return this.http$.put(
      this.api + '/maintenances/byIncident?iid=' + id,
      data
    );
  }

  public loadIncidentsByVehicle(vehicle?: Train): void {
    const selectedVehicle = vehicle || this._train$.getValue();
    if (selectedVehicle) {
      this.getIncidentsByVehicle(selectedVehicle.id)
        .pipe(
          takeUntil(this.ngUnsubscribe$),
          tap(() => this.clearDetails()),
          finalize(() => {
            this.train$ = selectedVehicle;
          })
        )
        .subscribe(incidents => {
          this.incidents$ = incidents;
        });
    } else {
      this.clearDetails();
    }
  }

  private clearDetails(): void {
    this.train$ = null;
    this.incidents$ = [];
    this.details$ = null;
    this.coordinates$ = [];
  }

  public loadIncidentDetails(id: string): void {
    this.resetDetails();
    this.getIncidentDetailsById(id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(details => {
        this.details$ = details;
        this.coordinates$ = details.gpsLocations;
      });
  }

  public updateStates(data: any): void {
    let target: Observable<any> = null;
    if (data.type === 'confirmation') {
      target = this.setConfirmationStatesById(data.id, {
        state: data.state,
        inspectionDate: data.date,
      });
    } else if (data.type === 'maintenance') {
      target = this.setMaintenanceStatesById(data.id, {
        state: data.state,
        repairDate: data.date,
      });
    }
    if (target) {
      target.pipe(take(1), distinctUntilChanged()).subscribe(() => {
        this.watchUpdates(data);
      });
    }
  }

  public watchUpdates(patch: any): void {
    // Update, on success update watchIncident
    let data: any;
    switch (patch.type) {
      case 'confirmation':
        data = {
          confirmation: {
            state: patch.state,
            inspectionDate: patch.date,
          },
        };
        break;
      case 'maintenance':
        data = {
          maintenance: {
            state: patch.state,
            repairDate: patch.date,
          },
        };
        break;
    }
    this.watchIncident$.next({
      id: patch.id,
      patch: data,
    });
  }

  get train$(): any {
    return this._train$.asObservable();
  }
  set train$(value: any) {
    this._train$.next(value);
  }
  get incidents$(): BehaviorSubject<Incident[]> {
    return this._incidents$;
  }
  set incidents$(value: any) {
    this._incidents$.next(value);
  }
  get details$(): any {
    return this._details$.asObservable();
  }
  set details$(value: any) {
    this._details$.next(value);
  }
  get coordinates$(): any {
    return this._coordinates$.asObservable();
  }
  set coordinates$(value: any) {
    this._coordinates$.next(value);
  }

  public resetIncidentList(): void {
    this.loadIncidentsByVehicle();
  }

  private resetDetails(): void {
    this.details$ = null;
    this.coordinates$ = Array<Coordinates>();
  }

  private exportFormRef: ComponentRef<IncidentExportComponent> = null;
  public createExportForm(ref: ViewContainerRef, event: PointerEvent): void {
    ref.clear();
    if (!this.exportFormRef) {
      this.exportFormRef = ref.createComponent(IncidentExportComponent);
      this.exportFormRef.instance.target = event.target;
      this.exportFormRef.instance.submitted
        .pipe(
          first(),
          finalize(() => this.destroyExportForm())
        )
        .subscribe();
    } else {
      this.destroyExportForm();
    }
  }

  public getDownloadableIncidents(from, to): Observable<unknown> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: {
        to: to,
        from: from,
      },
    });
    return this.http$.get(this.statisticApi + '/incidents/byFilter?' + params);
  }

  public destroyExportForm(): void {
    if (this.exportFormRef) {
      this.exportFormRef.destroy();
      this.exportFormRef = null;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
