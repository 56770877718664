<p class="train-config-step-info">
  Please enter the details for the ProPM gateways you want to assign for the
  vehicle. Up to {{ maxGateways }} ProPMs can be assigned to a vehicle. At least
  one ProPM has to be assigned.
</p>
<div class="d-flex w-100 h-100">
  <div class="flex-column w-100">
    <form [formGroup]="registerGatewaysForm" (ngSubmit)="onSubmit()">
      <div class="d-flex flex-row w-100">
        <div class="w-25 pr-3 pt-3 pb-3">
          <label for="input-gateway-serial">ProPM Serial Number</label>
          <input
            type="text"
            class="form-control"
            formControlName="gatewaySerial"
            [class.error]="
              gatewaySerial.invalid &&
              (gatewaySerial.touched || gatewaySerial.dirty)
            "
            id="input-gateway-serial"
            placeholder="e.g. 542643"
            [class.disabled]="gateways?.length >= maxGateways"
            [disabled]="gateways?.length >= maxGateways"
            required />
        </div>
        <div class="w-25 p-3">
          <label for="input-gateway-activation">Activation Code</label>
          <input
            type="text"
            class="form-control"
            [class.error]="
              gatewayActivation.invalid &&
              (gatewayActivation.touched || gatewayActivation.dirty)
            "
            id="input-gateway-activation"
            placeholder="pin"
            formControlName="gatewayActivation"
            [class.disabled]="gateways?.length >= maxGateways"
            [disabled]="gateways?.length >= maxGateways"
            required />
        </div>
        <div class="d-flex mr-auto p-3 align-items-end">
          <button
            type="submit"
            class="btn btn-primary"
            [class.disabled]="
              !registerGatewaysForm.valid || gateways?.length >= maxGateways
            "
            [disabled]="
              !registerGatewaysForm.valid || gateways?.length >= maxGateways
            ">
            Activate Gateway
          </button>
        </div>
      </div>
      <div *ngIf="error" class="alert alert-danger mb-3" @fade>
        <div>{{ error }}</div>
      </div>
      <div
        *ngIf="gateways?.length >= maxGateways"
        class="alert alert-danger mb-3"
        @fade>
        <div>Maximum number of attached gateways reached.</div>
      </div>
      <div
        *ngIf="
          gatewaySerial.invalid &&
          (gatewaySerial.dirty || gatewaySerial.touched)
        "
        class="alert alert-danger mb-3"
        @fade>
        <div *ngIf="gatewaySerial.errors?.['required']">
          ProPM Serial Number is required.
        </div>
        <div *ngIf="gatewaySerial.errors?.['minlength']">
          ProPM Serial Number minium length is {{ minLength }} digits.
        </div>
        <div *ngIf="gatewaySerial.errors?.['maxlength']">
          ProPM Serial Number maximum length is {{ maxLength }} digits.
        </div>
      </div>
      <div
        *ngIf="
          gatewayActivation.invalid &&
          (gatewayActivation.dirty || gatewayActivation.touched)
        "
        class="alert alert-danger mb-3"
        @fade>
        <div *ngIf="gatewayActivation.errors?.['required']">
          An activation code is required.
        </div>
      </div>
    </form>
    <div class="d-flex flex-row">
      <h4>Available Gateways</h4>
    </div>
    <div class="d-flex flex-row">
      <ng-container *ngIf="gateways?.length > 0; else empty">
        <idm-fleet-gateway
          *ngFor="let gateway of gateways"
          [state]="gateway.state"
          [actions]="true"
          [serial]="gateway.serial"
          (deleteClicked)="onDeleteClick($event)"></idm-fleet-gateway>
      </ng-container>
      <ng-template #empty>No registered devices available!</ng-template>
    </div>
  </div>
</div>
