import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

/**
 * Added success message for debugging of the ElectronJS desktop application
 */

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => null)
  .catch(err => console.error(err));

// Call the element loader after the platform has been bootstrapped
// defineCustomElements(window);
