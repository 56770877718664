import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function tagConfiguredValidator(gateways: Array<any>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let found = false;
    gateways.forEach(gateway => {
      return gateway?.tags?.find(tag => {
        if (tag === control.value) found = true;
      });
    });
    return found ? { tagExists: { value: control.value } } : null;
  };
}
