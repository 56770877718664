import {
  CancelModalComponent,
  Modal,
  SidebarItemSelectorComponent,
  Train,
} from './../../../core';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { COMPONENT_STATES } from './../../enums/component-states.enum';
import { MaintenanceService } from './../../services/maintenance.service';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'idm-maintenance-management',
  templateUrl: './maintenance-management.component.html',
})
export class MaintenanceManagementComponent implements OnInit, OnDestroy {
  private activeTrain: Train;
  private selectedTrain: Train;
  private nextUrl: string;

  private ngUnsubscribe$ = new Subject();

  @ViewChild('unsavedChangesModal', { read: ViewContainerRef })
  unsavedChangesModal: ViewContainerRef;
  @ViewChild('sidebarItems')
  sidebarItems: SidebarItemSelectorComponent;

  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.watchRouteChange();
  }

  public canExit(): boolean {
    const componentState: COMPONENT_STATES =
      this.maintenanceService.maintenanceComponentState;
    return (
      componentState === COMPONENT_STATES.Pristine ||
      componentState === COMPONENT_STATES.Saved
    );
  }

  public trainSelected(train: Train): void {
    this.activeTrain = this.selectedTrain;
    this.selectedTrain = train;
    if (this.canExit() !== false) {
      this.maintenanceService.setSelectedVehicle(train);
    }
  }

  private watchRouteChange() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url.indexOf('authentication') >= 0) {
          this.maintenanceService.maintenanceComponentState =
            COMPONENT_STATES.Pristine;
        }
        this.nextUrl = event.url;
        if (
          this.maintenanceService.maintenanceComponentState !==
            COMPONENT_STATES.Pristine &&
          this.maintenanceService.maintenanceComponentState !==
            COMPONENT_STATES.Saved
        ) {
          this.createUnsavedChangesModal(this.unsavedChangesModal).then(() => {
            this.store.dispatch(
              new Modal.Show('maintenance-unsaved-changes-modal')
            );
          });
        }
      }
    });
  }

  private createUnsavedChangesModal(ref: ViewContainerRef): Promise<string> {
    return new Promise(resolve => {
      ref.clear();
      const componentRef = ref.createComponent(CancelModalComponent);
      componentRef.instance.id = 'maintenance-unsaved-changes-modal';
      componentRef.instance.headline = 'Cancel Edit';
      componentRef.instance.description =
        'You have unsaved changes on the selected vehicle. Are you sure you want to leave?';
      componentRef.instance.canceled
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((state: boolean) => this.cancelRouteChange(state));
      componentRef.instance.confirmed
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((state: boolean) => this.leaveRoute(state));
      resolve('done');
    });
  }

  private cancelRouteChange(state: boolean): void {
    if (state === true) {
      this.store.dispatch(new Modal.Hide());
      if (this.nextUrl.indexOf('maintenance/manager/vehicle') > -1) {
        this.sidebarItems.resetSelection(this.activeTrain?.id || null);
      }
    }
  }

  private leaveRoute(state: boolean): void {
    if (state === true) {
      this.maintenanceService.maintenanceComponentState =
        COMPONENT_STATES.Saved;
      this.store.dispatch(new Modal.Hide());
    }
    if (this.nextUrl.indexOf('maintenance/manager/vehicle') > -1) {
      this.trainSelected(this.selectedTrain);
    } else {
      this.router.navigate([this.nextUrl]);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
