import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import {
  FleetConfigurationService,
  FleetConfiguratorEditTagsModalComponent,
  TAG_STATE,
} from './../../../../fleet';
import {
  TagActivationForm,
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../../vehicles';

import { Modal } from './../../../../core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'idm-fleet-configurator-edit-tags',
  templateUrl: './fleet-configurator-edit-tags.component.html',
})
export class FleetConfiguratorEditTagsComponent {
  public trainViewConfiguration: TrainViewConfiguration = {
    gateways: {
      editable: false,
      visible: true,
      counter: true,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: true,
          replaceable: false,
          visible: true,
        },
      },
    },
  };
  public model: TrainConfiguration;
  private subs: Array<Subscription> = new Array<Subscription>();

  @ViewChild('addModal', { read: ViewContainerRef }) addModal: ViewContainerRef;

  constructor(
    private fleetConfiguratorService: FleetConfigurationService,
    private store: Store,
    private viewContainerRef: ViewContainerRef
  ) {
    this.subs.push(
      this.fleetConfiguratorService
        .getConfiguration()
        .subscribe(configuration => {
          this.model = configuration;
        })
    );
  }

  public onTagClick(value: any): void {
    const bogie = this.model.trainStructure.bogies.find(
      fBogie => fBogie.name === value.bogie
    );
    const slot = bogie.slots.find(fSlot => fSlot.name === value.wheel);
    const bleId = slot?.tag?.bleId ? slot.tag.bleId : '';
    const gateway = this.model.gateways.find(fGateway => {
      return fGateway?.tags?.find((tag: string) => tag === bleId);
    });
    this.createModal(
      value?.wheel,
      value?.bogie,
      bleId,
      gateway ? gateway.serial : ''
    ).then(__done =>
      this.store.dispatch(new Modal.Show('fleet-configurator-edit-tags-modal'))
    );
  }

  public createModal(
    wheel: string,
    bogie: string,
    bleId?: string,
    gateway?: string
  ): Promise<string> {
    return new Promise((resolve, __reject) => {
      this.removeModal();
      const componentRef = this.viewContainerRef.createComponent(
        FleetConfiguratorEditTagsModalComponent
      );
      componentRef.instance.gateways = this.model.gateways;
      componentRef.instance.id = 'fleet-configurator-edit-tags-modal';
      componentRef.instance.wheel = wheel;
      componentRef.instance.bogie = bogie;
      componentRef.instance.bleId = bleId;
      componentRef.instance.gateway = gateway;
      componentRef.instance.selected.subscribe(data => {
        this.onSelect(data);
      });
      componentRef.instance.removed.subscribe(data => {
        this.onRemove(data);
      });
      resolve('done');
    });
  }

  public removeModal() {
    this.viewContainerRef.clear();
  }

  /**
   * Add data for tags and gateway to the trainStructure Model:
   * - tag ref and state into slot
   * - tag ref into selected gateways tags Array
   *
   * @param data
   */

  public onSelect(data: TagActivationForm): any {
    const bogies = this.model.trainStructure.bogies;
    const gateway = this.model.gateways.find(
      fFateway => fFateway.serial === data.gateway
    );
    const bogie = bogies.find(fBogie => fBogie.name === data.bogie);
    if (typeof bogie !== 'undefined') {
      const slot = bogie.slots.find(fSlot => fSlot.name === data.wheel);
      if (typeof slot !== 'undefined') {
        slot.tag = { bleId: data.mac, status: { type: TAG_STATE.connected } };
      }
    }
    if (typeof gateway?.tags !== 'undefined') {
      gateway.tags.push(data.mac);
    } else {
      gateway.tags = new Array<string>(data.mac);
    }
    this.fleetConfiguratorService.configuration.next(this.model);
    // Should detach
    this.addModal.clear();
  }

  public onRemove(data: TagActivationForm): any {
    const bogie = this.model.trainStructure.bogies.find(
      fBogie => fBogie.name === data.bogie
    );
    const slot = bogie.slots.find(fSlot => fSlot.tag?.bleId === data.mac);
    delete slot.tag;
    this.model.gateways.forEach(fGateway => {
      fGateway.tags = fGateway.tags?.filter(item => item !== data.mac);
    });
    this.fleetConfiguratorService.configuration.next(this.model);
  }
}
