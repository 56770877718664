import { Action, State, StateContext, StateToken } from '@ngxs/store';

import { Injectable } from '@angular/core';
import { Modal } from './modal.actions';

export interface ModalStateModel {
  id: string;
}
const INITIAL_STATE: ModalStateModel = { id: null };
const MODAL_STATE_TOKEN = new StateToken<ModalStateModel>('modal');

@State<ModalStateModel>({
  name: MODAL_STATE_TOKEN,
  defaults: INITIAL_STATE,
})
@Injectable()
export class ModalState {
  @Action(Modal.Show)
  show(
    { patchState }: StateContext<ModalStateModel>,
    { id }: Modal.Show
  ): void {
    patchState({ id: id });
  }

  @Action(Modal.Hide)
  hide({ patchState }: StateContext<ModalStateModel>): void {
    patchState({ id: null });
  }
}
