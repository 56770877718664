import { Component } from '@angular/core';
import { IncidentsService } from './../../../incidents/services/incidents.service';
import { Train } from './../../../core';

@Component({
  selector: 'idm-incident-management',
  templateUrl: './incident-management.component.html',
})
export class IncidentManagementComponent {
  constructor(private incidentsService: IncidentsService) {}

  public trainSelected(event: Train): void {
    this.incidentsService.loadIncidentsByVehicle(event);
  }
}
