<idm-basic-modal id="fleet-configurator-edit-tags-modal">
  <h3 *ngIf="actionType === 'NEW'">Configure HD-TAG</h3>
  <h3 *ngIf="actionType === 'REPLACE'">Replace HD-TAG</h3>
  <p>
    Please enter the BLE ID of the
    <span *ngIf="actionType === 'REPLACE'">new </span>HD-TAG’s Bluetooth
    adapter. You can find this information on the type plate of your HD-TAG.
    <span *ngIf="actionType === 'NEW'"
      >Secondly assign the specified HD-TAG to a ProPM gateway.</span
    >
  </p>
  <div class="d-flex flex-row info">
    <div class="m-3 d-flex align-items-center">
      <span class="material-icons">info</span>
    </div>
    <div>
      <p>
        Please ensure that the ProPM and the HD-TAG specified in this form must
        be up and running and the HD-TAG is in range of the ProPM.
      </p>
    </div>
  </div>
  <form [formGroup]="tagForm" (submit)="submit(tagForm.value)">
    <div class="d-flex">
      <div class="w-50">
        <label>Wheel Position</label>
        <div class="font-weight-bold mb-3">{{ wheel }}</div>
        <label>Bogie</label>
        <div class="font-weight-bold mb-3">{{ bogie }}</div>
      </div>
      <div class="w-50" *ngIf="actionType === 'REPLACE'">
        <label>Currently mounted HD-Tag</label>
        <div class="font-weight-bold mb-3">{{ bleId }}</div>
        <ng-container *ngIf="actionType === 'REPLACE'">
          <label>Assigned ProPM Gateway</label>
          <div class="font-weight-bold mb-3">
            {{ gateway || 'Currently not available' }}
          </div>
        </ng-container>
      </div>
    </div>
    <label for="mac"
      >{{ actionType === 'REPLACE' ? 'New' : '' }} HD-TAG’s BLE ID</label
    >
    <div class="input-group mb-3">
      <input
        trimInputValue
        type="text"
        mask="AA:AA:AA:AA:AA:AA"
        [dropSpecialCharacters]="false"
        class="form-control"
        id="mac"
        [placeholder]="
          scanActive
            ? 'Please scan the QR-Code on the typeplate of the HD-TAG'
            : 'xx:xx:xx:xx:xx:xx'
        "
        formControlName="mac"
        [attr.disabled]="scanActive ? true : null"
        [class.disabled]="scanActive"
        [class.error]="mac.invalid && (mac.dirty || mac.touched)"
        required="" />
      <div class="input-group-append">
        <button
          class="btn"
          [class.btn-success]="tagForm.valid"
          [class.btn-primary]="!scanActive && !tagForm.valid"
          [class.btn-warning]="scanActive && !tagForm.valid"
          type="button"
          (click)="toggleScan()">
          <span class="material-icons">qr_code_scanner</span>
        </button>
      </div>
    </div>
    <div *ngIf="mac.invalid && mac.touched && mac.errors" class="alert alert-danger mt-3 mb-3">
      <div *ngIf="mac.errors?.['required']">A valid BLEID is required.</div>
      <div *ngIf="mac.errors?.['pattern']"> 
        Wrong Format for BLEID.
      </div>
      <div
        *ngIf="mac.errors?.['tagExists'] || mac.errors?.['tagReplacementConflict']">
        HD-TAG has already been activated and mounted, please choose another one
        or remove it from the registered position and try again.
      </div>
    </div>

    <ng-container *ngIf="actionType === 'NEW'">
      <label for="gateway"
        >Serial Number of ProPM gateway to be assigned to</label
      >
      <select
        class="form-control custom-select mb-3"
        id="gateway"
        formControlName="gateway"
        required>
        <option
          value=""
          class="disabled"
          *ngIf="gateways?.length > 1"
          disabled
          hidden>
          Select a gateway
        </option>
        <option
          *ngFor="let gateway of gateways; let i = index"
          [value]="gateway['serial']">
          {{ gateway['serial'] }}
        </option>
      </select>
    </ng-container>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-3">
      <div>{{ error }}</div>
    </div>
    <div class="w-100 d-flex mt-5">
      <button
        *ngIf="actionType === 'NEW'"
        type="button"
        class="mr-auto btn btn-danger"
        (click)="remove()"
        [class.disabled]="!configured"
        [disabled]="!configured">
        Remove
      </button>
      <div *ngIf="actionType === 'REPLACE'" class="mr-auto">&nbsp;</div>
      <button type="button" class="btn btn-secondary mr-4" (click)="hide()">
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [class.disabled]="!tagForm.valid || (saving$ | async)"
        [disabled]="!tagForm.valid || (saving$ | async)">
        <span class="material-icons" *ngIf="!(saving$ | async)"> save</span>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="saving$ | async"></span>
        Save
      </button>
    </div>
  </form>
</idm-basic-modal>
