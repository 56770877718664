// Refer source: https://stackoverflow.com/questions/41308826/angular-2-debounce-ngmodelchange

import {
  Directive,
  EventEmitter,
  OnDestroy,
  Output,
  Input,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { skip, distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[modelChangeDebounced]',
})
export class ModelChangeDebouncedDirective implements OnDestroy {
  @Output()
  modelChangeDebounced = new EventEmitter<any>();
  @Input()
  modelChangeDebounceTime = 500; // optional, 500 default
  subscription: Subscription;
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  constructor(private ngModel: NgModel) {
    this.subscription = this.ngModel.control.valueChanges
      .pipe(
        skip(1), // skip initial value
        distinctUntilChanged(),
        debounceTime(this.modelChangeDebounceTime)
      )
      .subscribe(value => this.modelChangeDebounced.emit(value));
  }
}
