<div class="d-flex flex-column w-100 h-100">
  <div *ngIf="error$ | async" class="alert alert-danger mt-2" @fade>
    <div>{{ error$ | async }}</div>
  </div>
  <div>
    <p class="train-config-step-info">
      If you have descriptions for the wheels of the vehicle you are configuring
      please enter them as wheel names in the graphic below. This step is
      optional.
    </p>
  </div>
  <div class="flex-grow-1 d-flex w-100 flex-column">
    <idm-train
      class="w-100"
      [model]="model"
      [viewConfig]="trainViewConfiguration"></idm-train>
  </div>
</div>
