import {
  FleetComponentsComponent,
  FleetConfigurationService,
  FleetConfiguratorComponent,
  FleetConfiguratorConnectGatewaysComponent,
  FleetConfiguratorConnectTagsComponent,
  FleetConfiguratorEditGatewaysComponent,
  FleetConfiguratorEditTagsComponent,
  FleetConfiguratorEditTagsModalComponent,
  FleetConfiguratorEditTrainComponent,
  FleetConfiguratorEditWheelComponent,
  FleetConfiguratorNavComponent,
  FleetConfiguratorSaveComponent,
  FleetConfiguratorStepsComponent,
  FleetContentComponent,
  FleetDetailsComponent,
  FleetGatewayComponent,
  FleetGatewaysComponent,
  FleetInventoryComponent,
  FleetManagementComponent,
  FleetMapComponent,
  FleetTagComponent,
} from '.';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ItemsService, TrimInputValueDirective } from './../core';
import { RouterModule, Routes } from '@angular/router';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthenticationGuard } from './../iam';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import { FleetConfigurationComponent } from './components/fleet-configuration/fleet-configuration.component';
import { FleetService } from './../fleet/services/fleet.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { VehiclesModule } from './../vehicles';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

const configurationChildren = [
  {
    path: 'configurator',
    component: FleetConfiguratorComponent,
    children: [
      {
        path: 'edit-train',
        component: FleetConfiguratorEditTrainComponent,
        data: { animation: 'step1' },
      },
      {
        path: 'edit-wheel',
        component: FleetConfiguratorEditWheelComponent,
        data: { animation: 'step2' },
      },
      {
        path: 'edit-gateways',
        component: FleetConfiguratorEditGatewaysComponent,
        data: { animation: 'step3' },
      },
      {
        path: 'connect-gateways',
        component: FleetConfiguratorConnectGatewaysComponent,
        data: { animation: 'step4' },
      },
      {
        path: 'edit-tags',
        component: FleetConfiguratorEditTagsComponent,
        data: { animation: 'step5' },
      },
      {
        path: 'connect-tags',
        component: FleetConfiguratorConnectTagsComponent,
        data: { animation: 'step6' },
      },
      {
        path: 'save',
        component: FleetConfiguratorSaveComponent,
        data: { animation: 'step7' },
      },
    ],
  },
];

const routes: Routes = [
  {
    path: 'devices/manager/:tab/vehicle/:id',
    canActivate: [AuthenticationGuard],
    component: FleetManagementComponent,
    data: {
      role: ['idm_user', 'idm_admin'],
    },
  },
  {
    path: 'fleets/manager/:tab/vehicle/:id',
    canActivate: [AuthenticationGuard],
    component: FleetManagementComponent,
    data: {
      role: ['idm_user', 'idm_admin'],
    },
  },
  {
    path: 'fleets/manager/:tab',
    canActivate: [AuthenticationGuard],
    component: FleetManagementComponent,
    data: {
      role: ['idm_user', 'idm_admin'],
    },
    children: [...configurationChildren],
  },
];

@NgModule({
  declarations: [
    FleetDetailsComponent,
    FleetManagementComponent,
    FleetContentComponent,
    FleetInventoryComponent,
    FleetMapComponent,
    FleetGatewaysComponent,
    FleetComponentsComponent,
    FleetConfiguratorComponent,
    FleetConfiguratorEditTrainComponent,
    FleetConfiguratorEditWheelComponent,
    FleetConfiguratorEditGatewaysComponent,
    FleetConfiguratorConnectGatewaysComponent,
    FleetConfiguratorConnectTagsComponent,
    FleetConfiguratorEditTagsComponent,
    FleetConfiguratorSaveComponent,
    FleetConfiguratorNavComponent,
    FleetConfiguratorStepsComponent,
    FleetConfiguratorEditTrainComponent,
    FleetGatewayComponent,
    FleetConfiguratorEditTagsModalComponent,
    FleetTagComponent,
    FleetConfigurationComponent,
    TrimInputValueDirective,
  ],
  imports: [
    HttpClientModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    MatIconModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxSkeletonLoaderModule,
    GoogleMapsModule,
    QRCodeModule,
    NgxMaskModule.forRoot(),
    VehiclesModule,
  ],
  providers: [FleetService, FleetConfigurationService, ItemsService],
})
export class FleetModule {}
