import { AbstractVehicleViewComponent } from './../../../abstracts/abstract-vehicle-view.component';
import { Component } from '@angular/core';

@Component({
  selector: 'idm-train-bogie',
  templateUrl: './train-bogie.component.html',
  styleUrls: ['./train-bogie.component.scss'],
})
export class TrainBogieComponent extends AbstractVehicleViewComponent {
  public onTagClick(value: string): void {
    value['bogie'] = this.model.name;
    this.tagClicked.emit(value);
  }
}
