import {
  ActionCompletion,
  Actions,
  Select,
  Store,
  ofActionCompleted,
  ofActionDispatched,
} from '@ngxs/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, filter, map, take, takeUntil } from 'rxjs/operators';

// Module
import { Authentication } from './../../iam/store/authentication/authenticaton.actions';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationMessageType } from './../../core/models/notification-message-type.enum';
import { Notifications } from './../../core/store/notifications/notifications.action';
import { environment } from './../../../environments/environment';

class DecodedToken {
  exp: number;
  name: string;
  preferredUsername: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private returnUrl: string;
  private api = environment.loginApi;
  private params$: Observable<any>;
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  private refreshCounter = 0;
  private ngUnsubscribe$ = new Subject();
  public isRunning = false;

  public jwt: JwtHelperService = new JwtHelperService();

  @Select((state: any) => state.authentication.refreshToken)
  refreshToken$: Observable<any>;

  constructor(
    private http$: HttpClient,
    private router$: Router,
    private store$: Store,
    private actions$: Actions
  ) {
    this.actions$
      .pipe(ofActionCompleted(Authentication.Login))
      .subscribe((state: ActionCompletion) => {
        if (state.result.successful) {
          this.redirect();
        }
      });
    this.actions$
      .pipe(ofActionDispatched(Authentication.Logout))
      .subscribe(() => {
        this.logout();
      });
    this.params$ = this.router$.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(
        () =>
          this.getLeafRoute(this.router$.routerState.root).snapshot.queryParams
      )
    );
    this.params$.subscribe(params => {
      this.returnUrl = params.returnUrl || '/apps/selector';
    });
  }

  private getLeafRoute(route: ActivatedRoute): ActivatedRoute {
    if (route === null) {
      return null;
    }
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  public login(userData: any): Observable<any> {
    const URI = this.api + '/login';
    const options = { headers: this.headers };
    const params = new HttpParams({
      fromObject: { email: userData.email, password: userData.password },
    });

    return this.http$.post(URI, params, options).pipe(
      map((result: any) => {
        if (result) {
          const decoded: any = this.decodeToken(result.access_token);
          result.iss = decoded.iss;
          result.username = decoded.preferred_username;
          result.realname = decoded.name;
          result.realm_access = decoded.realm_access;
          result.expires = decoded.exp;
          if (decoded?.tenant) {
            result.tenantId = decoded.tenant.id;
            result.tenantName = decoded.tenant.name;
          } else {
            const parsed = this.parseTenantFromIss(decoded.iss);
            result.tenantId = result.tenantName = parsed;
          }
          this.startRefreshTokenTimer(decoded.exp);
          this.store$.dispatch(
            new Notifications.AddNotification({
              type: NotificationMessageType.success,
              msg: `Welcome ${decoded.name}! <br /> You have been successfully logged in`,
            })
          );
        }
        return result;
      })
    );
  }

  private parseTenantFromIss(iss: string): string {
    const splitted = iss.split('/');
    return splitted[splitted.length - 1];
  }

  public redirect(): void {
    this.router$.navigateByUrl(this.returnUrl);
  }

  public logout(): void {
    this.router$
      .navigate(['/authentication/login'], {
        replaceUrl: true,
        queryParams: {
          returnUrl:
            this.router$.url !== '/' &&
            this.router$.url.indexOf('authentication') === -1
              ? this.router$.url
              : '/apps/selector',
        },
      })
      .then(() => {
        this.unsubscribe();
        this.store$.dispatch(
          new Notifications.AddNotification({
            type: NotificationMessageType.success,
            msg: 'You have been successfully logged out',
          })
        );
      });
  }

  public decodeToken(token: string): string | Promise<string> {
    return this.jwt.decodeToken(token);
  }

  public refresh(): any {
    const refreshToken = JSON.parse(
      localStorage.getItem('authentication')
    ).refreshToken;
    const options = { headers: this.headers };
    const params = new HttpParams({
      fromObject: { refresh_token: refreshToken },
    });
    return this.http$
      .post<any>(`${environment.loginApi}/login`, params, options)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError((error: HttpErrorResponse): any => {
          this.logout();
          return throwError(error);
        }),
        map(result => {
          const decoded: any = this.decodeToken(result.access_token);
          result.expires = decoded.exp;
          this.refreshCounter = this.refreshCounter + 1;
          this.startRefreshTokenTimer(decoded.exp);
          return result;
        })
      );
  }

  // helper methods
  // Should this be handled here?
  private startRefreshTokenTimer(tokenExpires: any) {
    // Start Refresh Token Timer on Reload!
    this.isRunning = true;
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(tokenExpires * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    const source = timer(timeout);
    // Wake up? https://stackoverflow.com/questions/45938689/angular-2-application-on-mobile-browser-sleep-detection
    source.pipe(takeUntil(this.ngUnsubscribe$), take(1)).subscribe(() => {
      this.store$.dispatch(new Authentication.Refresh());
    });
  }

  private unsubscribe(): void {
    this.ngUnsubscribe$.next(true);
  }
}
