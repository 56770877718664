<div class="container utilities">
  <div class="d-flex flex-nowrap align-middle">
    <div class="d-flex flex-nowrap align-left">
      <span class="app-counter">DEVICES ({{ devices?.length }})</span>
    </div>
    <div class="flex-grow-1 flex-nowrap selections">
      <ng-container *ngIf="showFilter">
        <a
          (click)="loadAllDevices(type)"
          [class.active]="filter === 'all'"
          class="badge badge-primary filter-all"
          >ALL DEVICES</a
        >
        <ng-container *ngIf="this.type !== 'hdtag'">
          <a
            *ngFor="let state of deviceStates | keyvalue"
            (click)="filterByStatus(state.value)"
            [class.active]="filter === state.value"
            class="badge badge-primary filter-{{ state.value }}">
            {{ state.value }}
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="showSettings">
        <a class="badge badge-primary" (click)="lines = !lines">Lines</a>
        <a class="badge badge-primary filter-dead" (click)="colored = !colored"
          >Colors</a
        >
        <a class="badge badge-primary filter-dead" (click)="shadow = !shadow"
          >Shadow</a
        >
      </ng-container>
    </div>
    <div class="d-flex flex-nowrap text-right functions">
      <form
        class="form-inline d-inline"
        #searchDeviceForm
        (submit)="(null); $event.preventDefault()"
        [class.d-none]="this.type === 'hdtag'">
        <div
          class="input-group"
          [class.d-inline]="showSearch"
          [class.d-none]="!showSearch || this.type === 'hdtag'">
          <input
            type="text"
            #searchDeviceInput
            class="form-control search-serial"
            placeholder="Serialnumber"
            [class.d-none]="this.type === 'hdtag'" />
          <button
            class="btn bg-transparent clear-btn"
            (click)="loadAllDevices(type)"
            [class.d-none]="this.type === 'hdtag'">
            <span class="material-icons" [class.d-none]="this.type === 'hdtag'"
              >cancel</span
            >
          </button>
          <span
            class="arrow right"
            [class.d-none]="this.type === 'hdtag'"></span>
        </div>
      </form>
      <a
        class="badge badge-light badge-icon"
        (click)="toggleSearch()"
        [class.d-none]="this.type === 'hdtag'"
        ><span class="material-icons">search</span></a
      >
      <a
        class="badge badge-light badge-icon"
        (click)="toggleFilter()"
        [class.d-none]="this.type === 'hdtag'"
        ><span class="material-icons">filter_list</span></a
      >
      <a class="badge badge-light badge-icon" (click)="toggleSettings()"
        ><span class="material-icons">settings</span></a
      >
    </div>
  </div>
</div>

<div class="text-center data-spinner" [class.spinner-hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  class="text-center device-error"
  [class.sidebar-closed]="!(sidebar$ | async)"
  [class.sidebar-opened]="sidebar$ | async">
  <div class="container">
    <div
      class="row"
      [class.error-hidden]="!error"
      [class.error-visible]="error">
      <div class="col-6 text-right">
        <img
          src="assets/graphics/oops-404-robot.svg"
          class="oops-404-robot"
          alt="404 not found" />
      </div>
      <div class="col-6 text-left my-auto">
        <h4>We are sorry!</h4>
        <h2>There are no such devices available...</h2>
        <p>
          Please try again with another option.<br />If the problem persists,
          please <a href="mailto:dppm.zf@zf.com">contact us</a>!
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row" [@listAnimation]="devices.length">
    <ng-container *ngFor="let device of devices">
      <div class="columns col-xxl-2 col-xl-3 col-md-4 col-sm-5">
        <div
          class="device-tile {{ type }}"
          [class.drop-shadow]="shadow"
          [class.no-drop-shadow]="!shadow"
          [class.connected]="device?.device"
          (click)="device.device ? loadConnections('propm', device) : null">
          <div class="row device-header">
            <div
              class="device-icon"
              [class.colored]="colored"
              [ngClass]="device.status.type | lowercase">
              <div class="svg-icon-wrapper">
                <svg-icon src="assets/graphics/icon-{{ deviceIcon }}.svg">
                </svg-icon>
              </div>
            </div>
            <div class="device-serial">
              <label>Serial No.: </label>
              <h3>{{ device.sn }}</h3>
            </div>
            <div class="mount-state">
              <span class="material-icons" *ngIf="device.device">link</span>
              <span class="material-icons" *ngIf="!device.device"
                >link_off</span
              >
            </div>
          </div>
          <div
            class="device-visualizer"
            [class.hidden]="!lines"
            [class.visible]="lines">
            <div class="heart-rate">
              <div
                [ngClass]="device.status.type | lowercase"
                [inlineSVG]="
                  'assets/graphics/heart-rate-' +
                  (device.status.type | lowercase) +
                  '.svg'
                "></div>

              <div class="fade-in"></div>
              <div class="fade-out"></div>
            </div>
          </div>
          <div class="device-update">
            <label>Last Update:</label>
            <p>{{ device.status.timestamp | date: 'short' }}</p>
            <p *ngIf="device.bleId">BLE: {{ device.bleId | uppercase }}</p>
          </div>
          <div class="device-state">
            <span
              class="badge badge-pill badge-primary"
              [ngClass]="device.status.type | lowercase"
              >{{ device.status.type }}</span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
