import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { Coordinates } from './../../../core';
import { IncidentsService } from './../../services/incidents.service';
import { Observable } from 'rxjs';

/**
 * About Angular google-map implementation:
 * https://timdeschryver.dev/blog/google-maps-as-an-angular-component
 */

@Component({
  selector: 'idm-incident-map',
  templateUrl: './incident-map.component.html',
  styleUrls: ['./incident-map.component.scss'],
})
export class IncidentMapComponent implements OnInit {
  public zoom = 12;
  public center = {
    lat: 47.66481,
    lng: 9.471089,
  };
  public options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    scaleControl: true,
    disableDoubleClickZoom: false,
  };
  public gMapsApiLoaded$: Observable<boolean>;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) mapMarker: MapMarker;
  @ViewChild('markerElem', { static: false }) markerElem: MapMarker;

  @Input() incidentType: string;

  public markers: Array<any> = new Array<any>();
  public infoPosition: Coordinates;

  constructor(private incidentsService: IncidentsService) {}

  ngOnInit(): void {
    this.incidentsService._coordinates$.subscribe(
      (coordinates: Array<Coordinates>) => {
        this.setMarkers(coordinates);
        if (this.markers?.length > 0) {
          const center = this.markers[0].position;
          this.setCenter({
            latitude: center.lat,
            longitude: center.lng,
          });
        }
      }
    );
  }

  setMarkers(source: Array<Coordinates>) {
    this.markers = new Array<Coordinates>();
    if (typeof source !== undefined) {
      source?.forEach(item => {
        if (item?.latitude > 0 && item?.longitude > 0) {
          this.markers.push({
            position: {
              lat: item.latitude,
              lng: item.longitude,
            },
            options: {
              icon: '/assets/images/map-incident-marker.png',
            },
          });
        }
      });
    }
  }

  openInfo(marker: MapMarker) {
    const positions = marker.getPosition();
    this.infoPosition = {
      latitude: positions ? positions.lat() : 0,
      longitude: positions ? positions.lng() : 0,
    };
    this.map.panTo({
      lat: positions ? positions.lat() : 0,
      lng: positions ? positions.lng() : 0,
    });
    this.infoWindow.open(marker);
  }

  setCenter(coordinate: Coordinates): void {
    this.center = {
      lat: coordinate.latitude,
      lng: coordinate.longitude,
    };
  }
}
