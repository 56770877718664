<div class="container h-100 zf-gradient-background">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="wrapper row">
      <form
        class="form-signin zf-gradient-background col-xl-6 col-md-12"
        [formGroup]="loginForm"
        (ngSubmit)="login()">
        <h2 class="page-title mb-4">Login</h2>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white border-0"
              ><span
                [ngClass]="{
                  error:
                    isValidInput('user') &&
                    (loginForm.controls['user'].errors.required ||
                      loginForm.controls['user'].errors.pattern)
                }"
                class="zf-icon-person-neutral"></span
            ></span>
          </div>
          <input
            type="text"
            class="form-control bg-white border-0 pl-0"
            placeholder="Email address"
            autocomplete="email"
            aria-label="Email address"
            required=""
            formControlName="email"
            [ngClass]="{
              error:
                isValidInput('email') &&
                (loginForm.controls['email'].errors.required ||
                  loginForm.controls['email'].errors.pattern)
            }" />
        </div>

        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white border-0"
              ><span
                [ngClass]="{
                  error:
                    isValidInput('password') &&
                    loginForm.controls['password'].errors.required
                }"
                class="zf-icon-padlock-closed"></span
            ></span>
          </div>
          <input
            type="password"
            class="form-control bg-white border-0 pl-0"
            placeholder="Password"
            aria-label="Password"
            autocomplete="current-password"
            required=""
            formControlName="password"
            [ngClass]="{
              error:
                isValidInput('password') &&
                loginForm.controls['password'].errors.required
            }" />
        </div>
        <button
          type="button"
          class="btn btn-login btn-primary btn-labeled btn-custom btn-lg btn-block mb-3"
          [disabled]="!loginForm.valid"
          type="submit">
          <span class="btn-label"
            ><span class="material-icons">person</span></span
          ><span class="btn-text">Login</span>
        </button>
      </form>
      <div
        class="mood-img-container col d-none d-md-block {{ env.portal }}"></div>
    </div>
  </div>
</div>
