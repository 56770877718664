import * as moment from 'moment';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'idm-datepicker-popup',
  templateUrl: './datepicker-popup.component.html',
  styleUrls: ['./datepicker-popup.component.scss'],
})
export class DatepickerPopupComponent implements OnChanges {
  @Input() disabled = false;
  @Input() disabledWithDate = false;
  @Input() initDate: any | boolean = null;
  @Input() initDateOnActivation = true;
  @Input() maxDate: any = null;
  @Input() minDate: any = null;
  @Input() label: string = null;

  @Output()
  dateSelectEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('datepicker') datepicker: NgbDatepicker;

  public date: any = null;

  constructor(private calendar: NgbCalendar) {}

  public ngOnChanges(): void {
    let date;
    // only react if component is enabled
    if (!this.disabled) {
      if (!this.maxDate) {
        this.maxDate = this.calendar.getToday();
      } else {
        this.maxDate = this.convertTimestamp(this.maxDate);
      }
      if (this.minDate) {
        this.minDate = this.convertTimestamp(this.minDate);
      }
      // try to set the date to the initialized date, if not it will be null
      if (this.initDate !== false) {
        date = this.convertTimestamp(this.initDate);
      }
      // if there is no date available and it should be initialized
      // on activation, set the datefield to today
      if (!date && this.initDateOnActivation === true) {
        date = this.calendar.getToday();
      }
      this.setDate(date);
    } else if (this.disabled) {
      // Reset date to null if component is disabled
      this.setDate(null);
    }
  }

  private convertTimestamp(data: any): any {
    if (data instanceof NgbDate === false) {
      const date = moment.utc(data);
      return new NgbDate(
        +date.format('YYYY'),
        +date.format('M'),
        +date.format('D')
      );
    } else {
      return data;
    }
  }

  private convertToUtcIsoString(date: NgbDate): string {
    // Date.UTC need the month from 0..11
    // see: https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
    return date
      ? moment.utc(Date.UTC(date.year, date.month - 1, date.day)).toISOString()
      : null;
  }

  public setDate(date: any): void {
    this.date = date;
    // reset initDate, it's not valid anymore
    if (this.initDate !== null) {
      this.initDate = null;
    } else {
      this.dateSelectEvent.emit(this.convertToUtcIsoString(this.date));
    }
  }

  public getSelectedDate(): NgbDate {
    return this.date;
  }
}
