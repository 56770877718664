import {
  GATEWAY_STATE,
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../../vehicles';

import { Component } from '@angular/core';
import { FleetConfigurationService } from './../../../../fleet/services/fleet-configuration.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'idm-fleet-configurator-connect-gateways',
  templateUrl: './fleet-configurator-connect-gateways.component.html',
})
export class FleetConfiguratorConnectGatewaysComponent {
  public trainViewConfiguration: TrainViewConfiguration = {
    actions: {
      connectGateways: true,
    },
    gateways: {
      editable: true,
      visible: true,
      counter: false,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: false,
          replaceable: false,
          visible: false,
        },
      },
    },
  };

  public model: TrainConfiguration;
  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(private fleetConfiguratorService: FleetConfigurationService) {
    this.model.trainStructure.bogies.forEach(bogie =>
      [...bogie.slots].reverse()
    );
    this.subs.push(
      this.fleetConfiguratorService
        .getConfiguration()
        .subscribe(configuration => {
          if (Object.keys(configuration).length > 0) this.model = configuration;
        })
    );
  }

  onConnectGatwaysClicked(): void {
    this.model.gateways.forEach(gateway => {
      gateway.state = GATEWAY_STATE.connected;
    });
  }
}
