import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GATEWAY_STATE,
  GatewayConfiguration,
  TrainConfiguration,
} from './../../../../vehicles';

import { FleetConfigurationService } from './../../../../fleet/services/fleet-configuration.service';
import { Subscription } from 'rxjs';
import { fadeInAndOutAnimation } from './../../../../core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'idm-fleet-configurator-edit-gateways',
  templateUrl: './fleet-configurator-edit-gateways.component.html',
  animations: [fadeInAndOutAnimation],
  styleUrls: ['./fleet-configurator-edit-gateways.component.scss'],
})
export class FleetConfiguratorEditGatewaysComponent implements OnDestroy {
  public maxGateways = 3;
  public minLength = 6;
  public maxLength = 6;
  public error: string = null;
  public model: TrainConfiguration;

  public registerGatewaysForm = new FormGroup({
    gatewaySerial: new FormControl('', {
      validators: [
        Validators.required,
        Validators.maxLength(this.maxLength),
        Validators.minLength(this.minLength),
      ],
      updateOn: 'blur',
    }),
    gatewayActivation: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  public gateways: Array<GatewayConfiguration> =
    new Array<GatewayConfiguration>();

  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(private fleetConfigurationService: FleetConfigurationService) {
    // needed for redirection if no configuration object is present
    this.subs.push(
      this.fleetConfigurationService
        .getConfiguration()
        .subscribe((config: TrainConfiguration) => {
          this.model = config;
          if (config?.gateways?.length > 0) {
            this.gateways = config.gateways;
          }
        })
    );
  }

  public onSubmit() {
    const serial = this.registerGatewaysForm.value['gatewaySerial'];
    const pin = this.registerGatewaysForm.value['gatewayActivation'];
    const registered = this.gateways.some(gateway => gateway.serial === serial);
    if (this.registerGatewaysForm.valid && !registered) {
      this.fleetConfigurationService
        .getGatewayActivation(serial, pin)
        .pipe(first())
        .subscribe({
          next: () => {
            this.updateModel(serial, GATEWAY_STATE.connected);
          },
          error: (error: any) => {
            // DECOMISSION IF CONFLICT AND THEN RETRY IF 409 CONFLICT
            if (error.status === 409) {
              this.fleetConfigurationService
                .getGateway(serial)
                .pipe(first())
                .subscribe({
                  next: () => {
                    this.updateModel(serial, GATEWAY_STATE.connected);
                  },
                  error: (errorNext: any) => {
                    this.error = errorNext.text;
                  },
                });
            } else {
              this.error = error.text;
            }
          },
        });
    } else if (registered) {
      this.error =
        'You have already registered and activated the gateway with the serial: ' +
        serial;
    }
  }

  private updateModel(serial: string, state: GATEWAY_STATE) {
    this.error = null;
    this.gateways.push({
      serial: serial,
      state: state,
    });
    this.registerGatewaysForm.reset();
    this.fleetConfigurationService.setAttribute(['gateways'], this.gateways);
  }

  public onDeleteClick(serial: string) {
    // Find Tags connected to the gateways
    this.gateways.forEach((gateway: any) => {
      if (gateway.serial === serial) {
        const tags: Array<any> = gateway?.tags;
        if (tags) {
          tags.forEach(tag => {
            // Find in slots
            this.model.trainStructure.bogies.forEach(bogie => {
              bogie.slots.forEach(slot => {
                if (slot?.tag.bleId === tag) {
                  delete slot.tag;
                }
              });
            });
          });
        }
      }
    });
    // Remove Gateway
    this.gateways = this.gateways.filter(gateway => gateway.serial !== serial);
    this.fleetConfigurationService.configuration.next(this.model);
    this.fleetConfigurationService.setAttribute(['gateways'], this.gateways);
  }

  // Template getter functions

  get gatewaySerial() {
    return this.registerGatewaysForm.get('gatewaySerial');
  }

  get gatewayActivation() {
    return this.registerGatewaysForm.get('gatewayActivation');
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
