import {
  CONFIRMATION_STATES,
  Incident,
  fadeInAndOutAnimation,
} from './../../../core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delayWhen, filter, takeUntil, tap } from 'rxjs/operators';

import { FileContext } from './../../../core/models/file-context.model';
import { IncidentsService } from './../../../incidents/services/incidents.service';
import { MAINTENANCE_STATES } from './../../../core/enums/maintenance-states.enum';
import { timer } from 'rxjs';

interface StateSelectEvent extends MouseEvent {
  state: CONFIRMATION_STATES | MAINTENANCE_STATES;
  date: string | null;
  id: string;
}
@Component({
  selector: 'idm-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class IncidentDetailsComponent implements OnInit {
  public details: Incident = null;
  public maintenanceDisabled = true;
  public initDataConfirmation: unknown = null;
  public initDataMaintenance: unknown = null;
  public rejectDisabled = false;
  public maintenanceMinDate: unknown = null;
  public fileContext: FileContext;
  public incidents$: Observable<unknown> = new Observable<unknown>();
  public loading = true;
  public pulse = true;
  private animationsDelay = 251;
  private animationsDelayLong = this.animationsDelay * 4;
  private delayFor = () => timer(this.animationsDelay);
  private delayForLong = () => timer(this.animationsDelayLong);
  private ngUnsubscribe$ = new Subject();

  constructor(
    private incidentsService: IncidentsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.incidents$ = this.incidentsService.incidents$;
    this.incidentsService.details$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(details => details === null)
      )
      .subscribe({
        next: () => {
          this.details = null;
        },
      });
    this.incidentsService.details$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(() => {
          this.pulse = true;
        }),
        delayWhen(this.delayFor),
        tap(() => (this.loading = true)),
        delayWhen(this.delayForLong)
      )
      .subscribe((details: Incident) => {
        if (details !== null) {
          this.loading = false;
          setTimeout(() => {
            this.details = details;
            this.fileContext = {
              source: 'incident',
              attribute: 'iid',
              id: details.id,
              max: 10,
            };
            this.incidentsService.lastConfirmationData =
              this.initDataConfirmation = this.details.confirmation;
            this.incidentsService.lastMaintenanceData =
              this.initDataMaintenance = this.details.maintenance;
            if (
              details?.confirmation?.state === CONFIRMATION_STATES.CONFIRMED
            ) {
              this.maintenanceDisabled = false;
            } else {
              this.maintenanceDisabled = true;
            }
            if (details?.maintenance?.state === MAINTENANCE_STATES.REPAIRED) {
              this.rejectDisabled = true;
              this.maintenanceMinDate =
                this.details.confirmation.inspectionDate;
            }
          }, this.animationsDelay);
        } else {
          setTimeout(() => (this.pulse = false), this.animationsDelayLong);
        }
      });
  }

  public setState(event: StateSelectEvent) {
    this.cdr.markForCheck();
    // SET State for Confirmation
    if (event) {
      if (event.state === CONFIRMATION_STATES.CONFIRMED) {
        this.maintenanceDisabled = false;
      } else if (event.state === CONFIRMATION_STATES.REJECTED) {
        this.maintenanceDisabled = true;
      } else if (event.state === CONFIRMATION_STATES.OPEN) {
        this.maintenanceDisabled = true;
      }
      // SET state for Maintenance
      if (event.state === MAINTENANCE_STATES.REPAIRED) {
        this.rejectDisabled = true;
      } else if (event.state === MAINTENANCE_STATES.UNREPAIRED) {
        this.rejectDisabled = false;
      }
      // SET mindate for maintenance datepicker
      if (event.type === 'confirmation') {
        if (event.date) {
          this.maintenanceMinDate = event.date;
        }
      }
      event.id = this.details.id;
      this.incidentsService.updateStates(event);
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
