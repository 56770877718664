import { FleetConfigurationService } from './../../../services/fleet-configuration.service';
import {
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'idm-fleet-configurator-nav',
  templateUrl: './fleet-configurator-nav.component.html',
})
export class FleetConfiguratorNavComponent implements OnDestroy {
  public next = () => null;
  public previous = () => null;
  public cancel = () => null;
  public done: boolean;
  public valid: boolean;
  public nextActive: boolean;
  public prevActive: boolean;
  public finalStep: boolean;
  private subs: Array<Subscription> = new Array<Subscription>();

  @ViewChild('cancelModal', { read: ViewContainerRef })
  cancelModal: ViewContainerRef;

  constructor(private fleetConfigurationService: FleetConfigurationService) {
    this.next = this.fleetConfigurationService.next.bind(
      fleetConfigurationService
    );
    this.previous = this.fleetConfigurationService.previous.bind(
      fleetConfigurationService
    );
    this.cancel = this.fleetConfigurationService.cancel.bind(
      fleetConfigurationService
    );
    this.subs.push(
      this.fleetConfigurationService.nextActive$.subscribe(state => {
        this.nextActive = state;
      })
    );
    this.subs.push(
      this.fleetConfigurationService.prevActive$.subscribe(state => {
        this.prevActive = state;
      })
    );
    this.subs.push(
      this.fleetConfigurationService.finalStep$.subscribe(state => {
        this.finalStep = state;
      })
    );
    this.subs.push(
      this.fleetConfigurationService.valid$.subscribe(state => {
        this.valid = state;
      })
    );
    this.subs.push(
      this.fleetConfigurationService.done$.subscribe(state => {
        this.done = state;
      })
    );
  }

  onCancelClick(): void {
    this.fleetConfigurationService.cancel(this.cancelModal);
  }
  onSaveClick(): void {
    this.fleetConfigurationService.save();
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
