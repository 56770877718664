<div>
  <ng-container *ngIf="model?.gateways?.length > 0">
    <div class="pr-5 pl-5 w-100">
      <div class="d-flex flex-row w-100">
        <idm-gateway
          *ngFor="let gateway of model.gateways"
          [serial]="gateway.serial"
          [state]="gateway.state"
          [actions]="viewConfig.gateways?.editable"
          [tags]="gateway?.tags"
          [counter]="viewConfig.gateways?.counter"></idm-gateway>
        <div class="ml-auto"></div>
      </div>
    </div>
  </ng-container>
</div>
