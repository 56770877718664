import { FleetService } from '@idm/fleet/services/fleet.service';
import { fadeInAndOutAnimation } from '@idm/core';
import { FleetConfigurationService } from '@idm/fleet';
import { slideInRouteAnimation } from './../../../core/animations/slide-in-route-animation';
import { RouterOutlet } from '@angular/router';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'idm-fleet-configurator',
  templateUrl: './fleet-configurator.component.html',
  styleUrls: ['./fleet-configurator.component.scss'],
  animations: [slideInRouteAnimation, fadeInAndOutAnimation],
})
export class FleetConfiguratorComponent implements OnDestroy, AfterViewInit {
  public init$: Subject<boolean> = new Subject();

  constructor(
    private fleetConfigurationService: FleetConfigurationService,
    private fleetService: FleetService
  ) {
    this.fleetConfigurationService.initConfiguration();
  }

  ngAfterViewInit(): void {
    this.fleetService.resetVehicle();
    this.fleetService.resetAll().then(() => {
      this.init$.next(true);
    });
  }

  public prepareRoute(outlet: RouterOutlet): any {
    // IMPORTANT: This one has to change that the animation is triggered, currently
    // this is done via route data.animation naming with manual increment.
    // maybe another property would suffice (like the route target?)
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  ngOnDestroy(): void {
    this.fleetConfigurationService.killConfiguration();
    this.init$.next(false);
  }
}
