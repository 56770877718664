import { Injectable } from '@angular/core';
import packageInfo from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  public version: string;

  constructor() {
    this.version = packageInfo.version;
  }
}
