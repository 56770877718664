import { Component, OnDestroy, OnInit } from '@angular/core';

import { FleetService } from './../../services/fleet.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'idm-fleet-inventory',
  templateUrl: './fleet-inventory.component.html',
  styleUrls: ['./fleet-inventory.component.scss'],
})
export class FleetInventoryComponent implements OnInit, OnDestroy {
  public emptyComponentList = false;

  constructor(private fleetService: FleetService) {}

  private ngUnsubscribe$: Subject<boolean> = new Subject();

  public ngOnInit(): void {
    this.fleetService.gateways$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(gateways => {
        // 404 occured, handled in service
        this.emptyComponentList = false;
        if (gateways === null || gateways?.length === 0) {
          this.emptyComponentList = true;
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
