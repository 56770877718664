<div class="w-100 d-flex flex-column">
  <div class="w-100">
    <ng-container *ngIf="viewConfig?.gateways?.visible === true">
      <idm-train-gateways
        [model]="model"
        [viewConfig]="viewConfig"></idm-train-gateways>
    </ng-container>
  </div>
  <div>
    <idm-train-actions
      [viewConfig]="viewConfig.actions"
      (connectGatewaysClicked)="onConnectGatewaysClick()"
      (connectTagsClicked)="onConnectTagsClick()"
      (validateConfigurationClicked)="
        onValidateConfigurationClick()
      "></idm-train-actions>
  </div>
  <div
    class="train-wrapper full w-100 pr-5 pl-5"
    [class.half]="!viewConfig?.bogie.wheel.tag.visible"
    [class.preview]="
      !viewConfig?.actions &&
      !viewConfig?.bogie.wheel.name.visible &&
      !viewConfig?.bogie.wheel.tag.visible
    ">
    <div
      class="train-body d-flex justify-content-around"
      [ngClass]="model?.type">
      <idm-train-bogie
        *ngFor="let bogie of model?.trainStructure?.bogies"
        (tagClicked)="onTagClick($event)"
        [model]="bogie"
        [viewConfig]="viewConfig?.bogie"
        (modelChanged)="onModelChange($event)"></idm-train-bogie>
    </div>
  </div>
</div>
