import { Injectable, ErrorHandler, NgZone } from '@angular/core';

// See: https://pkief.medium.com/global-error-handling-in-angular-ea395ce174b1

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone) {}

  handleError(error: Error) {
    console.error('Error from global error handler', error);
  }
}
