<div class="d-flex mb-3">
  <div *ngIf="actions" class="actions justify-content-center">
    <button
      class="btn delete bg-transparent clear-btn p-0"
      (click)="onDeleteClick()">
      <span class="material-icons delete">cancel</span>
    </button>
  </div>
  <div class="d-flex pr-3 align-items-center">
    <svg-icon
      [ngClass]="state | lowercase | replace: ' ':'-'"
      src="assets/graphics/icon-propm-290322.svg"></svg-icon>
  </div>
  <div class="d-flex flex-column pr-3 gateway-serial justify-content-center">
    <div class="mb-1">
      Gateway Serial Number: <strong>{{ serial }}</strong>
    </div>
    <div>
      <span
        class="badge badge-pill mr-1"
        [class.badge-secondary]="state === 'NOT CONFIGURED'"
        [class.badge-primary]="state === 'CONFIGURED'"
        [class.badge-success]="state === 'ACTIVE'"
        [class.badge-danger]="state === 'ERROR'"
        [class.badge-warning]="state === 'RUNNING'"
        >{{ state | titlecase }}</span
      >
      <span class="badge badge-pill badge-secondary" *ngIf="counter"
        >{{ tags?.length ? tags.length : 0 }} Tag{{
          tags?.length > 1 ? 's' : ''
        }}</span
      >
    </div>
  </div>
</div>
