import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

/**
 * About Aynchronous Validators:
 * https://weblog.west-wind.com/posts/2019/Nov/18/Creating-Angular-Synchronous-and-Asynchronous-Validators-for-Template-Validation
 * https://www.concretepage.com/angular-2/angular-custom-async-validator-example
 * Angular Documentation:
 * https://angular.io/guide/form-validation
 */
@Injectable({
  providedIn: 'root',
})
export class TrainExistsValidator {
  constructor(private http: HttpClient) {}
  public validate(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return this.http
        .get(
          environment.api +
            '/fm/trains/groupedByFleet/byFilter?nm=' +
            control.value
        )
        .pipe(
          map((result: Array<any> | null) => {
            return result?.length === 0
              ? null
              : {
                  trainExists: 'Name already exists.',
                };
          })
        );
    };
  }
}
