<div class="d-flex flex-column w-100 h-100">
  <div *ngIf="error$ | async" class="alert alert-danger mt-2" @fade>
    <div>{{ error$ | async }}</div>
  </div>
  <p *ngIf="done === false" class="train-config-step-info">
    The last step is to check the connection of the HD-TAGs to the assigned
    gateway. If the conncetion can not be established within 2 minutes, find
    more information about a troubleshooting in the online manual or contact the
    service team via the online form.
  </p>
  <p *ngIf="done === true" class="train-config-step-info">
    Your configuration is saved successfully! You will find the vehicle now in
    the assigned fleet.
    <a (click)="gotoTrain()">Click here for the details.</a> <br />If you want
    to create a new configuration of a vehicle, click
    <a href (click)="reset(); (false)">start new configuration</a>.
  </p>
  <div class="flex-grow-1 d-flex w-100 flex-column">
    <idm-train
      [model]="model"
      class="w-100"
      [viewConfig]="trainViewConfiguration"
      (connectGatewaysClicked)="onConnectGatwaysClicked()"
      (connectTagsClicked)="onConnectTagsClicked()"
      (validateConfigurationClicked)="
        onValidateConfigurationClicked()
      "></idm-train>
  </div>
</div>
