import { Event, Router, NavigationEnd } from '@angular/router';
import { FleetConfigurationService } from './../../../services/fleet-configuration.service';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'idm-fleet-configurator-steps',
  templateUrl: './fleet-configurator-steps.component.html',
  styleUrls: ['./fleet-configurator-steps.component.scss'],
})
export class FleetConfiguratorStepsComponent implements OnDestroy {
  public steps: Array<any> = new Array<any>();
  public currentStep: string;
  public currentStepId: number;
  public done: boolean;
  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(
    public fleetConfiguratorService: FleetConfigurationService,
    private router: Router
  ) {
    this.steps = this.fleetConfiguratorService.steps;
    this.subs.push(
      this.fleetConfiguratorService.activeStep$.subscribe(id => {
        this.currentStepId = id;
      })
    );
    this.subs.push(
      this.fleetConfiguratorService.done$.subscribe(done => (this.done = done))
    );
    this.subs.push(
      this.router.events.subscribe((e: Event) => {
        if (e instanceof NavigationEnd) {
          const fragments = e.urlAfterRedirects.split('/');
          this.currentStep = fragments.pop();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
