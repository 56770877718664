import { Component, OnInit } from '@angular/core';
import { ItemsService, fadeInAndOutAnimation } from './../../../core';
import { filter, takeWhile } from 'rxjs/operators';

// Module
import { App } from '../../models/app.model';
import { AppsService } from './../../services/apps.service';
import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import idmData from './data/apps.idm.json';
import imbosData from './data/apps.imbos.json';

@Component({
  selector: 'idm-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class SelectorComponent implements OnInit {
  public apps: App[] =
    environment.portal === 'idm' ? idmData.apps : imbosData.apps;
  public targetIndex: number;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  public favorites = false;
  public listView = false;

  public authenticated$: Observable<boolean>;
  public initialized = false;

  constructor(
    private appsService: AppsService,
    private itemsService: ItemsService
  ) {}
  ngOnInit(): void {
    this.resetEventCounter();
    this.initializeDeepLinks()
      .then(() => {
        this.initializeApps().then(() => (this.initialized = true));
      })
      .catch(reason => console.log(reason));
  }

  private initializeDeepLinks(): Promise<string> {
    return new Promise((resolve, __reject) => {
      this.itemsService.vehicleId$
        .pipe(
          filter(vehicleId => vehicleId !== null),
          takeWhile(vehicleId => vehicleId !== null)
        )
        .subscribe({
          next: vehicleId => {
            this.apps.forEach(app => {
              app.target = app.target.replace('<vehicleId>', vehicleId);
              app.disabled = false;
            });
            resolve('Successfully replaced vehicleId');
          },
        });
    });
  }

  private initializeApps(): Promise<string> {
    return new Promise(resolve => {
      this.appsService.getIncidentsCounter().subscribe((counter: number) => {
        this.apps.forEach(item => {
          if (item.title === 'Incident Manager') {
            item.events = counter;
          }
        });
        resolve('EVENT COUNTER ADDED');
      });
    });
  }

  // https://stackoverflow.com/questions/54894416/angular-7-drag-n-drop-in-grid-not-able-to-move-panels-between-grid
  // https://stackblitz.com/edit/angular-dyz1eb?file=src%2Fapp%2Fapp.component.ts
  /**
   * The drag and drop functionality has been removed in Version 1.8.0
   * There was a problem with the correct positioning of the tiles while dragging. It seems
   * to be a problem with grid and list handling in Angular CDK 11
   * This should be reevaluated in the future and if necessary (eg more apps ar available)
   */

  private resetEventCounter() {
    this.apps.forEach(item => (item.events = 0));
  }

  public setListView(listView: boolean): void {
    this.listView = listView;
  }
}
