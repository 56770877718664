import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'heading' })
export class HeadingPipe implements PipeTransform {
  // corresponding to: http://snowfence.umn.edu/Components/winddirectionanddegrees.htm
  transform(value: number): number {
    // can be computed, but it's better readable that way
    const directions: Array<any> = [
      {
        value: 'N',
        min: 348.7,
        max: 11.25,
      },
      {
        value: 'NNE',
        min: 11.25,
        max: 33.75,
      },
      {
        value: 'NE',
        min: 33.75,
        max: 56.25,
      },
      {
        value: 'ENE',
        min: 56.25,
        max: 78.75,
      },
      {
        value: 'E',
        min: 78.75,
        max: 101.25,
      },
      {
        value: 'ESE',
        min: 101.25,
        max: 123.75,
      },
      {
        value: 'SE',
        min: 123.75,
        max: 146.25,
      },
      {
        value: 'SSE',
        min: 146.25,
        max: 168.75,
      },
      {
        value: 'S',
        min: 168.75,
        max: 191.25,
      },
      {
        value: 'SSW',
        min: 191.25,
        max: 213.75,
      },
      {
        value: 'SW',
        min: 213.75,
        max: 236.25,
      },
      {
        value: 'WSW',
        min: 236.25,
        max: 258.75,
      },
      {
        value: 'W',
        min: 258.75,
        max: 281.25,
      },
      {
        value: 'WNW',
        min: 281.25,
        max: 303.75,
      },
      {
        value: 'NW',
        min: 303.75,
        max: 326.25,
      },
      {
        value: 'NNW',
        min: 326.25,
        max: 348.75,
      },
    ];

    const heading = directions.find(
      direction => value >= direction.min && value < direction.max
    );
    return typeof heading !== 'undefined' ? heading.value : '';
  }
}
