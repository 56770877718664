import { DatepickerPopupComponent } from './../../../core/components/datepicker-popup/datepicker-popup.component';
import * as moment from 'moment';

import { BehaviorSubject, Subject, throwError, timer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  Train,
  fadeInAndOutAnimation,
  CancelModalComponent,
  Modal,
  Notifications,
  NotificationMessageType,
} from './../../../core';
import {
  BogieConfiguration,
  TrainConfiguration,
  TrainViewConfiguration,
  WheelConfiguration,
} from './../../../vehicles';
import {
  concatMap,
  delayWhen,
  filter,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { MaintenanceService } from './../../services/maintenance.service';
import {
  MaintenanceStateModel,
  PositionsMaintenanceModel,
} from './../../models/maintenance-state.model';
import { Store } from '@ngxs/store';
import { COMPONENT_STATES } from './../../enums/component-states.enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

class DisabledStates {
  incidents: boolean;
  clear: boolean;
}

/**
 * TODO: Refactor to reactive form and validation process
 */

@Component({
  selector: 'idm-maintenance-feedback',
  templateUrl: './maintenance-feedback.component.html',
  styleUrls: ['./maintenance-feedback.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class MaintenanceFeedbackComponent implements OnInit, OnDestroy {
  public trainViewConfiguration: TrainViewConfiguration = {
    actions: {
      connectAll: false,
      validateConfig: false,
    },
    gateways: {
      editable: false,
      visible: false,
      counter: false,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: false,
          replaceable: false,
          visible: false,
        },
        state: {
          visible: true,
        },
      },
    },
  };
  public model: TrainConfiguration;

  public vehicle: Train;
  public maintenanceState: MaintenanceStateModel;
  public disabledStates: unknown = new Object();
  public COMPONENT_STATES = COMPONENT_STATES;
  public initDate = new NgbDate(0, 0, 0);

  public componentState$: BehaviorSubject<COMPONENT_STATES> =
    new BehaviorSubject(COMPONENT_STATES.Pristine);
  public hasErrors$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public datePlausibility$: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  public disabledInspectionDatepicker$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);
  public disabledRepairDatepicker$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  private animationsDelay = 251;
  private cachedModel: TrainConfiguration;
  private delayFor = () => timer(this.animationsDelay);
  private ngUnsubscribe$ = new Subject();

  @ViewChild('cancelModal', { read: ViewContainerRef })
  cancelModal: ViewContainerRef;
  @ViewChild('repairDate') repairDate: DatepickerPopupComponent;
  @ViewChild('inspectionDate') inspectionDate: DatepickerPopupComponent;

  constructor(
    private store: Store,
    private maintenanceService: MaintenanceService
  ) {}

  ngOnInit(): void {
    this.subscribeOnComponentStates();
    this.subscribeOnVehicle();
    this.subscribeOnErrors();
    this.subscribeOnRepairDatepicker();
    this.subscribeOnInspectionDatepicker();
  }

  private subscribeOnRepairDatepicker(): void {
    this.disabledRepairDatepicker$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: state => {
          if (state === true && this.maintenanceState?.repairDate)
            this.maintenanceState.repairDate = null;
        },
      });
  }
  private subscribeOnInspectionDatepicker(): void {
    this.disabledInspectionDatepicker$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: state => {
          if (state === true && this.maintenanceState?.inspectionDate)
            this.maintenanceState.inspectionDate = null;
        },
      });
  }

  private subscribeOnVehicle(): void {
    this.maintenanceService.vehicle$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(vehicle => vehicle !== null),
        tap(() => (this.model = null)),
        delayWhen(this.delayFor),
        concatMap(vehicle => {
          return this.maintenanceService
            .loadConfiguration(vehicle.id)
            .pipe(map(model => ({ vehicle, model })));
        })
      )
      .subscribe({
        next: ({ vehicle, model }) => {
          this.vehicle = vehicle;
          this.model = model;
          this.generateDisabledStates();
          this.cachedModel = JSON.parse(JSON.stringify(model));
          this.maintenanceState.trainId = vehicle.id;
        },
        error: () => throwError('SUBSCRIPTION ERROR'),
      });
  }

  private subscribeOnErrors(): void {
    this.hasErrors$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(state => {
      if (state === false) {
        this.componentState$.next(COMPONENT_STATES.Valid);
      }
    });
  }

  private subscribeOnComponentStates(): void {
    this.componentState$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (state: COMPONENT_STATES) =>
          (this.maintenanceService.maintenanceComponentState = state)
      );
  }

  private generateDisabledStates() {
    this.disabledRepairDatepicker$.next(true);
    this.disabledInspectionDatepicker$.next(true);
    this.hasErrors$.next(true);
    this.maintenanceState = new MaintenanceStateModel();
    this.model.trainStructure.bogies.forEach(bogie => {
      bogie.slots.forEach(slot => {
        this.disabledStates[slot.name] = new DisabledStates();
        this.disabledStates[slot.name] = {
          incidents: false,
          clear: false,
          maintenance: true,
        };
      });
    });
    this.componentState$.next(COMPONENT_STATES.Pristine);
  }

  public updateState(
    bogie: string,
    slot: string,
    type: string,
    value: Event
  ): void {
    this.componentState$.next(COMPONENT_STATES.Touched);
    const bFound = this.model.trainStructure.bogies.find(b => b.name === bogie);
    const sFound = bFound.slots.find(s => s.name === slot);
    const disabledState = this.disabledStates[sFound.name];
    sFound[type] = (<HTMLInputElement>value.target).checked;
    if (disabledState) {
      disabledState.maintenance = !(
        sFound['shelling'] ||
        sFound['flatspot'] ||
        sFound['noIncident']
      );
      switch (type) {
        case 'shelling':
        case 'flatspot':
          if (sFound['shelling'] === true || sFound['flatspot'] === true) {
            sFound['noIncident'] = false;
            disabledState.clear = true;
          }
          if (!sFound['shelling'] && !sFound['flatspot']) {
            disabledState.clear = false;
          }
          break;
        case 'noIncident':
          if (sFound['noIncident'] === true) {
            sFound['shelling'] = false;
            sFound['flatspot'] = false;
            disabledState.incidents = true;
          }
          if (!sFound['noIncident']) {
            disabledState.incidents = false;
          }
          break;
      }
    }

    this.validate();
  }

  public validate(): void {
    // 1) If the corresponding check has been activated
    let validInspection = this.validateInspection();
    let validMaintenance = this.validateMaintenance();
    // 2) Checks date plausibility if both states and datepickers are enabled
    const validDatePlausibility =
      validInspection && validMaintenance
        ? this.validateDatePlausibility()
        : true;

    let valid = false;
    if (validInspection || validMaintenance) {
      if (validInspection) {
        this.disabledInspectionDatepicker$.next(false);
        validInspection = this.validateDate(
          this.inspectionDate.getSelectedDate()
        );
      } else {
        this.disabledInspectionDatepicker$.next(true);
        validInspection = true;
      }

      if (validMaintenance) {
        this.disabledRepairDatepicker$.next(false);
        validMaintenance = this.validateDate(this.repairDate.getSelectedDate());
      } else {
        this.disabledRepairDatepicker$.next(true);
        validMaintenance = true;
      }
    } else {
      this.disabledRepairDatepicker$.next(true);
      this.disabledInspectionDatepicker$.next(true);
    }

    valid =
      validInspection === true &&
      validMaintenance === true &&
      validDatePlausibility;

    this.datePlausibility$.next(validDatePlausibility);
    this.hasErrors$.next(!valid);
  }

  validateInspection(): boolean {
    let found = false;
    this.model.trainStructure.bogies.forEach(bogie => {
      bogie.slots.forEach(slot => {
        if (
          slot.flatspot === true ||
          slot.noIncident === true ||
          slot.shelling === true
        ) {
          found = true;
        }
      });
    });
    return found;
  }
  validateMaintenance(): boolean {
    let found = false;
    this.model.trainStructure.bogies.forEach(bogie => {
      bogie.slots.forEach(slot => {
        if (slot.maintenanceDone === true) {
          found = true;
        }
      });
    });
    return found;
  }

  validateDate(date: NgbDate): boolean {
    return date !== null && typeof date !== 'undefined' && date?.year !== null
      ? true
      : false;
  }

  validateDatePlausibility(cachedRepairDate?: string): boolean {
    return moment
      .utc(cachedRepairDate || this.maintenanceState.repairDate)
      .isSameOrAfter(moment.utc(this.maintenanceState.inspectionDate));
  }

  updateInspectionDate(date: string): void {
    this.datePlausibility$.next(true);
    if (date) this.componentState$.next(COMPONENT_STATES.Touched);
    if (date !== null) {
      this.maintenanceState.inspectionDate = date;
      this.validateDatePlausibility(this.maintenanceState.repairDate);
    }
    this.validate();
  }

  updateRepairDate(date: string): void {
    if (date) this.componentState$.next(COMPONENT_STATES.Touched);
    if (date !== null) {
      this.maintenanceState.repairDate = date;
    }
    this.validate();
  }

  public save(): void {
    const oBogies: BogieConfiguration[] = [...this.model.trainStructure.bogies];
    const positions: PositionsMaintenanceModel[] =
      new Array<PositionsMaintenanceModel>();
    oBogies.forEach(bogie => {
      bogie?.slots?.forEach((slot: WheelConfiguration) => {
        positions.push({
          tagId: slot.tag?.bleId,
          wheelName: slot.name,
          shelling: slot?.shelling || false,
          flatspot: slot?.flatspot || false,
          noIncident: slot?.noIncident || false,
          maintenanceDone: slot?.maintenanceDone || false,
        });
      });
    });
    this.maintenanceState.positions = positions;

    this.maintenanceService
      .saveMaintenanceReport(this.maintenanceState)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: () => {
          this.componentState$.next(COMPONENT_STATES.Saved);
          this.store.dispatch(
            new Notifications.AddNotification({
              type: NotificationMessageType.success,
              msg: `Your incident localization feedback has been successfully saved to ${this.vehicle.name}`,
            })
          );
        },
        error: (error: HttpErrorResponse) => {
          this.componentState$.next(COMPONENT_STATES.Dirty);
          this.store.dispatch(
            new Notifications.AddNotification({
              type: NotificationMessageType.error,
              msg: `An error has occured ${error.error}. Please contact our support if the error consists.`,
            })
          );
        },
      });
  }

  public createCancelModal(ref: ViewContainerRef): Promise<string> {
    return new Promise(resolve => {
      ref.clear();
      const componentRef = ref.createComponent(CancelModalComponent);
      componentRef.instance.id = 'maintenance-feedback-cancel-modal';
      componentRef.instance.headline = 'Cancel Incident Localization';
      componentRef.instance.description =
        'Do you really want to cancel the current localization of incidents on the vehicle?<br />Please be aware that all data will be lost.';
      componentRef.instance.canceled
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((state: boolean) => {
          if (state === true) {
            this.store.dispatch(new Modal.Hide());
          }
        });
      componentRef.instance.confirmed
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((state: boolean) => {
          if (state === true) {
            this.model = JSON.parse(JSON.stringify(this.cachedModel));
            this.generateDisabledStates();
            this.store.dispatch(new Modal.Hide());
          }
        });
      resolve('done');
    });
  }

  public onCancelClick(): void {
    this.createCancelModal(this.cancelModal).then(() => {
      this.store.dispatch(new Modal.Show('maintenance-feedback-cancel-modal'));
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
