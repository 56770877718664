import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
  RouterModule,
  Routes,
} from '@angular/router';
import { Injectable, NgModule } from '@angular/core';

import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { environment } from '../environments/environment';

@Injectable()
export class CustomReusingStrategy implements RouteReuseStrategy {
  private cache: { [key: string]: DetachedRouteHandle } = {};

  /**
   * Set reuse to false if you want the outlet to be detached on eg. logout or
   * other route changes
   *
   * Example in route:
   * data: { reuse: false }
   */

  shouldDetach(__route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    if (handler && route.routeConfig.data && route.routeConfig.data.reuse) {
      this.cache[this.getUrl(route)] = handler;
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.cache[this.getUrl(route)];
  }
  retrieve(__route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return null;
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    if (
      future.routeConfig &&
      future.routeConfig.data &&
      future.routeConfig.data.reuse !== undefined
    ) {
      return future.routeConfig.data.reuse;
    }
    return future.routeConfig === current.routeConfig;
  }
  getUrl(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig) {
      return route.routeConfig.path;
    }
  }
}
/**
 * Path handling is mainly handled in the submodules
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: '/authentication/login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
];
// Lazy loads only if devMode is set to true, it is not the same as development build!
// Currently deactivated, was coupled to environement.devMode === true
routes.push({
  path: 'admin',
  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
});
// IMPORTANT: Has to be the last entry!
routes.push({
  path: '**',
  redirectTo: '/404',
});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: RouteReuseStrategy, useClass: CustomReusingStrategy }],
})
export class AppRoutingModule {}
