<p class="train-config-step-info">
  Please enter a name for the vehicle you are going to create. Choose a train
  type and assign the vehicle to a fleet.<br />
  If you can not find the train type or fleet you want to specify please fill
  out the contact form and contact the IDM Team.
</p>
<div class="d-flex flex-row">
  <div class="train-form">
    <form [formGroup]="editTrainForm">
      <div class="form-group row w-100">
        <label for="name" class="col-sm-3 col-form-label">Name</label>
        <div class="col-sm-9">
          <input
            type="train-name"
            class="form-control"
            id="name"
            placeholder="Name of the train"
            [class.error]="
              trainName.invalid && (trainName.dirty || trainName.touched)
            "
            formControlName="trainName"
            required />
          <div
            *ngIf="trainName.invalid && (trainName.dirty || trainName.touched)"
            class="alert alert-danger mt-3 mb-0">
            <div *ngIf="trainName.errors?.['required']">
              Train name is required.
            </div>
            <div *ngIf="trainName.errors?.['minlength']">
              Train name must be at least {{ trainNameMinLength }} characters
              long.
            </div>
            <div *ngIf="trainName.errors?.['maxlength']">
              Train name exceeds maximum length of
              {{ trainNameMaxLength }} characters.
            </div>
            <div *ngIf="trainName.errors?.['trainExists']">
              Train already exists, please choose another name.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row w-100">
        <label for="train-type" class="col-sm-3 col-form-label"
          >Train type</label
        >
        <div class="col-sm-9">
          <select
            class="form-control custom-select"
            id="train-type"
            formControlName="trainType"
            required>
            <option value="" disabled hidden>
              Please select the train type
            </option>
            <option
              *ngFor="let trainTypeEl of trainTypes"
              [value]="trainTypeEl.name">
              {{ trainTypeEl.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row w-100">
        <label for="train-fleet" class="col-sm-3 col-form-label">Fleet</label>
        <div class="col-sm-9">
          <select
            class="form-control custom-select"
            id="fleet"
            formControlName="fleet"
            required>
            <option value="" disabled hidden>Please select the fleet</option>
            <option *ngFor="let fleetEl of fleets" [value]="fleetEl?.id">
              {{ fleetEl?.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div
    class="train-preview mb-3 d-flex align-items-center justify-content-center">
    <div
      class="w-100"
      @fade
      *ngIf="model?.trainStructure; then type; else selectType"></div>
    <ng-template #type>
      <idm-train
        class="w-100 preview"
        [model]="model"
        [viewConfig]="trainViewConfiguration"></idm-train>
    </ng-template>
    <ng-template #selectType>
      <div class="preview-placeholder">
        Please select the train type for a preview
      </div>
    </ng-template>
  </div>
</div>
