import {
  trigger,
  transition,
  animate,
  style,
  query,
  stagger,
} from '@angular/animations';

/**
 * Use it in a list like:
 * [@listAnimation]="devices.length"
 * Start with the ngfor after that element
 */

export const listFlyInAndOutAnimation = trigger('listAnimation', [
  transition(':increment', [
    query(
      ':enter',
      [
        style({ transform: 'scale(0.5)', opacity: 0 }), // initial
        stagger(
          '30ms',
          animate(
            '750ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
            style({ transform: 'scale(1)', opacity: 1 })
          )
        ),
      ],
      { optional: true }
    ),
  ]),
  transition(':decrement', [
    query(
      ':leave',
      [
        style({ transform: 'scale(1)', opacity: 1 }), // initial
        animate(
          '750ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(0.5)', opacity: 0 })
        ),
      ],
      { optional: true }
    ),
  ]),
]);
