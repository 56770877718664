import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { Incident, Train, VehicleStateGetResponseBody } from './../../core';
import { Injectable, OnDestroy } from '@angular/core';
import {
  distinctUntilChanged,
  first,
  map,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FleetService implements OnDestroy {
  private api = environment.api + '/fm/trains';
  private devicesApi = environment.api + '/fm/devices';
  private telemetryApi = environment.telemetryApi;

  public interval = 3000; // ms
  public back: string = null; // backlink for cancelation

  _vehicle$: BehaviorSubject<Train> = new BehaviorSubject<Train>(null);
  _details$: BehaviorSubject<Incident> = new BehaviorSubject<Incident>(null);
  _gateways$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _components$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _telemetry$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _tagSignals$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // Used to unsubsribe
  public ngUnsubscribe$: Subject<boolean> = new Subject();
  private stopPolling$: Subject<boolean> = new Subject();

  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(
    private http$: HttpClient,
    private router: Router,
    private location: Location
  ) {
    this.resetAll();
    this.location.onUrlChange(() => this.stopPolling$.next(true));
  }

  public loadDetailsByVehicle(vehicle: Train): void {
    this.vehicle$ = vehicle;
    if (vehicle) {
      // TOOD: It would also be possible to subscribe on the vehicle$ BehaviorSubject (it provides the value direct after subscription, don't forget!)
      this.loadTrainDetails(vehicle.id);
      this.loadGatewaysByVehicle(vehicle.id);
    }
  }

  public getTrainDetailsById(id: string): Observable<any> {
    return this.http$.get(this.api + '/infos/byTrain?id=' + id).pipe(first());
  }

  public getTrainGatewaysById(id: string): Observable<any> {
    return this.http$.get(this.devicesApi + '/byTrain?id=' + id).pipe(first());
  }

  public getTrainComponentsByGatewaySerial(sn: string): Observable<any> {
    return this.http$
      .get(this.devicesApi + '/withTags/bySerialNo?sn=' + sn)
      .pipe(first());
  }

  public getGatewayTelemetryDataBySerial(sn: string): Observable<any> {
    return timer(1, this.interval).pipe(
      takeUntil(this.stopPolling$),
      distinctUntilChanged(),
      switchMap(() =>
        this.http$.get(this.telemetryApi + '/equipment/complete?sn=' + sn)
      ),
      map((data: any) => data.ccmVehicleTelemetry),
      share()
    );
  }

  public getGatewaySignalQualityBySerial(sn: string): Observable<any> {
    return this.http$
      .get(this.telemetryApi + '/equipment/complete?sn=' + sn)
      .pipe(
        first(),
        map((data: any): any => {
          return new Object({
            updatedAt: data.ccmVehicleTelemetry.updatedAt,
            quality: data.signalStrength.quality,
            strength: data.signalStrength.interface,
          });
        })
      );
  }

  public getTagLastSignalByGatewaySerial(sn: string): Observable<any> {
    return this.http$
      .get(this.telemetryApi + '/equipment/complete?sn=' + sn)
      .pipe(
        first(),
        map((data: any) => data.hdtagsLastSignal)
      );
  }

  public loadTrainDetails(id: string): void {
    this.getTrainDetailsById(id)
      .pipe(
        tap(() => (this.details$ = null)),
        first()
      )
      .subscribe(details => {
        this.details$ = details;
      });
  }

  public loadGatewaysByVehicle(id: string) {
    this.getTrainGatewaysById(id)
      .pipe(
        tap(() => {
          this.resetGateways();
          this.resetComponents();
        }),
        first()
      )
      .subscribe(
        gateways => {
          this.gateways$ = gateways;
        },
        __error => {
          this.gateways$ = null;
        }
      );
  }

  public loadTrainComponentsByGatewaySerial(sn: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.resetComponents();
      this.getTrainComponentsByGatewaySerial(sn)
        .pipe(first())
        .subscribe({
          next: components => {
            this.components$ = components;
            resolve('COMPONENTS LOADED');
          },
          error: __error => reject('COMPONENTS ERROR'),
        });
    });
  }

  /**
   * Currently just takes the first vcu in the list and loads the telemetry data
   *
   * About polling: https://blog.angulartraining.com/how-to-do-polling-with-rxjs-and-angular-50d635574965
   *
   * @param sn serialnumber
   */
  public loadTelemetryDataByGatewaySerial(sn: string): void {
    this.resetCoordinates();
    // Don't kill it directly with first!
    this.subs.push(
      this.getGatewayTelemetryDataBySerial(sn).subscribe(
        (data: VehicleStateGetResponseBody) => {
          this.telemetry$ = data;
        }
      )
    );
  }

  public startConfiguration(back?: string) {
    this.back = back || this.back;
    this.router.navigate([
      '/fleets/manager/configuration/configurator/edit-train',
    ]);
  }

  // Vehicles
  get vehicle$(): any {
    return this._vehicle$;
  }
  set vehicle$(value: any) {
    if (this.vehicle$.getValue()?.id !== value?.id) this._vehicle$.next(value);
  }
  // Details
  get details$(): any {
    return this._details$.asObservable();
  }
  set details$(value: any) {
    if (value?.trainDetails && value !== this._details$.getValue())
      this._details$.next(value);
  }
  // Gateways
  get gateways$(): any {
    return this._gateways$.asObservable();
  }
  set gateways$(value: any) {
    // Needed for transfering 404 errors
    this._gateways$.next(value);
  }
  // Components
  get components$(): any {
    return this._components$.asObservable();
  }
  set components$(value: any) {
    if (value) {
      // Shallow comparison of sn
      // A direct comparison would also work, this is kept as a hint for future attribute upgrades
      let past = this._components$.getValue();
      past = {
        sn: past?.device?.sn,
      };
      const future = {
        sn: value?.device?.sn,
      };
      // this only works if the structure is equal
      if (JSON.stringify(future) !== JSON.stringify(past))
        this._components$.next(value);
    } else {
      this._components$.next(null);
    }
  }
  // Telemetry
  get telemetry$(): any {
    return this._telemetry$.asObservable();
  }
  set telemetry$(value: any) {
    if (value !== this._telemetry$.getValue()) this._telemetry$.next(value);
  }
  // TagSignals
  get tagSignals$(): any {
    return this._telemetry$.asObservable();
  }
  set tagSignals$(value: any) {
    if (value !== this._telemetry$.getValue()) this._telemetry$.next(value);
  }

  public resetAll(): Promise<any> {
    this.ngUnsubsribe();
    return new Promise(resolve => {
      this.resetDetails();
      this.resetGateways();
      this.resetComponents();
      this.resetCoordinates();
      resolve('FLEET SERVICE RESET');
    });
  }

  public resetVehicle(): void {
    this.vehicle$.next(null);
  }
  private resetDetails(): void {
    this.details$ = null;
  }
  private resetGateways(): void {
    this.gateways$ = null;
  }
  private resetComponents(): void {
    this.components$ = null;
  }
  private resetCoordinates(): void {
    this.stopPolling$.next();
    this.telemetry$ = null;
  }
  private ngUnsubsribe(): void {
    this.subs.forEach(sub$ => sub$.unsubscribe());
  }
  ngOnDestroy(): void {
    this.resetAll();
  }
}
