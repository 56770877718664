<footer *ngIf="(authenticated$ | async) === false">
  <div class="text-black-50 w-100 text-center fixed-bottom pl-5 pr-5 pb-3 pt-3">
    <a routerLink="/">Home</a> &middot;
    <a routerLink="/imprint">Imprint</a> &middot;
    <a routerLink="/data-protection">Data Protection</a> &middot;
    <a href="/3rdpartylicenses.txt">Source Code Notice</a><br />
    {{ env.title }} {{ version }} &copy; ZF Friedrichshafen AG
    {{ today | date: 'yyyy':'':'de-DE' }}
  </div>
</footer>
