import { FileContext } from './../../models/file-context.model';
import { FileManagerService } from '@idm/core/services/file-manager.service';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FileListGetResponseBody } from '@idm/core/models/file-list-get-response-body.model';
import * as moment from 'moment';
import { fadeInAndOutAnimation } from '@idm/core/animations/fade-in-and-out.animation';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'idm-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FileManagerComponent implements OnChanges, OnDestroy {
  @Input() context: FileContext;

  private subs$: Array<any> = new Array<any>();

  constructor(public fileManagerService: FileManagerService) {}

  public fileList = new Array<FileListGetResponseBody>();

  ngOnChanges(): void {
    this.clearSubscriptions();
    this.loadFileList();
    this.updateFileList();
  }

  private loadFileList() {
    const sub = this.fileManagerService
      .getFileList(this.context)
      .subscribe((files: FileListGetResponseBody[]) => {
        this.fileList = files;
        this.updateFileCounter();
      });
    this.subs$.push(sub);
  }

  private moveFiles(file: File) {
    // Remmove double entry and set it on top afterwards
    this.fileList.forEach((f: any, index: number) => {
      if (f.fileName === file.name) this.fileList.splice(index, 1);
    });
    this.fileList.unshift({
      fileName: file.name,
      lastModified: moment.utc(file.lastModified).toISOString(),
      sizeInBytes: file.size,
    });
  }

  private updateFileList() {
    // Filter for double files

    const sub = this.fileManagerService.fileObserver$.subscribe(
      (files: any) => {
        if (typeof files[Symbol.iterator] === 'function') {
          [...files].forEach(file => {
            this.moveFiles(file);
          });
        } else {
          this.moveFiles(files);
        }
        this.updateFileCounter();
      }
    );
    this.subs$.push(sub);
  }

  public downloadFile(fileName: string): void {
    // Access-Control-Expose-Headers MUST include Content-Disposition
    this.fileManagerService
      .downloadFile(this.context, fileName)
      .subscribe((response: HttpResponse<Blob>) => {
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'blob' })
        );
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  public deleteFile(fileName: string): void {
    // Currently moved to the finally statement as a workaround until the CORS response problem has been solved
    // Move back to base handling, also refer to the file-service
    const sub = this.fileManagerService
      .deleteFile(this.context, fileName)
      .subscribe(
        () => this.handleDelete(fileName),
        __error => this.handleDelete(fileName)
      );
    this.subs$.push(sub);
  }

  private handleDelete(fileName: string): void {
    this.fileList = this.fileList.filter(el => el.fileName !== fileName);
    this.updateFileCounter();
  }

  private updateFileCounter() {
    this.fileManagerService.fileCounter$.next(this.fileList.length);
  }

  private clearSubscriptions(): void {
    if (this.subs$.length > 0) {
      this.subs$.forEach(sub => sub.unsubscribe());
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
