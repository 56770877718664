import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// Feature Modules
import { AppsModule } from './apps/apps.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
// Core & Shared Modules
import { CoreModule } from './core/core.module';
import { DevicesModule } from './devices/devices.module';
import { FleetModule } from './fleet/fleet.module';
import { IamModule } from './iam/iam.module';
import { IncidentsModule } from './incidents/incidents.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../environments/environment';

function initializeApp(): Promise<string> {
  return new Promise((resolve, reject) => {
    const el = () => document.getElementById('splash-screen');
    if (el()) {
      el().classList.add('removed');
      el().addEventListener('transitionend', () => {
        el().remove();
        if (!el()) {
          resolve('Remove Splash Screen');
        } else {
          el().remove();
          reject('Can not remove Splash Screen');
        }
      });
    } else {
      reject('Can not remove Splash Screen');
      alert('An error has occured, please reload the page');
    }
  });
}
const cookieConfig: NgcCookieConsentConfig = {
  revokable: false,
  content: {
    href: '/data-protection',
    target: '_self',
    policy: 'Data Protection',
    dismiss: 'OK',
    message: `
        <p>We only use strictly necessary and functional cookies to allow our site to work properly and to ensure you get the best experience on our website. To achieve that, we store some of your personal information (like your email address and name) in the local storage of your browser, this allows us to recognize you and give you a more convenient and performant user experience. We only use strictly necessary cookies. If you do not agree close this window and contact your sales person you find in the <a href="/imprint">imprint</a>.</p>
        <p>Please refer to our General Data Protection Notice if you want more information about our commitment to the GDPR.</p>        
        `,
  },
  cookie: {
    domain: environment.domain,
  },
  palette: {
    popup: {
      background: '#00223e',
    },
    button: {
      background: '#00abe7',
    },
  },
  theme: 'edgeless',
  type: 'info',
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    CoreModule.forRoot(),
    IamModule,
    AppsModule,
    DevicesModule,
    IncidentsModule,
    FleetModule,
    MaintenanceModule,
    // IMPORTANT: The AppRoutingModule has to be called after all the submodules routing paraemters
    // because of the wildcard and error page handling!
    AppRoutingModule,
    // IMPORTANT: The StoreModule forRoot is needed in the apps main module. Submodules provide a separate access via
    // the forFeature implementation and export. (this is the same for NgXS)
    // Refer: https://medium.com/@AnkurRatra/lazy-loading-of-modules-with-ngrx-store-4th-version-angular-2-23c93295d4e8
    // Refer: https://offering.solutions/blog/articles/2018/02/10/separating-state-into-angular-modules-with-ngrx/
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'IDM Store',
      disabled: environment.production,
    }),
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'Component is loading...',
    }),
    /*
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
    */
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// when using async app initialization, be sure to set the `async` boot option in `main.ts` like this:
