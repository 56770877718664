import { Component, EventEmitter, Input, Output } from '@angular/core';

import { GATEWAY_STATE } from './../../../../enums/gateway-state.enum';

@Component({
  selector: 'idm-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss'],
})
export class GatewayComponent {
  @Input() state: GATEWAY_STATE;
  @Input() serial: string;
  @Input() actions = false;
  @Input() counter = false;
  @Input() tags: Array<string> = new Array<string>();

  @Output() deleteClicked: EventEmitter<string> = new EventEmitter<string>();

  public onDeleteClick() {
    this.deleteClicked.emit(this.serial);
  }
}
