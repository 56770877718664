import { Component, Input } from '@angular/core';

@Component({
  selector: 'idm-fleet-tag',
  templateUrl: './fleet-tag.component.html',
  styleUrls: ['./fleet-tag.component.scss'],
})
export class FleetTagComponent {
  @Input() state: any;
  @Input() labelPosition: any;
}
