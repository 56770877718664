import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';

/**
 * Why using a component instead of an abstract class definition:
 * see: https://ozak.medium.com/stop-repeating-yourself-in-angular-how-to-create-abstract-components-9726d43c99ab
 * With that declaration and registration in the module, access and inheritance of DI is working
 * Changed to Directive, maybe this has some problems on rendering and lifecycle management
 */
@Directive()
export class AbstractVehicleViewComponent<T = any> {
  @Input() model: T;
  @Input() viewConfig: T;

  @Output() modelChanged: EventEmitter<T> = new EventEmitter<T>();
  @Output() tagClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() connectGatewaysClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() connectTagsClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() validateConfigurationClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  protected cdRef: ChangeDetectorRef;

  constructor(public injector: Injector) {
    this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }

  public onModelChange(model: T) {
    this.cdRef.detectChanges();
    this.modelChanged.emit(this.model || model);
  }

  public onTagClick(value: any): void {
    this.tagClicked.emit(value);
  }

  public onConnectGatewaysClick(): void {
    this.connectGatewaysClicked.emit(true);
  }

  public onConnectTagsClick(): void {
    this.connectTagsClicked.emit(true);
  }

  public onValidateConfigurationClick(): void {
    this.validateConfigurationClicked.emit(true);
  }
}
