import { AuthenticationService } from '@idm/iam/services/authentication.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Authentication } from '../store/authentication/authenticaton.actions';
import { AuthenticationState } from '../store/authentication/authentication.state';

import moment from 'moment';

// See: https://medium.com/echohub/angular-role-based-routing-access-with-angular-guard-dbecaf6cd685
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private store$: Store,
    private authenticationService: AuthenticationService
  ) {}

  private isAuthenticated(): boolean {
    const authenticated = this.store$.selectSnapshot(
      AuthenticationState.isValid
    )(moment());
    // Refreshes on Reload of the page
    if (authenticated) {
      if (!this.authenticationService.isRunning) {
        this.store$.dispatch(new Authentication.Refresh());
      }
    }
    return authenticated;
  }

  private isAuthorized(route: ActivatedRouteSnapshot): boolean {
    let authorized = false;
    const roles = this.store$.selectSnapshot(AuthenticationState.roles);
    // We only need to find one matching role from the token to ensure the user has the
    // corresponding rights to access this route
    route.data.role.some(authorizedRole => {
      roles.forEach(role => {
        if (role === authorizedRole) {
          authorized = true;
        }
      });
    });
    return authorized;
  }

  canActivate(next: ActivatedRouteSnapshot): any {
    if (!this.isAuthenticated() || !this.isAuthorized(next)) {
      this.store$.dispatch(Authentication.Logout);
      return false;
    }
    return true;
  }
}
