import { Component, Input } from '@angular/core';
import { Modal, fadeInAndOutAnimation } from './../../../core';
import { Select, Store } from '@ngxs/store';

import { Observable } from 'rxjs';

// Refer: https://blog.bitsrc.io/creating-modals-in-angular-cb32b126a88e

@Component({
  selector: 'idm-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class BasicModalComponent {
  @Select((state: any) => state.modal) modal$: Observable<any>;
  @Input() id: string;

  constructor(private store: Store) {}

  hideModal(): void {
    this.store.dispatch(new Modal.Hide());
  }
}
