import * as moment from 'moment';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { CONFIRMATION_STATES } from './../../../core';

@Component({
  selector: 'idm-incident-feedback',
  templateUrl: './incident-feedback.component.html',
  styleUrls: ['./incident-feedback.component.scss'],
})
export class IncidentFeedbackComponent implements OnChanges {
  @Output()
  updateStateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() initData: any;
  @Input() rejectDisabled: boolean;

  private today: string = moment.utc(Date.now()).toISOString();

  public selectedState: CONFIRMATION_STATES;
  public selectedDate: string;
  public datepickerDisabled = true;
  public states = CONFIRMATION_STATES;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    if (this.initData) {
      this.selectedState = this.initData.state;
      this.updateDate(this.initData.inspectionDate);
    }
  }

  updateDate(date: string): void {
    const setDate = date !== '1899-11-30T00:00:00.000Z' ? date : this.today;
    if (setDate !== this.selectedDate) {
      this.updateState(this.selectedState, setDate);
    }
  }

  updateState(state: CONFIRMATION_STATES, date?: string): void {
    this.cdr.markForCheck();
    this.selectedState = state || this.selectedState;
    this.datepickerDisabled = this.selectedState === CONFIRMATION_STATES.OPEN;
    if (typeof date !== 'undefined') {
      this.selectedDate = date;
    }
    // Dont update on initial data load
    if (!this.initData && typeof date !== 'undefined') {
      this.updateStateEvent.emit({
        type: 'confirmation',
        state: this.selectedState,
        date:
          this.selectedState !== CONFIRMATION_STATES.OPEN
            ? this.selectedDate
            : null,
      });
    } else {
      this.initData = null;
    }
    this.cdr.detectChanges();
  }
}
