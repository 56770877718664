<div class="container utilities">
  <div class="d-flex align-middle">
    <div class="flex-grow-1 selections">
      <span class="app-counter">APPS ({{ apps.length }})</span>
      <a [class.active]="!favorites" class="badge badge-primary">All</a>
    </div>
  </div>
</div>

<div class="container apps-selector" [class.list]="listView" cdkDropListGroup>
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-12 empty-element"></div>
  </div>
  <div class="row">
    <ng-container *ngFor="let app of apps">
      <div
        class="col-xl-4 col-lg-6 col-12 {{ app.title }}"
        [class.d-none]="favorites && !app.favorite">
        <div
          class="app-tile"
          [class.list-tile]="listView"
          [class.disabled]="app.disabled === true">
          <div class="text-right app-tile-header" [routerLink]="app.target">
            <span class="material-icons more">more_horiz</span>
          </div>
          <div
            class="app-tile-body cdk-drag-disabled"
            [routerLink]="app.target">
            <span
              *ngIf="app.events > 0"
              class="badge badge-pill badge-danger"
              >{{ app.events }}</span
            >
            <h3><span class="zf-icon-{{ app.icon }}"></span>{{ app.title }}</h3>
            <p [class.d-none]="listView">{{ app.description }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
