import { Injectable } from '@angular/core';

/**
 * Requirements:
 *
 * Pushable Notification to the user frontend, always shows the last one beneath the header and reacts corresponding
 * to the type.
 * It slightly flies in and flies out (if no action is neccesary) after the computed duration (100ms / word, min 3000ms)
 *
 * Links:
 * - https://uxdesign.cc/toasts-or-snack-bars-design-organic-system-notifications-1236f2883023
 */

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private defaultDuration = 3000;

  public calcDuration(msg: string): number {
    const duration = msg.split(' ').length * 100;
    return duration < this.defaultDuration ? this.defaultDuration : duration;
  }
}
