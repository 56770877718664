import {
  trigger,
  transition,
  animate,
  style,
  query,
  animateChild,
  group,
} from '@angular/animations';

const resetRoute = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: '48px',
        width: '100%',
      }),
    ],
    { optional: true }
  ),
];

export const slideInRouteAnimation = trigger('slideInRouteAnimation', [
  transition('* <=> *', [
    ...resetRoute,
    query(':enter', [style({ left: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('300ms ease-out', style({ left: '100%' }))], {
        optional: true,
      }),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
        optional: true,
      }),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
]);
