import { Actions, ofActionDispatched } from '@ngxs/store';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, fromEvent, timer } from 'rxjs';
import {
  debounceTime,
  delay,
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';

import { FleetsGrouped } from './../../models/fleets-grouped.model';
import { Items } from './../../../core';
import { ItemsService } from './../../../core/services/items.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Train } from './../../../core/models/train.model';
import { listFadeInAnimation } from './../../../core/animations/list-fade-in.animation';

class ListPosition {
  groupIndex: number;
  itemIndex: number;
}

@Component({
  selector: 'idm-sidebar-item-selector',
  templateUrl: './sidebar-item-selector.component.html',
  styleUrls: ['./sidebar-item-selector.component.scss'],
  animations: [listFadeInAnimation],
})
export class SidebarItemSelectorComponent implements OnInit, OnDestroy {
  public visible = true;
  public hidden = false;
  public items: Array<FleetsGrouped> = new Array<FleetsGrouped>();
  public unfilteredItems: Array<FleetsGrouped> = new Array<FleetsGrouped>();
  public selectedItemIndex = -1;
  public selectedGroupIndex = 0;
  public selectedGroupName = '';
  public groupVisible: unknown = new Object();
  public initialized = false;
  public stateFilter = false;

  // https://samiprogramming.medium.com/dont-prefix-angular-output-properties-with-on-e5a638517613
  @Output() trainSelect = new EventEmitter<Train>();
  // emits the backlink and starts the configuration process
  @Output() addClick = new EventEmitter<string>();

  @Input() prefilter = 'all';
  @Input() scope: string;
  @Input() tab: string;
  @Input() bottomToolbar: boolean;
  @Input() deepLinkable = false;
  @Input() deepLink = null;
  // Show all (true) or only trains with incidents (false)
  @Input() defaultState = true;

  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay);
  private ngUnsubscribe$ = new Subject();
  private lastSelectedTrain: Train = null;

  @ViewChild('searchField', { static: true })
  searchField: ElementRef;
  @ViewChild('searchForm', { static: true })
  searchForm: ElementRef;

  constructor(
    private itemsService: ItemsService,
    private router: Router,
    private actions$: Actions,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (!this.deepLink) {
      this.deepLink = [this.scope, 'manager', this.tab, 'vehicle'];
    }
    this.actions$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        delay(1),
        ofActionDispatched(Items.Refresh)
      )
      .subscribe(() => {
        if (this.selectedItemIndex === -1) {
          this.selectedGroupIndex = 0;
          this.selectedItemIndex = 0;
        }
      });
    this.setIniitalStates();
    this.searchListener();
    this.loadItems();
  }

  public loadItems(): Promise<any> {
    return new Promise((resolve, __reject) => {
      this.itemsService
        .getTrainsGroupedByFleet()
        .pipe(takeUntil(this.ngUnsubscribe$), delayWhen(this.delayFor))
        .subscribe((items: Array<FleetsGrouped>) => {
          let listPosition: ListPosition = {
            groupIndex: 0,
            itemIndex: 0,
          };
          this.items = this.unfilteredItems = items;
          // Use default state filter
          if (this.defaultState === false) {
            this.toggleStateFilter();
          }
          // It is deeplinkable
          if (this.deepLinkable) {
            // search for vehicle name param
            // and if present: search and set the corresponding positions
            const params = this.getAllParams();
            const id = params['id'];
            listPosition = this.findListPositionById(id);
          }
          this.loadDetails(
            listPosition.groupIndex,
            listPosition.itemIndex === -1 ? 0 : listPosition.itemIndex
          ).then(() => {
            resolve(true);
          });
        });
    });
  }

  public findListPositionById(id: string): ListPosition {
    let groupIndex = 0;
    let itemIndex = 0;
    let foundIndex = -1;
    this.items.some((group, index) => {
      if (group.trains.length > 0) {
        foundIndex = group.trains.findIndex(item => {
          return item.id === id;
        });
        if (foundIndex > -1) {
          groupIndex = index;
          itemIndex = foundIndex;
        }
      }
    });
    return {
      groupIndex: groupIndex,
      itemIndex: itemIndex,
    };
  }

  private setIniitalStates(): void {
    this.hidden = this.router.url.indexOf('configurator') > -1 ? true : false;
    this.visible = !this.hidden;
  }

  public toggleVisibility(): void {
    this.visible = !this.visible;
  }

  public selectItem(groupIndex: number, itemIndex: number) {
    this.selectedItemIndex = itemIndex;
    this.selectedGroupIndex = groupIndex;
  }

  public toggleGroupVisibility(group: string): void {
    if (this.groupVisible[group] === undefined) {
      this.groupVisible[group] = false;
    } else {
      this.groupVisible[group] = !this.groupVisible[group];
    }
  }

  public resetSelection(id: string): void {
    const listPosition: ListPosition = this.findListPositionById(id);
    this.loadDetails(listPosition.groupIndex, listPosition.itemIndex);
  }

  public loadDetails(groupIndex: number, itemIndex: number): Promise<any> {
    return new Promise((resolve, __reject) => {
      this.selectItem(groupIndex, itemIndex);
      if (this.items.length > 0) {
        const group = this.items[this.selectedGroupIndex].fleet;
        const train =
          this.items[this.selectedGroupIndex].trains[this.selectedItemIndex];
        if (this.lastSelectedTrain?.id !== train?.id) {
          this.lastSelectedTrain = train;
          train['fleet'] = group;
          // only if is a new selection
          // Already a deep link, don't navigate
          if (this.deepLinkable) {
            const target = [...this.deepLink, train.id];
            this.router
              .navigate(target)
              .then(() => this.trainSelect.emit(train))
              .catch(error => console.log('ROUTING ERROR', error));
          } else {
            this.trainSelect.emit(train);
          }
          resolve(true);
        }
      }
    });
  }
  /**
   * Used to update the url without rerouting but generating deep links to the vehicle
   * @param vehicleId
   */

  public updateVehicleIdParam(vehicleId: string) {
    let path = '';
    const loc = this.location.path().split('/');
    loc.pop();
    if (loc) {
      loc.forEach(item => {
        if (item && item != '') {
          path += item + '/';
        }
      });
    }
    path += vehicleId;
    if (path) {
      this.location.replaceState(path);
    }
  }
  private searchListener(): void {
    fromEvent(this.searchField.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter(res => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
      )
      .subscribe((input: string) => {
        this.filterItem(input);
      });
    fromEvent(this.searchForm.nativeElement, 'submit')
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((event: any) => {
        event.preventDefault();
      });
  }

  private filterItem(value: unknown, by?: string): void {
    if (!by) by = 'name';
    const tmp = new Array<FleetsGrouped>();
    this.unfilteredItems.forEach((group: FleetsGrouped) => {
      const foundTrains = group.trains.filter(item => {
        if (value instanceof String || typeof value === 'string') {
          return item[by].indexOf(value) > -1;
        } else {
          return item[by] === value;
        }
      });
      tmp.push({
        fleet: group.fleet,
        trains: foundTrains,
      });
    });
    this.items = tmp;
    this.checkItemVisibility();
  }

  private checkItemVisibility(): void {
    let found = false;
    this.items.forEach((group: FleetsGrouped) => {
      if (!found) {
        found = group.trains.includes(this.lastSelectedTrain);
      }
    });
    if (!found) {
      this.loadDetails(0, 0);
    }
    if (this.lastSelectedTrain) {
      const pos: ListPosition = this.findListPositionById(
        this.lastSelectedTrain.id
      );
      this.selectItem(pos.groupIndex, pos.itemIndex);
    }
  }

  private getAllParams(): any {
    const getParams = route => ({
      ...route.params,
      ...route.children.reduce(
        (acc, child) => ({ ...getParams(child), ...acc }),
        {}
      ),
    });
    return getParams(this.router.routerState.snapshot.root);
  }

  public toggleStateFilter() {
    this.stateFilter = !this.stateFilter;
    if (this.stateFilter) {
      this.filterItem(true, 'hasIncidents');
    } else {
      this.resetItems();
    }
  }

  public resetItems(): void {
    this.searchField.nativeElement.value = '';
    this.items = this.unfilteredItems;
    this.checkItemVisibility();
  }

  public startConfigurator(): void {
    this.visible = false;
    this.addClick.emit(this.router.url);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
