import * as moment from 'moment-timezone';

import {
  CONFIRMATION_STATES,
  Incident,
  listFadeInAndOutAnimation,
} from './../../../core';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, timer } from 'rxjs';

import { IncidentsService } from './../../../incidents/services/incidents.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { delayWhen, takeUntil, tap } from 'rxjs/operators';
import { fadeInAndOutAnimation } from './../../../core/animations/fade-in-and-out.animation';
import { DateFilter } from './../../../incidents/models/date-filter.model';

/**
 * About the hybrid filter logic of the component:
 *
 * The initial request delivers all currently available incidents in a predefined range (e.g. last week)
 *
 * State filters are only applied locally, even if the cStates are currently available via
 * the REST API.
 *
 * Slider heavily inspired by: https://codepen.io/trevanhetzel/pen/rOVrGK
 *
 * But: If the timespan changes (predefined or custom, and dateTo or dateFrom has a diff > maxValidityInMinutes ), the data will be requested and refreshed via the REST API
 */

@Component({
  selector: 'idm-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss'],
  animations: [listFadeInAndOutAnimation, fadeInAndOutAnimation],
})
export class IncidentListComponent implements OnInit, OnDestroy {
  public incidents: Array<Incident> = null;
  public selected = -1;
  public confirmationStates = CONFIRMATION_STATES;
  public filter: boolean = null;
  public formData: FormGroup;
  public selectedRange: number;

  public rangeOptions = 3; // without custom

  public dateTo$: BehaviorSubject<NgbDate> = new BehaviorSubject<NgbDate>(null);
  public dateFrom$: BehaviorSubject<NgbDate> = new BehaviorSubject<NgbDate>(
    null
  );

  public datepickerDisabled = true;
  public ngUnsubscribe$: Subject<boolean> = new Subject();

  private filterDefaults = {
    open: true,
    confirmed: true,
    rejected: false,
    maintenance: 'all', // open, all, done
    shelling: true,
    flatspot: true,
    bearing: true,
    dateFrom: null,
    dateTo: null,
  };
  private maxValidityInMinutes = 30;
  private cachedIncidents: Array<Incident> = new Array<Incident>();

  constructor(
    private incidentsService: IncidentsService,
    private cdr: ChangeDetectorRef
  ) {}

  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay);

  @ViewChild('dateRange', { static: false }) dateRange: ElementRef;

  ngOnInit(): void {
    // Default range last month = 1
    this.setRange(null, 3).then(() => {
      this.incidentsService.dateRange$.next({
        dateFrom: <any>this.dateFrom$.value,
        dateTo: <any>this.dateTo$.value,
      });
      this.initForm();
      this.subscribeOnIncidents();
      this.watchIncident();
      this.watchDates();
    });
  }

  private initForm(): void {
    this.formData = new FormGroup({
      open: new FormControl(this.filterDefaults.open),
      confirmed: new FormControl(this.filterDefaults.confirmed),
      rejected: new FormControl(this.filterDefaults.rejected),
      maintenance: new FormControl(this.filterDefaults.maintenance),
      shelling: new FormControl(this.filterDefaults.shelling),
      flatspot: new FormControl(this.filterDefaults.flatspot),
      bearing: new FormControl(this.filterDefaults.bearing),
      dateFrom: new FormControl(this.filterDefaults.dateFrom),
      dateTo: new FormControl(this.filterDefaults.dateFrom),
    });
  }

  private subscribeOnIncidents(): void {
    this.incidentsService.incidents$
      .pipe(takeUntil(this.ngUnsubscribe$), delayWhen(this.delayFor))
      .subscribe((incidents: Array<Incident>) => {
        this.selected = -1;
        this.cachedIncidents = incidents;
        this.applyFilterLocally();
      });
  }

  private watchIncident(): void {
    this.incidentsService.watchIncident$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((update: any) => {
        this.cdr.markForCheck();
        const found: Incident = this.incidents.find(incident => {
          return incident.id === update.id;
        });
        if (found) {
          if (typeof update?.patch?.confirmation !== 'undefined') {
            found.confirmation = update?.patch?.confirmation;
          }
          if (typeof update?.patch?.maintenance !== 'undefined') {
            found.maintenance = update.patch.maintenance;
          }
          const index = this.incidents.indexOf(found);
          this.incidents[index] = found;
          this.cdr.detectChanges();
        }
      });
  }

  private watchDates(): void {
    this.dateFrom$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => this.formData.get('dateFrom').setValue(value));
    this.dateTo$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => this.formData.get('dateTo').setValue(value));
  }

  public loadDetails(index: number, hard?: boolean): void {
    if (index !== this.selected || hard === true) {
      this.selected = index;
      this.incidentsService.loadIncidentDetails(this.incidents[index].id);
    }
  }

  public toggleFilter(): void {
    this.filter = !this.filter;
    if (this.filter === true) {
      setTimeout(() => {
        this.dateRange.nativeElement.value = this.selectedRange;
        this.setRange(null, this.selectedRange);
      }, 10);
    }
  }

  public updateDate(target: string, event: Event) {
    const value = <any>(event.target as HTMLInputElement)?.value || event;
    switch (target) {
      case 'dateFrom':
        this.dateFrom$.next(value);
        break;
      case 'dateTo':
        this.dateTo$.next(value);
        break;
    }
  }

  public reset(dry?: boolean): void {
    this.formData.setValue(this.filterDefaults);
    this.filter = false;
    if (!dry) {
      this.setRange(null, 2).then(() => {
        this.apply();
      });
    }
  }

  public apply(): void {
    // compare current state of service dates with local selection
    // only fire a new request if they have changed
    const serviceDates: DateFilter = this.incidentsService.dateRange$.value;
    const dateFrom = this.dateFrom$.value.toString();
    const dateTo = this.dateTo$.value.toString();
    // Don't refresh if the difference between the dates is not more then <maxValidityInMinutes> minutes
    const dateFromDiff: number = moment
      .utc(serviceDates.dateFrom)
      .diff(moment.utc(dateFrom), 'minutes');
    const dateToDiff: number = moment
      .utc(serviceDates.dateTo)
      .diff(moment.utc(dateTo), 'minutes');
    if (
      Math.abs(dateFromDiff) > this.maxValidityInMinutes ||
      Math.abs(dateToDiff) > this.maxValidityInMinutes ||
      this.incidents.length === 0
    ) {
      this.incidentsService.dateRange$.next({
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
      this.incidentsService.loadIncidentsByVehicle();
    } else {
      this.applyFilterLocally();
    }
    this.filter = false;
  }

  /**
   * Example Object
   * {
   *  "id": "86253875-2775-1683-3534-260533078643",
   *  "measured": "2022-06-09T16:52:50.418Z",
   *  "subtype": "SHELLING", // Other: FLATSPOT, BEARING
   *  "type": "WHEEL_DAMAGE",
   *  "confirmation": {
   *     "state": "OPEN", // Other: CONFIRMED, REJECTED
   *     "inspectionDate": null
   * },
   * "maintenance": {
   *     "state": "UNREPAIRED", // Other: REPAIRED
   *     "repairDate": null
   *  }
   * }
   */
  private applyFilterLocally(): void {
    const tempIncidentStates = [...this.cachedIncidents];
    const f = this.formData.value;
    // set all selected states
    const stateConditions = new Array<string>();
    if (f.open) stateConditions.push('OPEN');
    if (f.confirmed) stateConditions.push('CONFIRMED');
    if (f.rejected) stateConditions.push('REJECTED');
    // set all selected incident types
    const incidentTypes = new Array<string>();
    if (f.shelling) incidentTypes.push('SHELLING');
    if (f.flatspot) incidentTypes.push('FLATSPOT');
    if (f.bearing) incidentTypes.push('BEARING');
    // set maintenance
    const maintenanceStates = new Array<string>();
    if (f.maintenance === 'repaired' || f.maintenance === 'all') {
      maintenanceStates.push('REPAIRED');
    }
    if (f.maintenance === 'unrepaired' || f.maintenance === 'all') {
      maintenanceStates.push('UNREPAIRED');
    }
    const filteredIncidentStates = tempIncidentStates.filter(item => {
      // check if includes is the right condition
      // I think the maintenance state should be set to repaired and unrepaired if checked
      return (
        stateConditions.includes(item.confirmation.state) &&
        incidentTypes.includes(item.subtype) &&
        maintenanceStates.includes(item.maintenance.state)
      );
    });
    this.incidents = filteredIncidentStates;
    // Mark and load first element after processing
    if (this.incidents.length > 0) {
      this.loadDetails(0, true);
    } else {
      this.incidentsService.details$ = null;
    }
  }

  public setRange(event: Event, direct?: number): Promise<boolean> {
    return new Promise((resolve, __reject) => {
      this.datepickerDisabled = true;
      // direct means it is set manually and not via the date range slider
      if (direct) {
        // If it is undefined, the filter hasn't been activated and we just need the
        // dates for the initial set and request.
        if (typeof this.dateRange !== 'undefined') {
          this.dateRange.nativeElement.value = direct;
        }
      }
      const range: number = !direct
        ? +(event.target as HTMLInputElement).value
        : direct;
      this.dateTo$.next(<any>moment.utc().toISOString());
      switch (range) {
        case 1:
          this.dateFrom$.next(
            <any>moment.utc().subtract(30, 'days').toISOString()
          );
          break;
        case 2:
          this.dateFrom$.next(
            <any>moment.utc().subtract(3, 'months').toISOString()
          );
          break;
        case 3:
          this.dateFrom$.next(
            <any>moment.utc().subtract(1, 'years').toISOString()
          );
          break;
        case 4:
          this.dateFrom$.next(<any>moment.utc().toISOString());
          this.datepickerDisabled = false;
          break;
      }
      this.selectedRange = range;
      resolve(true);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
