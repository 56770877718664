import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FleetService } from './../../services/fleet.service';
import { fadeInAndOutAnimation } from './../../../core';

@Component({
  selector: 'idm-fleet-components',
  templateUrl: './fleet-components.component.html',
  styleUrls: ['./fleet-components.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FleetComponentsComponent implements OnInit, OnDestroy {
  public components: any = null;
  public collapsedTags = false;

  private subs: Array<Subscription> = new Array<Subscription>();
  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay);

  private lastSerial: string = null;

  @ViewChild('inventoryContainerRef') inventoryContainerRef: ElementRef;
  constructor(private fleetService: FleetService) {}

  ngOnInit(): void {
    this.fleetService.ngUnsubscribe$.next(false);
    this.subs.push(
      this.fleetService.components$
        .pipe(
          delay(1),
          tap(() => (this.components = null)),
          filter((components: any) => components !== null)
        )
        .subscribe(components => {
          this.components = components;
          if (this.components?.device) {
            const sn = this.components?.device.sn;
            this.fleetService
              .getTagLastSignalByGatewaySerial(this.components?.device.sn)
              .pipe(
                takeUntil(
                  this.fleetService.ngUnsubscribe$.pipe(value => value)
                ),
                distinctUntilChanged(() => this.lastSerial !== sn)
              )
              .subscribe(signals => {
                this.lastSerial = sn;
                if (this.components?.tags) {
                  this.components.tags.forEach(tag => {
                    const found = signals.find(signal => {
                      return tag.bleId === signal.bleId;
                    });
                    if (found) {
                      tag['lastSignal'] = found.timestamp;
                    }
                  });
                }
              });
          }
        })
    );
  }

  private reset(): void {
    this.scrollContentToTop();
    this.collapsedTags = false;
  }

  private scrollContentToTop(): void {
    if (typeof this.inventoryContainerRef !== 'undefined') {
      this.inventoryContainerRef.nativeElement.scrollTo(0, 0);
    }
  }

  public toogleTags(): void {
    this.collapsedTags = !this.collapsedTags;
  }

  ngOnDestroy(): void {
    this.fleetService.ngUnsubscribe$.next(true);
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
