<h6>Uploaded files</h6>
<ul class="w-100 pr-2" *ngIf="fileList.length > 0">
  <li
    class="d-flex flex-row w-100 pt-2 pb-2"
    *ngFor="let file of fileList"
    @fade>
    <div class="text-center icon-col p-2">
      <div
        class="pl-2 pr-2 icon-file"
        aria-label="PPT icon"
        inlineSVG="./assets/graphics/icon-blank.svg"></div>
      <div class="file-extension">
        {{ file.fileName | fileExtension }}
      </div>
    </div>
    <div class="p-2">
      <a target="_blank" (click)="downloadFile(file.fileName)">{{
        file.fileName | truncate
      }}</a
      ><br />
      {{ file.lastModified | date: 'YYY-MM-dd, hh:mm:ss a' }}
    </div>
    <div class="text-right p-2 ml-auto">
      <span class="zf-icon-bin" (click)="deleteFile(file.fileName)"></span>
    </div>
  </li>
</ul>
<ul class="w-100 pr-2" *ngIf="fileList.length === 0" @fade>
  <li class="d-flex flex-row w-100 pt-2 pb-2">No uploaded files yet!</li>
</ul>
