<div class="form-group mb-0">
  <div class="d-flex flex-row mb-3">
    <div class="flex-column w-25">
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="feedback-open"
          name="incident-feedback-state"
          class="custom-control-input"
          [(ngModel)]="selectedState"
          [value]="states.OPEN"
          #incidentFeedbackState="ngModel"
          (click)="updateState(states.OPEN)" />
        <label class="custom-control-label" for="feedback-open">Open</label>
      </div>
    </div>
    <div class="flex-colum mr-auto">
      <div class="custom-control custom-radio mb-3">
        <input
          type="radio"
          id="feedback-confirmed"
          name="incident-feedback-state"
          class="custom-control-input"
          [(ngModel)]="selectedState"
          [value]="states.CONFIRMED"
          #incidentFeedbackState="ngModel"
          (click)="updateState(states.CONFIRMED)" />
        <label class="custom-control-label" for="feedback-confirmed"
          >Confirmed</label
        >
      </div>

      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="feedback-rejected"
          name="incident-feedback-state"
          class="custom-control-input"
          [(ngModel)]="selectedState"
          [value]="states.REJECTED"
          #incidentFeedbackState="ngModel"
          (click)="updateState(states.REJECTED)"
          [disabled]="rejectDisabled" />
        <label class="custom-control-label" for="feedback-rejected"
          >Not confirmed</label
        >
      </div>
    </div>
  </div>
  <div class="flex-row d-flex w-75 ml-auto">
    <idm-datepicker-popup
      [disabled]="datepickerDisabled"
      [initDate]="selectedDate"
      label="Inspection date"
      (dateSelectEvent)="updateDate($event)"></idm-datepicker-popup>
  </div>
</div>
