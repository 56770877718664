import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken } from '@ngxs/store';
import { Sidebar } from './sidebar.actions';

export interface SidebarStateModel {
  collapsed: boolean;
}
const INITIAL_STATE: SidebarStateModel = { collapsed: true };
const SIDEBAR_STATE_TOKEN = new StateToken<SidebarStateModel>('sidebar');

@State<SidebarStateModel>({
  name: SIDEBAR_STATE_TOKEN,
  defaults: INITIAL_STATE,
})
@Injectable()
export class SidebarState {
  @Action(Sidebar.Expand)
  expand({ patchState }: StateContext<SidebarStateModel>): void {
    patchState({ collapsed: false });
  }
  @Action(Sidebar.Collapse)
  collapse({ patchState }: StateContext<SidebarStateModel>): void {
    patchState({ collapsed: true });
  }
}
