<div class="pr-5 pl-5">
  <div
    class="mr-auto pt-3 align-items-end"
    *ngIf="viewConfig?.connectGateways === true">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="onConnectGatewaysClick()">
      Connect Gateways
    </button>
  </div>
  <div
    class="mr-auto pt-3 align-items-end"
    *ngIf="viewConfig?.connectTags === true">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="onConnectTagsClick()">
      Connect Tags
    </button>
  </div>
  <div
    class="mr-auto pt-3 align-items-end"
    *ngIf="viewConfig?.connectAll === true">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="onConnectTagsClick(); onConnectGatewaysClick()">
      Test Connection
    </button>
  </div>
  <div>
    <div
      class="mr-auto pt-3 align-items-end"
      *ngIf="viewConfig?.validateConfig === true">
      <button
        type="submit"
        class="btn btn-primary"
        (click)="onValidateConfigurationClick()">
        Validate Configuration
      </button>
    </div>
  </div>
</div>
