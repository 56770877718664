import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { fadeInAndOutAnimation, Train } from '@idm/core';
import { FleetService } from '@idm/fleet/services/fleet.service';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import {
  delay,
  delayWhen,
  filter,
  skipUntil,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FleetConfigurationService } from '@idm/fleet';
export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

@Component({
  selector: 'idm-fleet-content',
  templateUrl: './fleet-content.component.html',
  styleUrls: ['./fleet-content.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FleetContentComponent implements OnDestroy, AfterViewInit {
  public train: Train;
  public tab = 'info';
  public isConfigurator$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private ngUnsubscribe$: Subject<boolean> = new Subject();

  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay * 2);

  constructor(
    private fleetService: FleetService,
    private fleetConfigurationService: FleetConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.checkForConfiguratorRoute(this.router.url);
    this.router.events
      .pipe(
        delay(1),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.checkForConfiguratorRoute(event.url);
      });
    this.subscribeOnConfigurationUpdates();
    this.initData();
  }

  checkForConfiguratorRoute(url): void {
    this.isConfigurator$.next(url.indexOf('configurator') > -1);
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.markForCheck();
    if (this.router.url.indexOf('configurator') > -1) {
      this.isConfigurator$.next(true);
    }
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe$), delay(1))
      .subscribe(params => {
        this.tab = params['tab'];
      });
    this.changeDetectorRef.detectChanges();
  }

  private initData() {
    this.fleetService.vehicle$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        delay(10),
        skipUntil(this.isConfigurator$.pipe(filter(is => !is))),
        filter(isNonNull),
        tap(() => (this.train = null)),
        delayWhen(this.delayFor)
      )
      .subscribe((train: Train) => {
        this.changeDetectorRef.markForCheck();
        this.train = train;
        this.changeDetectorRef.detectChanges();
      });
  }

  private subscribeOnConfigurationUpdates() {
    this.fleetConfigurationService.configuration
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        delay(1),
        filter(isNonNull),
        tap(() => (this.train = null))
      )
      .subscribe(config => {
        if (!this.train) {
          this.train = new Train();
        }
        this.train['name'] = config?.name;
        this.train['fleet'] = config?.fleetName;
      });
  }

  public setTab(tab: string): void {
    this.router.navigateByUrl(this.router.url.replace(this.tab, tab));
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.isConfigurator$.complete();
  }
}
