import { LoadingInformation } from '@idm/core/models/loading-information.model';

// tslint:disable-next-line: no-namespace
export namespace Loading {
  export class Start {
    static readonly type = '[Core] Start Loading';
    constructor(public payload: LoadingInformation) {}
  }
  export class End {
    static readonly type = '[Core] End Loading';
  }
}
