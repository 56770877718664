<div class="d-flex h-100 w-100 overflow-hidden" *ngIf="scope">
  <idm-sidebar-item-selector
    [deepLinkable]="true"
    [scope]="scope"
    [tab]="tab"
    [bottomToolbar]="bottomToolbar"
    (addClick)="addClicked($event)"
    (trainSelect)="trainSelected($event)"
    *ngIf="!(isConfigurator$ | async)"></idm-sidebar-item-selector>
  <idm-fleet-content
    class="d-flex flex-row flex-grow-1 w-100 h-100"></idm-fleet-content>
</div>
