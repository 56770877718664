<div
  class="fleet-inventory-container pb-4 pl-4 pr-4 pt-3 h-100 w-100"
  [@fade]="!components"
  #inventoryContainerRef>
  <div
    class="components-counter mb-5"
    *ngIf="components?.tags"
    [@fade]="components?.tags">
    Components ({{ components.tags.length + 1 }})
  </div>
  <div *ngIf="components" class="d-flex flex-column" [@fade]="components">
    <h4 id="gateway-header">ZF ProPM</h4>
    <div class="d-flex flex-row w-100">
      <div class="mr-4 image-col">
        <div class="p-4 device-image-container">
          <img src="/assets/images/propm.png" alt="ProPM image" />
        </div>
      </div>
      <div class="ml-auto mr-3 detail-col">
        <div class="w-100">
          <table class="table" aria-describedby="gateway-header">
            <th class="d-none" id="hidden-header-1"></th>
            <tbody>
              <tr>
                <td class="pl-3 col-key">Serial no.</td>
                <td class="pr-0 col-val">
                  {{ components.device.sn }}
                </td>
              </tr>
              <tr class="even">
                <td class="pl-3 col-key">Status</td>
                <td class="pr-0 col-val">
                  {{ components.device.status.type }}
                </td>
              </tr>
              <tr>
                <td class="pl-3 col-key">Status since</td>
                <td class="pr-0 col-val">
                  {{
                    components.device.status.timestamp
                      | date: 'YYY-MM-dd, hh:mm:ss a'
                  }}
                </td>
              </tr>
              <tr class="even">
                <td class="pl-3 col-key">Device Type</td>
                <td class="pr-0 col-val">
                  {{ components.device.firmware.vcuDevicetype }}
                </td>
              </tr>
              <tr>
                <td class="pl-3 col-key">Firmware Version</td>
                <td class="pr-0 col-val">
                  {{ components.device.firmware.vcuOsFwVersion }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ng-container *ngIf="components.tags.length > 0">
      <div
        class="collapsible-header d-flex flex-row mb-3 mr-4"
        (click)="toogleTags()">
        <div>
          <h4>Assigned HD TAGs</h4>
        </div>
        <div class="ml-auto pt-2 pr-2">
          <span
            class="arrow"
            [class.up]="!collapsedTags"
            [class.down]="collapsedTags"></span>
        </div>
      </div>
      <div
        class="tag-container"
        *ngFor="let tag of components.tags"
        [class.collapsed]="collapsedTags"
        [class.expanded]="!collapsedTags">
        <h4 id="tag-header">ZF HD TAG</h4>
        <div
          class="d-flex flex-row w-100 mr-4"
          *ngIf="components.tags.length > 0">
          <div class="mr-4 image-col">
            <div class="p-4 device-image-container">
              <img src="/assets/images/hdtag.png" alt="HD-TAG image" />
            </div>
          </div>
          <div class="mr-3 detail-col">
            <div class="w-100">
              <table class="table" aria-describedby="tag-header">
                <th class="d-none" id="hidden-header-2"></th>
                <tbody>
                  <tr>
                    <td class="pl-3 col-key">Serial no.</td>
                    <td class="pr-0 col-val">
                      {{ tag.sn }}
                    </td>
                  </tr>
                  <tr class="even">
                    <td class="pl-3 col-key">BLE ID</td>
                    <td class="pr-0 col-val">
                      {{ tag.bleId }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-3 col-key">Last signal</td>
                    <td class="pr-0 col-val">
                      <span *ngIf="tag.lastSignal">{{
                        tag?.lastSignal | date: 'YYY-MM-dd, hh:mm:ss a'
                      }}</span>
                      <span *ngIf="!tag.lastSignal"
                        >currently not available</span
                      >
                    </td>
                  </tr>
                  <tr class="even">
                    <td class="pl-3 col-key">Status</td>
                    <td class="pr-0 col-val">
                      {{ tag.status.type }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-3 col-key">Status since</td>
                    <td class="pr-0 col-val">
                      {{ tag.status.timestamp | date: 'YYY-MM-dd, hh:mm:ss a' }}
                    </td>
                  </tr>
                  <tr class="even">
                    <td class="pl-3 col-key">Software version</td>
                    <td class="pr-0 col-val">
                      {{ tag.firmware.swRevVersion }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-3 col-key">Firmware version</td>
                    <td class="pr-0 col-val">
                      {{ tag.firmware.fwRevVersion }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
