export const environment = {
  production: true,
  development: false,
  devMode: false,
  portal: 'idm',
  title: 'connect@rail Application Desktop',
  domain: 'dppm-zf.com',
  api: 'https://api-gateway.dppm-zf.com/idm/fe',
  loginApi: 'https://api-gateway.dppm-zf.com',
  telemetryApi: 'https://api-gateway.dppm-zf.com/tas',
  mailApi: 'https://api-gateway.dppm-zf.com/eas',
  activationApi: 'https://api-gateway.dppm-zf.com/da',
  replacementsApi: 'https://api-gateway.dppm-zf.com/dr/api/v1',
  useHash: false,
  gMapsApiKey: 'AIzaSyA64JSWpW6gTQmEL0eXerRwSmw4wLc0V2g',
};
