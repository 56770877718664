<div
  class="d-flex flex-grow-1 h-100 w-100 overflow-hidden"
  *ngIf="!emptyComponentList; else empty">
  <idm-fleet-gateways></idm-fleet-gateways>
  <div class="flex-row w-100">
    <idm-fleet-components></idm-fleet-components>
  </div>
</div>

<ng-template #empty>
  <div
    class="d-flex no-records align-items-center flex-column justify-content-center w-100 h-100">
    <img
      src="../../../../assets/images/no-records.png"
      alt="No registered components" />
    <div>No registered components</div>
  </div>
</ng-template>
