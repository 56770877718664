<div class="mr-0 w-100">
  <h4 class="mt-4 pt-3">Position</h4>
  <mat-icon #iconLocation>my_location</mat-icon>
  <div class="d-flex fleet-map w-100">
    <google-map
      height="500px"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options"
      (mapDrag)="stopPaning()">
      <map-marker
        #markerElem
        *ngFor="let marker of markers"
        [position]="marker.position"
        [options]="marker.options"
        (mapClick)="toggleInfo(marker)">
      </map-marker>

      <map-info-window>
        <div class="d-flex flex-row m-2">
          <div class="w-100 pr-3 border-right">
            <div class="train-state">
              <svg-icon
                src="assets/graphics/heart-state.svg"
                class="heart-state"
                [class.incidents]="train?.hasIncidents === true">
              </svg-icon>
              {{ train?.name }}<br />
            </div>
            <div class="train-heading">
              Heading
              <span *ngIf="!telemetry?.heading?.value">n.a.</span>
              <span *ngIf="telemetry?.heading?.value">
                {{ telemetry?.heading?.value | heading }}
              </span>
            </div>
          </div>
          <div class="ml-auto pr-3 pl-3 border-right">
            <div class="train-speed-gauge">
              <img src="assets/images/speed-gauge.png" alt="speed gauge" />
            </div>
            <div class="train-speed">
              <span *ngIf="!telemetry?.speed?.value">n.a.</span>
              <span *ngIf="telemetry?.speed?.value">
                {{ telemetry?.speed?.value | speed }}</span
              ><span class="train-speed-unit"> km/h</span>
            </div>
          </div>
        </div>
      </map-info-window>
    </google-map>
  </div>
</div>
