<ul class="nav nav-fill nav-steps pt-2 pb-2 mb-3">
  <li
    *ngFor="let step of steps; let i = index"
    class="nav-item"
    [ngStyle]="{ 'width.px': 100 / steps.length }"
    [ngClass]="{ done: i + 1 < currentStepId }">
    <button
      *ngIf="i + 1 <= currentStepId && !done; else noRouting"
      [ngClass]="{
        done: i + 1 < currentStepId || done,
        active: i + 1 === currentStepId
      }"
      [routerLink]="step.target !== '' ? step.target : null"
      class="btn rounded-circle active btn-outline-primary step-button"
      role="button">
      <span *ngIf="i + 1 >= currentStepId; else doneIcon">{{ i + 1 }}</span>
    </button>
    <ng-template #noRouting>
      <button
        class="btn rounded-circle btn-outline-primary step-button"
        [class.disabled]="currentStepId <= i"
        [class.done]="done"
        role="button">
        <span *ngIf="!done; else doneIcon">{{ i + 1 }}</span>
      </button>
    </ng-template>
    <label class="pt-2">{{ step.name }}</label>
  </li>
</ul>

<ng-template #doneIcon>
  <span class="material-icons">done</span>
</ng-template>
