import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@idm/iam';
// Module
import { FilterPipe } from './pipes/filter.pipe';
import { SelectorComponent } from './components/selector/selector.component';

const routes: Routes = [
  {
    path: 'apps',
    children: [
      {
        path: 'selector',
        component: SelectorComponent,
        canActivate: [AuthenticationGuard],
        data: {
          role: ['idm_user', 'idm_admin'],
          animation: 'LandingPage',
        },
      },
    ],
  },
];

@NgModule({
  declarations: [FilterPipe, SelectorComponent],
  providers: [FilterPipe],
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class AppsModule {}
