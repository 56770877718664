<div
  *ngIf="authenticated$ | async"
  class="incident-export-frame mt-3 p-3"
  [@fade]
  [style.left.px]="x"
  [style.top.px]="y">
  <div class="close p-2" (click)="close()">
    <span class="zf-icon-close"></span>
  </div>
  <legend class="mb-0">Please select a time period.</legend>
  <div class="range-selection pl-4 pr-4 pt-3 pb-4">
    <div
      class="range"
      [style]="
        '--active-range-width:' +
        (+dateRange.value - 1) * (100 / rangeOptions) +
        '%'
      ">
      <input
        #dateRange
        type="range"
        min="1"
        max="4"
        steps="1"
        value="3"
        (input)="setRange($event)" />
    </div>
    <ul class="range-labels">
      <li
        (click)="setRange(null, 1)"
        [class.active]="dateRange.value === '1'"
        [class.selected]="dateRange.value >= '1'">
        last 4 weeks
      </li>
      <li
        (click)="setRange(null, 2)"
        [class.active]="dateRange.value === '2'"
        [class.selected]="dateRange.value >= '2'">
        last 3 months
      </li>
      <li
        (click)="setRange(null, 3)"
        [class.active]="dateRange.value === '3'"
        [class.selected]="dateRange.value >= '3'">
        last 12 months
      </li>
      <li
        (click)="setRange(null, 4)"
        [class.active]="dateRange.value === '4'"
        [class.selected]="dateRange.value >= '4'">
        Custom
      </li>
    </ul>
  </div>
  <div class="datepicker">
    <idm-datepicker-popup
      [disabledWithDate]="datepickerDisabled"
      [initDate]="from$ | async"
      label="From"
      (dateSelectEvent)="
        updateDate('from', $event)
      "></idm-datepicker-popup>

    <idm-datepicker-popup
      [disabledWithDate]="datepickerDisabled"
      [initDate]="to$ | async"
      label="To"
      (dateSelectEvent)="
        updateDate('to', $event)
      "></idm-datepicker-popup>
  </div>
  <div class="pt-3 text-right">
    <button type="button" class="btn btn-primary" (click)="submitted.emit()">
      Download
    </button>
  </div>
</div>
