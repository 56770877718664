import * as moment from 'moment';

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { GatewayList, listFadeInAndOutAnimation } from './../../../core';
import { Subscription, timer } from 'rxjs';
import { delayWhen, first } from 'rxjs/operators';

import { FleetService } from './../../services/fleet.service';

/**
 * QR CODES
 *
 * VCUPro 1.01
 * SMALL (SN only):
 * BARCODE & SN: 016284
 * BIG:
 * PN, SN, Date (YY-MM-DD), Rev, ?, LAN1, LAN2, WLAN, BT, ?
 * 0501.338.605;016284;2019-05-31;01.01;352255061806554;7C:97:63:50:3F:9C;7C:97:63:60:3F:9C;7C:97:63:70:3F:9C;7C:97:63:80:3F:9C;0.0.0.000
 *
 * VCUPro 1.02
 * BARCODE & SMALL (SN only):
 * 017902
 * BIG:
 * PN, SN, ?, Date (YY-MM-DD), Rev, IMEI, LAN1, LAN2, WLAN, BT, ?
 * 0501.338.605;017902;89882390000056329769;2020-04-24;01.02;352255062463454;7C:97:63:50:45:EE;7C:97:63:60:45:EE;7C:97:63:70:45:EE;7C:97:63:80:45:EE;1.12.0.0000
 *
 * VCUPro 1.03
 * BARCODE & SMALL (SN only):
 * 019110
 * PN, SN, ?, Date (YY-MM-DD), Rev, IMEI, LAN1, LAN2, WLAN, BT, ?
 * 0501.338.605;019110;89882390000083305055;2020-11-30;01.03;352255063364107;7C:97:63:50:4A:A6;7C:97:63:60:4A:A6;7C:97:63:70:4A:A6;7C:97:63:80:4A:A6;1.5.2
 *
 * Heavy Duty TAG:
 * PN_0501.339.922;SN_HT:01:00:00:04:39;BLE_18:04:ED:BE:BB:92;SUB_00:12:4B:00:1C:BC:35:3A;PASS_521038
 *
 */

@Component({
  selector: 'idm-fleet-gateways',
  templateUrl: './fleet-gateways.component.html',
  styleUrls: ['./fleet-gateways.component.scss'],
  animations: [listFadeInAndOutAnimation],
})
export class FleetGatewaysComponent implements OnDestroy, AfterViewInit {
  public gateways: Array<GatewayList> = new Array<GatewayList>();
  public selected = -1;
  public lastUpdate: string = null;
  public live = true;

  private detailsLoading = false;
  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay);

  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(private fleetService: FleetService) {}

  public ngAfterViewInit(): void {
    this.subs.push(
      this.fleetService.gateways$
        // could be used for fading out the content or a skeleton
        // using tap (like this.skeleton true or via observable
        .pipe(delayWhen(this.delayFor))
        .subscribe((gateways: Array<GatewayList>) => {
          if (gateways !== null) {
            this.selected = -1;
            this.gateways = gateways;
            if (this.gateways.length > 0) {
              this.loadDetails(0);
              this.loadGatewaySignalQuality();
            }
          }
        })
    );
  }

  public loadDetails(index: number): void {
    if (index !== this.selected && !this.detailsLoading) {
      this.selected = index;
      this.detailsLoading = true;
      this.fleetService
        .loadTrainComponentsByGatewaySerial(this.gateways[index].sn)
        .then(() => {
          this.detailsLoading = false;
          this.fleetService.loadTelemetryDataByGatewaySerial(
            this.gateways[index].sn
          );
        })
        .catch(() => (this.detailsLoading = false));
    }
  }

  public loadGatewaySignalQuality(): void {
    this.gateways.forEach((gateway: GatewayList, index: number) => {
      this.subs.push(
        this.fleetService
          .getGatewaySignalQualityBySerial(gateway.sn)
          .pipe(first())
          .subscribe({
            next: signalQuality => {
              // Check if updatedAt is not older than 1 min
              const now = moment.utc(moment.now());
              const lastUpdate = moment.utc(signalQuality.updatedAt);
              if (now.diff(lastUpdate, 'minutes') > 1) {
                signalQuality.quality = 0;
              }
              this.updateGatewayData(
                index,
                signalQuality.quality,
                signalQuality.interface
              );
            },
            error: __error => this.updateGatewayData(index, '0', ''),
          })
      );
    });
    this.lastUpdate = moment.utc().toString();
  }

  private updateGatewayData(
    index: number,
    signal: string,
    interf: string
  ): void {
    if (this.gateways[index]) {
      this.gateways[index].signalQuality = signal;
      this.gateways[index].interface = interf;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
