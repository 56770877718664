import * as moment from 'moment';

import { BehaviorSubject, Observable } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { AuthenticationState } from './../../../iam/store/authentication/authentication.state';
import { IncidentsService } from './../../../incidents/services/incidents.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NotificationMessageType } from './../../../core/models/notification-message-type.enum';
import { Notifications } from './../../../core/store/notifications/notifications.action';
import { OnInit } from '@angular/core';
import { StatisticIncident } from './../../models/statistic-incident.model';
import { Store } from '@ngxs/store';
import { fadeInAndOutAnimation } from './../../../core/animations/fade-in-and-out.animation';
import { first } from 'rxjs/operators';

@Component({
  animations: [fadeInAndOutAnimation],
  selector: 'idm-incident-export',
  templateUrl: './incident-export.component.html',
  styleUrls: ['./incident-export.component.scss'],
})
export class IncidentExportComponent implements OnInit {
  @Output() submitted = new EventEmitter<void>();
  @Input() target: EventTarget;
  public x = 0;
  public y = 0;
  public datepickerDisabled = true;
  public rangeOptions = 3; // without custom
  public selectedRange: number;
  public to$: BehaviorSubject<NgbDate | string> = new BehaviorSubject<
    NgbDate | string
  >('');
  public from$: BehaviorSubject<NgbDate | string> = new BehaviorSubject<
    NgbDate | string
  >('');
  public authenticated$: Observable<boolean>;
  @ViewChild('dateRange', { static: false }) dateRange: ElementRef;

  constructor(
    private incidentsService: IncidentsService,
    private store$: Store
  ) {
    this.authenticated$ = this.store$.select(
      AuthenticationState.isAuthenticated
    );
    this.authenticated$.subscribe(authenticated => {
      if (!authenticated) {
        this.incidentsService.destroyExportForm();
      }
    });
  }

  /**
   * @description: Initializes the component and calculates the default range and position
   * It also registers on the submit event to start the download process afterwards
   */
  ngOnInit(): void {
    this.setRange(null, 3).then(() => {
      this.calculatePosition();
    });
    this.submitted.pipe(first()).subscribe({
      next: () => this.downloadIncidents(),
    });
  }

  /**
   * @description This method calculates the position of the CSV download form based on the position
   * of its target element. It uses the x and y coordinates of the target element to
   * set the position of the tooltip.
   */
  private calculatePosition(): void {
    const target: HTMLElement = this.target as HTMLElement;
    const dimensions: DOMRect = target.getBoundingClientRect();
    this.x = dimensions.x - 175 + dimensions.width / 2;
    this.y = dimensions.y + dimensions.height;
  }

  /**
   * @description Sets the date range for the CSV export.
   *
   * @param event
   * @param direct
   */
  public setRange(event: Event, direct?: number): Promise<boolean> {
    return new Promise(resolve => {
      this.datepickerDisabled = true;
      // direct means it is set manually and not via the date range slider
      if (direct) {
        // If it is undefined, the filter hasn't been activated and we just need the
        // dates for the initial set and request.
        if (typeof this.dateRange !== 'undefined') {
          this.dateRange.nativeElement.value = direct;
        }
      }
      const range: number = !direct
        ? +(event.target as HTMLInputElement).value
        : direct;
      this.to$.next(moment.utc().toISOString());
      switch (range) {
        case 1:
          this.from$.next(moment.utc().subtract(30, 'days').toISOString());
          break;
        case 2:
          this.from$.next(moment.utc().subtract(3, 'months').toISOString());
          break;
        case 3:
          this.from$.next(moment.utc().subtract(1, 'years').toISOString());
          break;
        case 4:
          this.from$.next(moment.utc().toISOString());
          this.datepickerDisabled = false;
          break;
      }
      this.selectedRange = range;
      resolve(true);
    });
  }

  // The user has changed the date in the UI, so we need to update the corresponding
  // date subject

  public updateDate(target: string, event: Event | string) {
    const value =
      <string>((event as Event).target as HTMLInputElement)?.value ||
      <string>event;
    switch (target) {
      case 'from':
        this.from$.next(value);
        break;
      case 'to':
        this.to$.next(value);
        break;
    }
  }

  // This function downloads a CSV file containing the data from the
  // incidents array.

  public downloadIncidents(): void {
    this.incidentsService
      .getDownloadableIncidents(this.from$.value, this.to$.value)
      .pipe(first())
      .subscribe({
        next: (response: Array<StatisticIncident>) => {
          this.exportStatisticIncidentsToCSV(response);
        },
      });
  }

  public close(): void {
    this.incidentsService.destroyExportForm();
  }

  private formatDate(date: NgbDate | string): string {
    return moment.utc(date).format('YYYYMMDD');
  }

  // This function takes a list of StatisticIncident objects and converts it to a CSV file.
  // The file is then downloaded by the browser. This is used to export the data to a CSV file.

  private exportStatisticIncidentsToCSV(data: Array<StatisticIncident>): void {
    data.forEach((data: StatisticIncident) => {
      delete data.incidentId;
    });
    if (data?.length > 0) {
      const replacer = (__key: string, value: string) =>
        value === null ? '' : value;
      const delimiter = ';';
      const header = Object.keys(data[0]);
      const csv = data.map(row =>
        header
          .map(fieldName => JSON.stringify(row[fieldName], replacer))
          .join(delimiter)
      );
      csv.unshift(header.join(delimiter));
      const csvArray = csv.join('\r\n');
      const blob = new Blob([csvArray], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute(
        'download',
        'idmIncidentsStats_' +
          this.formatDate(this.from$.getValue()) +
          '-' +
          this.formatDate(this.to$.getValue()) +
          '.csv'
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      this.store$.dispatch(
        new Notifications.AddNotification({
          type: NotificationMessageType.warning,
          msg: 'There are no incidents to export.',
        })
      );
    }
  }
}
