import { fadeInAndOutAnimation } from './core/animations/fade-in-and-out.animation';
import { CoreService } from './core/services/core.service';
import { Observable, of, Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationState } from './iam';
import { Title } from '@angular/platform-browser';
import { environment } from './../environments/environment';
import { DOCUMENT } from '@angular/common';
import buildInfo from '../build';

import { NgcCookieConsentService, NgcInitializeEvent } from 'ngx-cookieconsent';
import { routeFadeInAndOutAnimation } from './core';
import { Router, RouterOutlet } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, first, map } from 'rxjs/operators';

@Component({
  selector: 'idm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeFadeInAndOutAnimation, fadeInAndOutAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  public authenticated$: Observable<boolean>;

  public masked: boolean;

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  constructor(
    private store: Store,
    private titleService: Title,
    private coreService: CoreService,
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    private ccService: NgcCookieConsentService,
    private httpClient$: HttpClient
  ) {
    this.httpClient$
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=' +
          environment.gMapsApiKey,
        'callback'
      )
      .pipe(
        first(),
        map(() => true),
        catchError(error => {
          console.log('Google Maps API not loaded!', error);
          return of(true);
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.logBuildInfo();
    this.titleService.setTitle(
      'ZF ' + environment.title + ' ' + this.coreService.version
    );
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );

    // SET MASKED also on routes!
    this.masked = !this.ccService.hasAnswered();

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      this.masked = true;
    });
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      this.masked = false;
    });
    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        console.log('EVENT: ', event);
      }
    );
  }

  private logBuildInfo() {
    console.log(
      `\n%cBuild Info:\n\n` +
        `%c ❯ Environment: %c${
          environment.production ? 'production 🏭' : 'development 🚧'
        }\n` +
        `%c ❯ Build Version: ${buildInfo.version || 'unavailable'}\n` +
        ` ❯ Build Timestamp: ${buildInfo.timestamp || 'unavailable'}\n` +
        ` ❯ Build Message: %c${buildInfo.message || '<no message>'}\n` +
        `%c ❯ FullHash: ${buildInfo.git.fullHash || 'unvailable'}\n` +
        ` ❯ Branch: ${buildInfo.git.branch || 'unavailable'}\n`,
      'font-size: 14px; color: #7c7c7b;',
      'font-size: 12px; color: #7c7c7b',
      environment.production
        ? 'font-size: 12px; color: #95c230;'
        : 'font-size: 12px; color: #e26565;',
      'font-size: 12px; color: #7c7c7b',
      'font-size: 12px; color: #bdc6cf',
      'font-size: 12px; color: #7c7c7b'
    );
  }

  public prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  public ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
