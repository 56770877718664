<div
  #sidenav
  class="container sidebar position-fixed h-100 m-0 p-0 zf-gradient-background"
  *ngIf="authenticated$ | async"
  [ngClass]="{ expanded: (sidebar$ | async) !== true }">
  <nav id="sidebar-nav">
    <ul class="list-unstyled components">
      <li class="nav-item p-0 mb-3 mt-2">
        <a routerLink="/apps/selector" routerLinkActive="active"
          ><span class="zf-icon-menu-home pr-4 pl-4"></span>
          <div class="target-name">All Apps</div></a
        >
      </li>
      <hr />
      <li class="nav-item p-0 mb-1 mt-1">
        <a routerLink="/incidents" routerLinkActive="active"
          ><span class="zf-icon-flash pr-4 pl-4"></span>
          <div class="target-name">Incidents</div></a
        >
      </li>
      <li
        class="nav-item p-0 mb-1 mt-1"
        [class.disabled]="
          (vehicleId$ | async) === null || (vehicleId$ | async) === undefined
        ">
        <a
          [routerLink]="[
            '/',
            'devices',
            'manager',
            'inventory',
            'vehicle',
            vehicleId$ | async
          ]"
          [class.active]="isDevices$ | async"
          ><span class="zf-icon-technology pr-4 pl-4"></span>
          <div class="target-name">Devices</div></a
        >
      </li>
      <li
        class="nav-item p-0 mb-1 mt-1"
        [class.disabled]="
          (vehicleId$ | async) === null || (vehicleId$ | async) === undefined
        ">
        <a
          [class.active]="isFleets$ | async"
          [routerLink]="[
            '/',
            'fleets',
            'manager',
            'info',
            'vehicle',
            vehicleId$ | async
          ]"
          ><span class="zf-icon-train pr-4 pl-4"></span>
          <div class="target-name">Fleets</div></a
        >
      </li>
      <li class="nav-item p-0 mb-1 mt-1">
        <a
          routerLink="/fleets/manager/configuration/configurator/edit-train"
          [class.active]="isConfigurator$ | async"
          ><span class="zf-icon-chassis pr-4 pl-4"></span>
          <div class="target-name">Configurator</div></a
        >
      </li>
      <li
        class="nav-item p-0 mb-1 mt-1"
        [class.disabled]="
          (vehicleId$ | async) === null || (vehicleId$ | async) === undefined
        ">
        <a
          routerLink="/maintenance/manager"
          [routerLink]="[
            '/',
            'maintenance',
            'manager',
            'vehicle',
            vehicleId$ | async
          ]"
          [class.active]="isMaintenance$ | async"
          ><span class="zf-icon-workshop pr-4 pl-4"></span>
          <div class="target-name">Maintenance</div></a
        >
      </li>
      <li class="nav-item p-0 mb-1 mt-1">
        <a (click)="showContact()">
          <span class="zf-icon-dialogue pr-4 pl-4"></span>
          <div class="target-name">Contact</div></a
        >
      </li>
    </ul>
    <ul class="list-unstyled utilities pl-3 pr-3 pb-2 m-0">
      <li class="nav-item p-0 mb-1">
        <a routerLink="/imprint" routerLinkActive="active">Imprint</a>
      </li>
      <li class="nav-item p-0 claim">
        Version {{ version }} | &copy; ZF Friedrichshafen AG
      </li>
    </ul>
  </nav>
</div>
