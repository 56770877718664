<div
  class="container sidebar-item-selector h-100 m-0 p-0 mr-3 pb-5"
  [class.visible]="!visible"
  [class.hidden]="hidden">
  <div
    class="sidebar-item-selector-toolbar d-flex flex-row pr-4 pl-4 pt-3 pb-3"
    [class.hidden]="!visible">
    <!-- Vehicles ({{ items.length }})-->
    <div class="w-100">
      <form #searchForm class="form-inline d-inline">
        <div class="input-group">
          <input
            #searchField
            type="text"
            class="form-control search-item"
            placeholder="Search by name" />
          <button class="btn bg-transparent clear-btn">
            <span class="material-icons" (click)="resetItems()">cancel</span>
          </button>
        </div>
      </form>
    </div>
    <div class="toolbar-actions ml-auto">
      <button
        type="button"
        class="btn btn-link pt-2 pr-0"
        (click)="toggleStateFilter()">
        <svg-icon
          src="assets/graphics/heart-state.svg"
          class="heart-state button"
          [class.active]="stateFilter">
        </svg-icon>
      </button>
    </div>
  </div>

  <div class="m-4 no-records" *ngIf="items.length === 0 && initialized">
    <ng-container *ngIf="prefilter === 'incidents'"
      >No incidents registered</ng-container
    >
    <ng-container *ngIf="prefilter === 'all'">No elements found</ng-container>
  </div>

  <ul
    class="sidebar-item-selector-list overflow-auto d-flex flex-column m-0 p-0 h-100"
    [@listAnimation]="items.length"
    *ngIf="items">
    <li *ngFor="let item of items; let groupIndex = index">
      <!-- Note: Accessing length via item.value doesn't work even with optional property -->
      <div
        class="d-flex flex-row pt-3 pb-3 mr-4 ml-4 sidebar-grouping-header"
        [class.borderless]="groupIndex === 0"
        (click)="toggleGroupVisibility(item.fleet)">
        <div>{{ item.fleet }} ({{ item.trains.length }})</div>
        <div class="ml-auto pr-2">
          <span
            class="arrow"
            [class.up]="
              groupVisible[item.fleet] === undefined || groupVisible[item.fleet]
            "
            [class.down]="
              groupVisible[item.fleet] !== undefined &&
              !groupVisible[item.fleet]
            "></span>
        </div>
      </div>
      <ul
        class="sidebar-item-selector-items m-0 p-0"
        [class.expanded]="
          groupVisible[item.fleet] === undefined || groupVisible[item.fleet]
        "
        [class.collapsed]="
          groupVisible[item.fleet] !== undefined && !groupVisible[item.fleet]
        ">
        <!-- set active class on selected item -->
        <li
          class="d-flex flex-row ml-2 mr-2 mb-1 p-3"
          [class.active]="
            itemIndex === selectedItemIndex && groupIndex === selectedGroupIndex
          "
          *ngFor="let train of item.trains; let itemIndex = index"
          (click)="loadDetails(groupIndex, itemIndex)">
          <div class="col-identifier">
            {{ train.name }}
          </div>
          <div class="col-global-state ml-auto pr-0">
            <svg-icon
              src="assets/graphics/heart-state.svg"
              class="heart-state"
              [class.incidents]="train.hasIncidents === true"
              [class.pulse-commentout]="train.hasIncidents === true">
            </svg-icon>
          </div>
        </li>
      </ul>
    </li>
    <!-- Empty List element as placeholder to enable reaction with the add button -->
    <li>
      <div class="list-placeholder"></div>
    </li>
  </ul>
  <div
    class="sidebar-item-selector-bottom-toolbar text-right pr-3 pl-4 pt-2 pb-2"
    [class.hidden]="!visible"
    *ngIf="bottomToolbar === true">
    <button
      (click)="startConfigurator()"
      class="btn bg-transparent add-btn p-0">
      <span class="material-icons">add_circle</span>
    </button>
  </div>

  <div class="d-flex flex-column sidebar-item-selector-actions h-100">
    <div class="action-handle-wrapper">
      <div class="action-handle p-2 m-0" (click)="toggleVisibility()">
        <span
          class="arrow"
          [class.left]="visible"
          [class.right]="!visible"></span>
      </div>
    </div>
  </div>
</div>
