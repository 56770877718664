import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthenticationGuard } from './../iam';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { IncidentContentComponent } from './components/incident-content/incident-content.component';
import { IncidentDetailsComponent } from './components/incident-details/incident-details.component';
import { IncidentExportComponent } from './components/incident-export/incident-export.component';
import { IncidentFeedbackComponent } from './components/incident-feedback/incident-feedback.component';
import { IncidentListComponent } from './components/incident-list/incident-list.component';
import { IncidentMaintenanceComponent } from './components/incident-maintenance/incident-maintenance.component';
import { IncidentManagementComponent } from './components/incident-management/incident-management.component';
import { IncidentMapComponent } from './components/incident-map/incident-map.component';
import { IncidentsService } from './services/incidents.service';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const routes: Routes = [
  {
    path: 'incidents',
    redirectTo: 'incidents/manager',
    pathMatch: 'full',
  },
  {
    path: 'incidents/manager',
    component: IncidentManagementComponent,
    canActivate: [AuthenticationGuard],
    data: {
      role: ['idm_user', 'idm_admin'],
    },
  },
];

@NgModule({
  declarations: [
    IncidentManagementComponent,
    IncidentContentComponent,
    IncidentListComponent,
    IncidentDetailsComponent,
    IncidentMapComponent,
    IncidentFeedbackComponent,
    IncidentMaintenanceComponent,
    IncidentExportComponent,
  ],
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    CommonModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxSkeletonLoaderModule,
    GoogleMapsModule,
  ],
  providers: [IncidentsService],
})
export class IncidentsModule {}
