import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { FleetService } from './../../services/fleet.service';
import { MatIcon } from '@angular/material/icon';
import { Train } from './../../../core';
import { reject } from 'lodash-es';
import { takeWhile } from 'rxjs/operators';

class MapCoordinates {
  lat: number;
  lng: number;
}

// About the Google Maps Component: https://clipcode.net/assets/academy/virtual/clipcode-google-maps-in-angular-internals.pdf
@Component({
  selector: 'idm-fleet-map',
  templateUrl: './fleet-map.component.html',
  styleUrls: ['./fleet-map.component.scss'],
})
export class FleetMapComponent implements OnInit, AfterViewInit, OnDestroy {
  public zoom = 16;
  public center = {
    lat: 47.66481,
    lng: 9.471089,
  };
  public options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    scaleControl: true,
    streetViewControl: false,
  };
  public markerOptions = {
    icon: '/assets/images/map-train-marker.png',
  };
  public infoWindowOpened = false;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) mapMarker: MapMarker;
  @ViewChild('iconLocation', { static: false }) iconLocation: MatIcon;
  @ViewChild('markerElem', { static: false }) markerElem: MapMarker;

  public markers: Array<any> = new Array<any>();
  public telemetry: any;
  public train: Train;
  public error = false;

  private lastPosition: MapCoordinates;
  private minDistance = 0.01; // km
  private instanceUrl: string;
  private fps = 100;
  private autoPanOnHold$ = new BehaviorSubject<boolean>(false);

  private timeoutRef: Array<any> = new Array<any>();

  private subs: Array<Subscription> = new Array<Subscription>();

  constructor(
    private fleetService: FleetService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {}

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden) {
      this.stopPaning();
    } else {
      this.restartPaning();
    }
  }

  ngOnInit(): void {
    this.instanceUrl = this.router.url;
    this.ngOnDestroy();
    this.error = false;
    this.subs.push(
      this.fleetService.vehicle$.subscribe(
        (train: Train) => (this.train = train)
      )
    );
    this.autoPanOnHold$.subscribe(val => {
      if (this.mapMarker && !val) {
        this.setCenter({
          lat: this.mapMarker.getPosition().lat(),
          lng: this.mapMarker.getPosition().lng(),
        });
        this.killTimeouts();
      }
    });
    this.getFPS().then(fps => {
      // Doubled it to ensure to get into every frame update;
      this.fps = fps * 2;
      this.subs.push(
        this.fleetService.telemetry$
          .pipe(takeWhile(() => this.router.url === this.instanceUrl))
          .subscribe({
            next: (telemetry: any) => {
              if (this.map) {
                if (
                  telemetry !== null &&
                  telemetry.position.value.lat > 0 &&
                  telemetry.position.value.lon > 0
                ) {
                  const pos: MapCoordinates = {
                    lat: telemetry.position.value.lat,
                    lng: telemetry.position.value.lon,
                  };
                  if (
                    this.calculatekHaversineDistance(pos, this.lastPosition) >
                      this.minDistance ||
                    !this.lastPosition
                  ) {
                    if (this.getMarker() === null) {
                      this.addMarker({
                        position: pos,
                        options: this.markerOptions,
                      });
                      this.setCenter(pos);
                    } else {
                      this.updateMarkerPosition(pos);
                    }
                    this.lastPosition = pos;
                  }
                } else {
                  this.resetMarkers();
                }
                this.telemetry = telemetry;
              }
            },
            error: (error: any) => {
              this.error = true;
            },
          })
      );
    });
  }

  public ngAfterViewInit(): void {
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      this.createLocationControl()
    );
  }

  public createLocationControl(): HTMLElement {
    const controlButton = this.renderer.createElement('button');
    this.renderer.addClass(controlButton, 'gm-control-active');
    this.renderer.addClass(controlButton, 'gm-control-location');
    this.renderer.appendChild(
      controlButton,
      this.iconLocation._elementRef.nativeElement
    );
    controlButton.addEventListener('click', () => {
      this.toggleTracking();
    });
    return controlButton;
  }

  public calculatekHaversineDistance(
    currentPos: MapCoordinates,
    lastPos: MapCoordinates
  ): number {
    if (!currentPos || !lastPos) return null;
    const R = 6371.071; // Radius of the Earth in km
    const rlat1 = lastPos.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = currentPos.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflng = (currentPos.lng - lastPos.lng) * (Math.PI / 180); // Radian difference (longitudes)
    const distance =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflng / 2) *
              Math.sin(difflng / 2)
        )
      );
    return distance;
  }

  private addMarker(marker: any): void {
    this.markers.push(marker);
  }

  updateMarkerPosition(pos: any): void {
    if (this.markers.length > 0) {
      if (typeof this.mapMarker !== 'undefined') {
        this.animatedMove(
          this.mapMarker.marker,
          this.fleetService.interval,
          this.mapMarker.marker.getPosition(),
          pos
        );
      }
    }
  }

  // FPS
  // Recheck with https://stackoverflow.com/questions/6131051/is-it-possible-to-find-out-what-is-the-monitor-frame-rate-in-javascript
  // try to use requestAnimationFrame directly instead
  private getFPS(): Promise<number> {
    return new Promise((resolve, __reject) => {
      this.ngZone.runOutsideAngular(() => {
        let repaint = 0;
        const start = performance.now();
        const withRepaint = () => {
          requestAnimationFrame(() => {
            if (performance.now() - start < 1000) {
              repaint += 1;
              withRepaint();
            } else {
              resolve(repaint);
            }
          });
        };
        withRepaint();
      });
    });
  }

  public animatedMove(marker: any, t: number, current: any, moveto: any): void {
    this.ngZone.runOutsideAngular(() => {
      if (typeof current !== 'undefined') {
        const lat = current.lat();
        const lng = current.lng();
        let seconds = 1;
        // check how many seconds the inital delay has
        // has to be over 1s, please ensure in service config
        if (t > 1000) {
          seconds = t / 1000;
        }
        const frequence = this.fps * seconds;
        const delta = {
          lat: (moveto.lat - lat) / frequence,
          lng: (moveto.lng - lng) / frequence,
        };
        const delay = t / frequence;
        // Update frequently to animate
        for (let i = 0; i < frequence; i++) {
          this.trackMarker(i, delay, marker, delta);
        }
        this.ngZone.runOutsideAngular(() => {
          this.timeoutRef.push(this.slowPanTo(this.map, moveto, this.fps, t));
        });
      }
    });
  }

  // Custom function adapted from? https://stackoverflow.com/questions/9335150/slow-down-google-panto-function
  // nearly the same as upper movement of the marker, maybe extract that and handle panning and movement seperatly
  private slowPanTo(map, endPosition, n_intervals, T_msec): any {
    const getStep = delta => {
      return parseFloat(delta) / n_intervals;
    };
    const startPosition = map.getCenter();
    const lat_delta = endPosition.lat - startPosition.lat();
    const lng_delta = endPosition.lng - startPosition.lng();
    const lat_step = getStep(lat_delta);
    const lng_step = getStep(lng_delta);
    const lat_array = [];
    const lng_array = [];
    let i, j, ref;
    for (i = j = 1, ref = n_intervals; j <= ref; i = j += +1) {
      lat_array.push(map.getCenter().lat() + i * lat_step);
      lng_array.push(map.getCenter().lng() + i * lng_step);
    }
    const f_timeout = () => {
      return parseFloat(T_msec) / n_intervals;
    };
    const pan = (index: number) => {
      if (index < lat_array.length) {
        return setTimeout(() => {
          const target = new google.maps.LatLng({
            lat: lat_array[index],
            lng: lng_array[index],
          });
          // STOP PANNING AND RETURN NULL ON CONDITION
          if (this.autoPanOnHold$.getValue() === false) {
            map.panTo(target);
            return pan(index + 1);
          } else {
            return null;
          }
        }, f_timeout());
      }
    };
    // START PANNING
    return pan(0);
  }

  private trackMarker(
    frame: number,
    delay: number,
    marker: any,
    delta: any
  ): Promise<any> {
    return new Promise((resolve, __reject) =>
      this.ngZone.runOutsideAngular(() => {
        if (marker.getPosition().lat() > 0 && marker.getPosition().lng() > 0) {
          this.timeoutRef.push(
            setTimeout(() => {
              const newPos = {
                lat: marker.getPosition().lat() + delta.lat,
                lng: marker.getPosition().lng() + delta.lng,
              };
              marker.setPosition(newPos);
              resolve(true);
            }, delay * frame)
          );
        } else {
          reject('lat,lng equals 0, seems to be an error');
        }
      })
    );
  }

  getMarker(): any {
    if (this.markers.length > 0) {
      return this.markers[0];
    }
    return null;
  }

  setCenter(coordinate: MapCoordinates): void {
    this.center = {
      lat: coordinate.lat,
      lng: coordinate.lng,
    };
  }

  resetMarkers(): void {
    this.markers = new Array<any>();
  }

  toggleInfo(__marker?: MapMarker) {
    if (!this.infoWindowOpened) {
      this.infoWindow.open(this.mapMarker);
      this.infoWindowOpened = true;
    } else {
      this.infoWindow.close();
      this.infoWindowOpened = false;
    }
  }

  toggleTracking() {
    if (this.autoPanOnHold$.getValue() === true) {
      this.restartPaning();
    } else {
      this.stopPaning();
    }
  }

  stopPaning() {
    this.autoPanOnHold$.next(true);
  }

  restartPaning() {
    this.autoPanOnHold$.next(false);
  }

  killTimeouts(): void {
    this.timeoutRef.forEach(t => clearTimeout(t));
  }

  /**
   * Reset all in the fleetservice to cancel the polling request
   */
  ngOnDestroy(): void {
    this.killTimeouts();
    this.resetMarkers();
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
