// tslint:disable-next-line: no-namespace
export namespace Modal {
  export class Show {
    static readonly type = '[Core] Show Contact';
    constructor(public id: string) {}
  }
  export class Hide {
    static readonly type = '[Core] Hide Contact';
  }
}
