<div
  class="d-flex flex-column p-5 pt-2 overflow-auto configurator"
  *ngIf="(init$ | async) === true">
  <div class="p-0">
    <idm-fleet-configurator-steps></idm-fleet-configurator-steps>
  </div>
  <div class="pl-3 pr-3 h-100 w-100">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <div class="pt-5 pb-0">
    <idm-fleet-configurator-nav></idm-fleet-configurator-nav>
  </div>
</div>
