<div
  *ngIf="details; else placeholder"
  class="incidents-details"
  [@fade]="details">
  <h4 class="incident-localization-header">Incident Localization</h4>
  <div class="d-flex flex-row w-100">
    <div class="pr-2 w-50">
      <table class="table" aria-describedby="incident-localization-header">
        <th class="d-none" id="hidden-header-1"></th>
        <tbody>
          <tr>
            <td class="pl-0 col-key">Bogie</td>
            <td class="pr-0 col-val">
              {{ details?.mounting?.bogie }}
            </td>
          </tr>
          <tr>
            <td class="pl-0 col-key">Wheel</td>
            <td class="pr-0 col-val">
              {{ details?.mounting?.wheelPositionName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pl-2 pr-3 w-50">
      <table class="table" aria-describedby="incident-localization-header">
        <th class="d-none" id="hidden-header-2"></th>
        <tbody>
          <tr>
            <td class="pl-0 col-key">HD-TAG S/N</td>
            <td class="pr-0 col-val">
              {{ details?.tagSn }}
            </td>
          </tr>
          <tr>
            <td class="pl-0 col-key">HD-TAG BleID</td>
            <td class="pr-0 col-val">
              {{ details?.tag }}
            </td>
          </tr>
          <tr>
            <td class="pl-0 col-key">ProPM S/N</td>
            <td class="pr-0 col-val">
              {{ details?.device }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <h4 id="incident-details-header">Incident Details</h4>
  <div class="d-flex flex-row w-100">
    <div class="pr-2 w-50">
      <table class="table" aria-describedby="incident-details-header">
        <th class="d-none" id="hidden-header-3"></th>
        <tbody>
          <tr>
            <td class="pl-0 col-key">Incident Type</td>
            <td class="pr-0 col-val">
              {{ details?.type | titlecase | replace: '_':' ' }} >
              {{ details?.subtype | titlecase }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <h4>Incident Status</h4>
  <div class="d-flex flex-row w-100 pt-2">
    <div class="pr-2 w-50">
      <idm-incident-feedback
        [initData]="initDataConfirmation"
        (updateStateEvent)="setState($event)"
        [rejectDisabled]="rejectDisabled"></idm-incident-feedback>
    </div>
    <div class="pr-2 pl-2 w-50">
      <idm-incident-maintenance
        [initData]="initDataMaintenance"
        (updateStateEvent)="setState($event)"
        [disabled]="maintenanceDisabled"
        [minDate]="maintenanceMinDate"></idm-incident-maintenance>
    </div>
  </div>
  <h4>Attachments</h4>
  <div class="d-flex flex-row w-100 pt-2 pb-4">
    <div class="pr-2 w-50">
      <idm-file-uploader
        [context]="fileContext"
        [maxFileSize]="10000"
        [multipleUploadSupport]="false"></idm-file-uploader>
    </div>
    <div class="pr-2 pl-2 w-50">
      <idm-file-manager [context]="fileContext"></idm-file-manager>
    </div>
  </div>
</div>

<idm-incident-map
  [incidentType]="details?.subtype | titlecase"
  [class.d-none]="!details"
  [@fade]="details"></idm-incident-map>

<ng-template #placeholder>
  <div *ngIf="loading" [@fade]="!loading" class="mt-2 mr-4">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"
      [theme]="{ height: '150px' }"></ngx-skeleton-loader>

    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"
      [theme]="{ height: '200px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"
      [theme]="{ height: '400px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="pulse ? 'pulse' : false"
      class="w-100"
      [theme]="{ height: '300px' }"></ngx-skeleton-loader>
  </div>
</ng-template>
