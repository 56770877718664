import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private api = environment.mailApi + '/mails/contact';

  constructor(private http: HttpClient) {}

  public getRecipient(data: any): any {
    return this.http.post(this.api, data);
  }
}
