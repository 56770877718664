<ng-template #wheelName>
  <div class="wheel-name" [ngClass]="fieldPosition">
    <ng-container *ngIf="viewConfig.name.editable === true; else wheelNameOnly">
      <input
        type="text"
        class="form-control"
        [value]="model.wheel"
        [placeholder]="'Wheel ' + model.name"
        [ngModel]="model.wheel"
        [modelChangeDebounceTime]="500"
        [class.error]="error"
        (modelChangeDebounced)="onModelChange($event)" />
    </ng-container>
    <ng-template #wheelNameOnly>
      {{ model.wheel }}
    </ng-template>
  </div>
</ng-template>

<ng-template #tag>
  <div
    [ngbTooltip]="model?.tag?.bleId"
    tooltipClass="tag-tooltip"
    container="body">
    <idm-tag
      class="tag"
      [state]="model?.tag?.status?.type || TAG_STATE.notConfigured"
      [labelPosition]="fieldPosition"
      [class.clickable]="viewConfig.tag.editable"
      [class.replaceable]="viewConfig.tag.replaceable && !model?.tag?.status?.type"
      [ngClass]="fieldPosition"
      (click)="onTagClick()"></idm-tag>
  </div>
</ng-template>

<ng-template #wheel let-name="wheel">
  <div
    class="wheel"
    [class.action]="action"
    [class.suspicious]="
      (model?.shelling === true || model?.flatspot === true) &&
      viewConfig.state.visible
    "
    [class.clear]="model?.noIncident === true && viewConfig.state.visible"
    [class.maintained]="
      model?.maintenanceDone === true && viewConfig.state.visible
    "
    tooltipClass="wheel-tooltip"
    [ngbTooltip]="name"
    container="body"></div>
</ng-template>

<ng-template #states let-name="states">
  <div class="states" [ngClass]="fieldPosition">
    <span
      class="material-icons"
      [ngClass]="{
        inactive: !model?.maintenanceDone
      }"
      >build</span
    >
  </div>
</ng-template>

<div
  class="train-wheel text-center"
  [ngClass]="fieldPosition"
  [class.name]="viewConfig.name.visible || viewConfig.name.editable"
  [class.tag]="viewConfig.tag.visible || viewConfig.tag.editable">
  <ng-container *ngIf="fieldPosition.indexOf('top') > -1">
    <div class="wheel-wrapper">
      <ng-container *ngIf="viewConfig.state?.visible && !viewConfig.tag?.visible">
        <ng-container *ngTemplateOutlet="states"></ng-container>
      </ng-container>

      <ng-container *ngIf="viewConfig.tag?.visible && !viewConfig.name?.visible">
        <ng-container *ngTemplateOutlet="tag"></ng-container>
      </ng-container>

      <ng-container *ngIf="viewConfig.name?.visible && !viewConfig.tag?.visible">
        <ng-container *ngTemplateOutlet="wheelName"></ng-container>
      </ng-container>

      <ng-container *ngTemplateOutlet="wheel; context: model"></ng-container>
      <div class="wheel-slot">
        {{ model.name }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldPosition.indexOf('bottom') > -1">
    <div class="wheel-wrapper">
      <div class="wheel-slot">
        {{ model.name }}
      </div>
      <ng-container *ngTemplateOutlet="wheel; context: model"></ng-container>

      <ng-container *ngIf="viewConfig.name?.visible && !viewConfig.tag?.visible">
        <ng-container *ngTemplateOutlet="wheelName"></ng-container>
      </ng-container>

      <ng-container *ngIf="viewConfig.tag?.visible && !viewConfig.name?.visible">
        <ng-container *ngTemplateOutlet="tag"></ng-container>
      </ng-container>

      <ng-container *ngIf="viewConfig.state?.visible && !viewConfig.tag?.visible">
        <ng-container *ngTemplateOutlet="states"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
