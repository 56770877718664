import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'idm-cancel-modal',
  templateUrl: './cancel-modal.component.html',
})
export class CancelModalComponent {
  @Input() id: string;
  @Input() headline: string;
  @Input() description: string;

  @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onConfirm(): void {
    this.confirmed.emit(true);
  }

  public onCancel(): void {
    this.canceled.emit(true);
  }
}
