import { Component, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { Train, fadeInAndOutAnimation } from './../../../core';

import { IncidentsService } from './../../services/incidents.service';
import { delayWhen } from 'rxjs/operators';

@Component({
  selector: 'idm-incident-content',
  templateUrl: './incident-content.component.html',
  styleUrls: ['./incident-content.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class IncidentContentComponent implements OnInit {
  public train: Train;
  public incidentsCounter = -1;
  public incidents$: Observable<any> = new Observable<any>();

  private animationsDelay = 251;
  private delayFor = () => timer(this.animationsDelay);

  constructor(private incidentsService: IncidentsService) {}

  ngOnInit(): void {
    this.incidentsService.incidents$.subscribe(incidents => {
      this.incidentsCounter = incidents.length;
    });
    this.incidentsService.train$
      .pipe(delayWhen(this.delayFor))
      .subscribe(train => (this.train = train));
    this.incidents$ = this.incidentsService.incidents$;
  }

  resetFilter(): void {
    this.incidentsService.resetIncidentList();
  }
}
