<header>
  <nav class="navbar navbar-light bg-white fixed-top">
    <!-- Collapse button -->
    <button
      *ngIf="authenticated$ | async"
      type="button"
      class="navbar-toggler navbar-menu"
      (click)="toggleSidenav()">
      <span>
        <span class="material-icons" [class.d-none]="sidebar$ | async"
          >close</span
        >
        <span class="material-icons" [class.d-none]="!(sidebar$ | async)"
          >menu</span
        >
      </span>
    </button>

    <a class="navbar-brand">
      <img alt="ZF Logo" src="assets/images/zf-logo.svg" />
      <span>{{ title }}</span>
    </a>

    <ul class="navbar-nav ml-auto" *ngIf="!(authenticated$ | async)">
      <li class="nav-item mr-0 pr-0">
        <button type="button btn-home" class="btn btn-lg" routerLink="/">
          <span class="zf-icon-home"></span>
        </button>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" *ngIf="authenticated$ | async">
      <li class="nav-item header-icon">
        <span (click)="downloadIncidentsClicked($event)" #downloadIncidents ngbTooltip="Download incidents" class="material-icons-outlined">download_for_offline</span>
      </li>
      <li class="nav-item header-icon" routerLink="/admin/api-doc" *ngIf="((roles$ | async ) | roleFilter:'idm_admin') && environment.devMode === true">
        <span class="material-icons-outlined">api</span>
      </li>
      <li class="nav-item">
        <div
          [@fade]
          class="overlay spinner-border-avatar spinner-border"
          *ngIf="loading$ | async">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="avatar"><span></span></div>
      </li>
      <li class="nav-item user">
        {{ (realname$ | async) || (username$ | async) || (email$ | async) }}
        <br />
        <span class="user-tenant">{{ tenantName$ | async }}</span>
      </li>
      <li class="nav-item mr-0 pr-0">
        <button type="button btn-logout" class="btn btn-lg" (click)="logout()">
          <span class="zf-icon-logout"></span>
        </button>
      </li>
    </ul>
  </nav>
</header>

<div #incidentExportForm></div>
