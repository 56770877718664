<div class="d-flex h-100 w-100 overflow-hidden">
  <idm-sidebar-item-selector
    #sidebarItems
    scope="fleets"
    [deepLinkable]="true"
    [deepLink]="['maintenance', 'manager', 'vehicle']"
    (trainSelect)="trainSelected($event)"></idm-sidebar-item-selector>
  <idm-maintenance-content
    class="d-flex flex-row flex-grow-1 w-100 h-100"></idm-maintenance-content>
</div>
<div #unsavedChangesModal></div>
