import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DEVICE_STATE } from '@idm/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private api = environment.api + '/';

  static convertDeviceTarget(type: string): string {
    let path: string;
    switch (type) {
      case 'hdtag':
        path = 'tags';
        break;
      case 'propm':
      default:
        path = 'devices/byFilter';
    }
    return path;
  }

  constructor(private http$: HttpClient) {}

  getDevices(type: string): Observable<any> {
    return this.http$.get(this.api + DeviceService.convertDeviceTarget(type));
  }

  getDevicesByStatus(type: string, status: DEVICE_STATE): Observable<any> {
    return this.http$.get(
      this.api + DeviceService.convertDeviceTarget(type) + '?status=' + status
    );
  }

  getDevicesBySerial(type: string, sn: string): Observable<any> {
    return this.http$.get(
      this.api + DeviceService.convertDeviceTarget(type) + '?sn=' + sn
    );
  }
}
