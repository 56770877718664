<div class="container h-100 not-found" [class.fullscreen]="fullscreen">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="wrapper row">
      <div class="text-center device-error">
        <div class="container">
          <div class="row">
            <div
              class="text-center"
              [ngClass]="{ 'col-lg-6 text-lg-right': !customText }">
              <img
                src="assets/graphics/oops-404-robot.svg"
                class="oops-404-robot"
                alt="404 not found" />
            </div>
            <div
              class="col-lg-6 text-lg-left text-center my-lg-auto my-4"
              *ngIf="!customText">
              <h4>We are sorry!</h4>
              <h2>We cannot find the requested page (404)!</h2>
              <p>
                Please consider navigation to another path!<br />
                If the problem persists, please
                <a href="mailto:dppm.zf@zf.com">contact us</a>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
