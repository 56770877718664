import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SizeDetectorComponent } from './components/size-detector/size-detector.component';
import {
  FooterComponent,
  HeaderComponent,
  HttpStatusCodeInterceptor,
  HttpLoadingInterceptor,
  ImprintComponent,
  LoadingState,
  NotFoundComponent,
  FileUploaderComponent,
  FileManagerComponent,
  FileDragDropDirective,
  NotifcationComponent,
  NotificationService,
  NotificationsState,
  SidebarComponent,
  DataProtectionComponent,
  SidebarState,
  ReplacePipe,
  FileExtensionPipe,
  RoleFilterPipe,
  SpeedPipe,
  HeadingPipe,
  CoreService,
  ItemsService,
  ContactComponent,
  SidebarItemSelectorComponent,
  ContactService,
  TruncatePipe,
  ModelChangeDebouncedDirective,
  ModalState,
  ItemsState,
} from '.';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
  ErrorHandler,
} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { InlineSVGModule } from 'ng-inline-svg';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalErrorHandler } from './errors/global-error-handler';
import { TimeagoModule } from 'ngx-timeago';
import { DatepickerPopupComponent } from './components/datepicker-popup/datepicker-popup.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { BasicModalComponent } from './components/basic-modal/basic-modal.component';
import { CancelModalComponent } from './components/cancel-modal/cancel-modal.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

const routes: Routes = [
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'data-protection',
    component: DataProtectionComponent,
  },
];

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    NotifcationComponent,
    ImprintComponent,
    NotFoundComponent,
    SizeDetectorComponent,
    ReplacePipe,
    SpeedPipe,
    HeadingPipe,
    FileExtensionPipe,
    RoleFilterPipe,
    TruncatePipe,
    SidebarItemSelectorComponent,
    ContactComponent,
    DataProtectionComponent,
    DatepickerPopupComponent,
    FileUploaderComponent,
    FileManagerComponent,
    FileDragDropDirective,
    ModelChangeDebouncedDirective,
    BasicModalComponent,
    CancelModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    // Also important, has to be after all router implementations
    RouterModule.forChild(routes),
    InlineSVGModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    NgxsModule.forFeature([
      LoadingState,
      SidebarState,
      NotificationsState,
      ModalState,
      ItemsState,
    ]),
    TimeagoModule.forRoot(),
    NgbDatepickerModule,
    NgbTooltipModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    CoreService,
    ContactService,
    NotificationService,
    ItemsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpStatusCodeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    TimeagoModule,
    HeaderComponent,
    SidebarComponent,
    SidebarItemSelectorComponent,
    FooterComponent,
    NotifcationComponent,
    DatepickerPopupComponent,
    ImprintComponent,
    ContactComponent,
    SizeDetectorComponent,
    NotFoundComponent,
    FileManagerComponent,
    FileUploaderComponent,
    ReplacePipe,
    RoleFilterPipe,
    SpeedPipe,
    HeadingPipe,
    FileExtensionPipe,
    TruncatePipe,
    FileDragDropDirective,
    ModelChangeDebouncedDirective,
    BasicModalComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
    };
  }
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import CoreModule in the AppModule only.`
      );
    }
  }
}
