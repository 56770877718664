import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: any[], key?: string, value?: any): any[] {
    if (key && value) {
      return items.filter(item => {
        return item[key] === value;
      });
    }
    return items;
  }
}
