<div class="w-100 text-right mt-5" *ngIf="done === false">
  <a
    class="btn btn-outline-primary ml-3"
    role="button"
    aria-disabled="true"
    (click)="onCancelClick(); (false)"
    >Cancel</a
  >
  <a
    href="#"
    [class.disabled]="!prevActive"
    class="btn btn-primary ml-3"
    role="button"
    aria-disabled="true"
    (click)="previous(); (false)"
    >&lt; Previous Step</a
  >
  <a
    href="#"
    [class.d-none]="finalStep"
    [class.disabled]="!nextActive"
    class="btn btn-primary ml-3"
    role="button"
    aria-disabled="true"
    (click)="next(); (false)"
    >Next Step &gt;</a
  >

  <a
    href="#"
    [class.d-none]="!finalStep"
    class="btn btn-primary ml-3"
    role="button"
    aria-disabled="true"
    [class.disabled]="!valid"
    (click)="onSaveClick(); (false)"
    >Save Configuration</a
  >
</div>
<div #cancelModal></div>
