<div class="train-bogie-wrapper pr-2 pl-2 pt-3 pb-3">
  <div class="train-bogie w-100 h-100">
    <div class="bogie-name h-100 w-100 d-flex align-self-center">
      <div class="my-auto w-100 text-center">{{ model.name }}</div>
    </div>
    <div
      class="d-flex text-center axle flex-wrap"
      [ngClass]="'axle-' + model.slots.length / 2">
      <div
        *ngFor="let wheel of model.slots; let slot = index"
        class="text-center wheel">
        <idm-train-wheel
          [slot]="slot"
          [model]="wheel"
          [viewConfig]="viewConfig?.wheel"
          [fieldPosition]="
            (slot < model.slots.length / 2 ? 'top' : 'bottom') +
            ' ' +
            (model.slots.length / 2 > 1
              ? slot % (model.slots.length / 2) === 0
                ? 'left'
                : 'right'
              : '')
          "
          (modelChanged)="onModelChange($event)"
          (tagClicked)="onTagClick($event)"></idm-train-wheel>
      </div>
    </div>
  </div>
</div>
