import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import { GatewayComponent } from './components/train/devices/gateway/gateway.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TagComponent } from './components/train/devices/tag/tag.component';
import { TrainActionsComponent } from './components/train/train-actions/train-actions.component';
import { TrainBogieComponent } from './components/train/train-bogie/train-bogie.component';
import { TrainComponent } from './components/train/train.component';
import { TrainGatewaysComponent } from './components/train/train-gateways/train-gateways.component';
import { TrainWheelComponent } from './components/train/train-wheel/train-wheel.component';

@NgModule({
  declarations: [
    TrainComponent,
    TrainBogieComponent,
    TrainWheelComponent,
    TrainGatewaysComponent,
    TrainActionsComponent,
    TagComponent,
    GatewayComponent,
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    InlineSVGModule.forRoot(),
  ],
  exports: [
    TrainComponent,
    TrainBogieComponent,
    TrainWheelComponent,
    TrainGatewaysComponent,
    TrainActionsComponent,
    TagComponent,
    GatewayComponent,
  ],
})
export class VehiclesModule {}
