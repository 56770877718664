import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './../iam';
import { CanExitGuard } from './../core/guards/can-exit.guard';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { MaintenanceContentComponent } from './components/maintenance-content/maintenance-content.component';
import { MaintenanceFeedbackComponent } from './components/maintenance-feedback/maintenance-feedback.component';
import { MaintenanceManagementComponent } from './components/maintenance-management/maintenance-management.component';
import { MaintenanceService } from './services/maintenance.service';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { VehiclesModule } from './../vehicles';

const routes: Routes = [
  {
    path: 'maintenance/manager/vehicle/:id',
    component: MaintenanceManagementComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [CanExitGuard],
    data: {
      role: ['idm_user', 'idm_admin'],
    },
  },
];

@NgModule({
  declarations: [
    MaintenanceContentComponent,
    MaintenanceManagementComponent,
    MaintenanceFeedbackComponent,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgbModule,
    NgbTooltipModule,
    CommonModule,
    CoreModule,
    VehiclesModule,
  ],
  providers: [MaintenanceService],
})
export class MaintenanceModule {}
