<div
  [@fade]
  class="visibility-wrapper h-100 w-100"
  *ngIf="(modal$ | async).id === id">
  <div class="click-wrapper h-100 w-100" (click)="hideModal()"></div>
  <div class="modal-fullscreen h-100 w-100">
    <div class="d-flex align-items-center justify-content-center h-100">
      <div class="d-flex flex-column modal-container p-5">
        <div class="close p-2" (click)="hideModal()">
          <span class="zf-icon-close"></span>
        </div>

        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
