import { environment } from '@environment/environment';
import { CoreService } from './../../services/core.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '@idm/iam';

@Component({
  selector: 'idm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public authenticated$: Observable<boolean>;
  public env = environment;
  public version: string;
  public today: Date = new Date();

  constructor(private store: Store, private coreService: CoreService) {
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );
  }

  ngOnInit(): void {
    this.version = this.coreService.version;
  }
}
