<div class="d-flex flex-column w-100 h-100">
  <div>
    <p class="train-config-step-info">
      Please enter the details of the HD-TAGs to the positions by clicking the
      corresponding HD-TAG symbol.
    </p>
  </div>
  <div class="flex-grow-1 d-flex w-100 flex-column">
    <idm-train
      class="w-100"
      [model]="model"
      [viewConfig]="trainViewConfiguration"
      (tagClicked)="onTagClick($event)"></idm-train>
  </div>
</div>

<div #addModal></div>
