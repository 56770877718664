<div>
  <p class="train-config-step-info">
    In this step the activated Gateways will be connected and booted. Click the
    button below to start the Connection. Please take that the Gateways are
    powered and antennas are mounted. If the conncetion can not be estabished
    within 2 minutes, find more information about a troubleshooting in the
    online manual or contact the service team via the online form.
  </p>
</div>
<div>
  <idm-train
    [model]="model"
    [viewConfig]="trainViewConfiguration"
    (connectGatewaysClicked)="onConnectGatwaysClicked()"></idm-train>
</div>
