import { Authentication, AuthenticationState } from './../../../iam';
import {
  Component,
  ElementRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { IncidentsService } from './../../../incidents/services/incidents.service';
import { Observable } from 'rxjs';
// Module
import { Sidebar } from '../../store/sidebar/sidebar.actions';
import { environment } from '../../../../environments/environment';
import { fadeInAndOutAnimation } from '../../../core/animations/fade-in-and-out.animation';
import { first } from 'rxjs/operators';

@Component({
  selector: 'idm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class HeaderComponent {
  public title = environment.title;

  public opened$: Observable<any>;
  public route$: Observable<any>;
  public authenticated$: Observable<boolean>;

  public environment = environment;

  @Select((state: any) => state.loading.running) loading$: Observable<any>;
  @Select((state: any) => state.sidebar.collapsed) sidebar$: Observable<any>;
  @Select((state: any) => state.authentication.username)
  username$: Observable<any>;
  @Select((state: any) => state.authentication.realname)
  realname$: Observable<any>;
  @Select((state: any) => state.authentication.tenantName)
  tenantName$: Observable<any>;
  @Select((state: any) => state.authentication.email) email$: Observable<any>;
  @Select((state: any) => state.authentication.roles)
  roles$: Observable<any>;

  @ViewChild('incidentExportForm', { read: ViewContainerRef })
  incidentExportForm: ViewContainerRef;

  constructor(
    private store: Store,
    private incidentsService: IncidentsService
  ) {
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );
  }

  logout(): void {
    this.store.dispatch(new Authentication.Logout());
  }

  collapseSidenav(): void {
    this.store.dispatch(new Sidebar.Collapse());
  }

  expandSidenav(): void {
    this.store.dispatch(new Sidebar.Expand());
  }

  toggleSidenav(): void {
    this.sidebar$.pipe(first()).subscribe(collapsed => {
      if (!collapsed) {
        return this.collapseSidenav();
      }
      this.expandSidenav();
    });
  }

  downloadIncidentsClicked(event: PointerEvent): void {
    this.incidentsService.createExportForm(this.incidentExportForm, event);
  }
}
