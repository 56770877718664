// Build information, automatically generated by `ng-info`
const build = {
    version: "3.4.1",
    timestamp: "Mon Apr 24 2023 10:42:25 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "idm-master",
        hash: "43a513",
        fullHash: "43a513ff7a59d9f0d33aba0bdf1a13f395f60fb7"
    }
};

export default build;