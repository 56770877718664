import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Authentication } from '../store/authentication/authenticaton.actions';
import { AuthenticationState } from '../store/authentication/authentication.state';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthenticationToken(request);
    //
    // IMPORTANT: This is the ajax request interceptor for handling the jwt access token
    // for route safeguarding, check the AuthGuard!
    return next.handle(this.addAuthenticationToken(request));
  }

  private addAuthenticationToken(request): HttpRequest<any> {
    // Get access token from Local Storage
    const token: string = this.store.selectSnapshot(
      AuthenticationState.accessToken
    );
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!token) {
      return request;
    }
    if (
      this.hasGuard(['canActivate', 'canActivateChild'], 'AuthenticationGuard')
    ) {
      if (!this.validateToken(token)) {
        this.logout();
        return request;
      }
    }
    // We clone the request, because the original request is immutable
    if (request.method === 'JSONP') return request;
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private validateToken(__token: string): boolean {
    return this.store.selectSnapshot(AuthenticationState.isValid)(moment());
  }

  private logout(): void {
    this.store.dispatch(new Authentication.Logout());
  }

  private hasGuard(guardTypeArray: string[], guardName: string): boolean {
    const hasGuard = false;
    const currentRouteConfig = this.router.config.find(route => {
      const path = route.path.split(':')[0];
      return this.router.url.indexOf(path) > -1;
    });
    if (currentRouteConfig) {
      for (const guardType of guardTypeArray) {
        if (!hasGuard && currentRouteConfig[guardType]) {
          for (const guard of currentRouteConfig[guardType]) {
            this.checkRestrictions(guard, guardName);
          }
        } else {
          break;
        }
      }
    }
    return hasGuard;
  }

  private checkRestrictions(guard: any, guardName: string): boolean {
    return guard.name === guardName;
  }
}
