import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'speed' })
export class SpeedPipe implements PipeTransform {
  transform(value: number): number {
    if (!value) {
      return value;
    }
    return value * 3.6;
  }
}
