import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../../vehicles';

import { FleetConfigurationService } from './../../../../fleet/services/fleet-configuration.service';
import { Subscription } from 'rxjs';
import { TrainExistsValidator } from './../../../../fleet/validators/train-exists.validator';
import { fadeInAndOutAnimation } from './../../../../core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'idm-fleet-configurator-edit-train',
  templateUrl: './fleet-configurator-edit-train.component.html',
  styleUrls: ['./fleet-configurator-edit-train.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class FleetConfiguratorEditTrainComponent implements OnInit, OnDestroy {
  private subs: Array<Subscription> = new Array<Subscription>();

  public trainExists = false;
  public trainNameMinLength = 3;
  public trainNameMaxLength = 25;
  public editTrainForm: FormGroup;
  public trainTypes: Array<TrainConfiguration> =
    new Array<TrainConfiguration>();
  public fleets: Array<any> = new Array<any>();
  public model: TrainConfiguration;

  public trainViewConfiguration: TrainViewConfiguration = {
    gateways: {
      editable: false,
      visible: false,
      counter: false,
    },
    bogie: {
      wheel: {
        name: {
          editable: false,
          visible: false,
        },
        tag: {
          editable: false,
          replaceable: false,
          visible: false,
        },
      },
    },
  };

  constructor(
    private trainExistsValidator: TrainExistsValidator,
    private fleetConfigurationService: FleetConfigurationService
  ) {}
  ngOnInit(): void {
    this.generateForm();
    this.populateFormElements();
    this.registerFieldSubsribers();
    this.registerConfigurationSubscribers();
    this.registerFormSubscribers();
  }

  private generateForm(): void {
    this.editTrainForm = new FormGroup({
      trainName: new FormControl(
        '',
        // sync
        [
          Validators.required,
          Validators.minLength(this.trainNameMinLength),
          Validators.maxLength(this.trainNameMaxLength),
        ],
        // async
        [this.trainExistsValidator.validate()]
      ),
      trainType: new FormControl('', [Validators.required]),
      fleet: new FormControl('', [Validators.required]),
    });
  }

  private populateFormElements(): void {
    this.fleetConfigurationService
      .getTrainTypes()
      .pipe(first())
      .subscribe(
        (types: Array<TrainConfiguration>) => (this.trainTypes = types)
      );
    this.fleetConfigurationService
      .getFleets()
      .pipe(first())
      .subscribe((fleets: Array<string>) => {
        this.fleets = fleets;
      });
  }

  private registerFieldSubsribers(): void {
    this.subs.push(
      this.trainType.valueChanges.subscribe(type => {
        const foundType = this.trainTypes.find(types => {
          return types.name === type;
        });
        if (foundType) {
          this.fleetConfigurationService.setAttribute('type', foundType.name);
          this.fleetConfigurationService.setAttribute(
            'trainStructure',
            foundType.trainStructure
          );
        }
      })
    );
    this.subs.push(
      this.trainName.valueChanges.subscribe(name => {
        this.fleetConfigurationService.setAttribute('name', name);
      })
    );
    this.subs.push(
      this.fleet.valueChanges.subscribe(fleet => {
        this.fleetConfigurationService.setAttribute('fleet', fleet);
        const found = this.fleets.find(f => f.id === fleet);
        if (found && typeof found !== 'undefined') {
          this.fleetConfigurationService.setAttribute('fleetName', found?.name);
        }
      })
    );
  }

  private registerConfigurationSubscribers(): void {
    this.subs.push(
      this.fleetConfigurationService
        .getConfiguration()
        .subscribe((config: TrainConfiguration) => {
          this.model = config;
          if (config?.name) {
            this.editTrainForm.patchValue({
              trainName: config.name,
              trainType: config.type,
              fleet: config.fleet,
            });
          }
        })
    );
  }

  private registerFormSubscribers(): void {
    this.subs.push(
      this.editTrainForm.statusChanges.subscribe(status => {
        if (status === 'PENDING' || status === 'INVALID') {
          this.fleetConfigurationService.error$.next('Max Length');
        } else {
          this.fleetConfigurationService.error$.next(null);
        }
      })
    );
  }

  // Template getter functions

  get trainName() {
    return this.editTrainForm.get('trainName');
  }

  get trainType() {
    return this.editTrainForm.get('trainType');
  }

  get fleet() {
    return this.editTrainForm.get('fleet');
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
