<div *ngIf="details" class="d-flex flex-column p-5" [@fade]="details">
  <div
    class="d-flex flex-row w-100"
    *ngIf="details.trainDetails"
    [@fade]="details.trainDetails">
    <div class="pr-4 w-50">
      <h4 id="train-details-header">Train details</h4>
      <div class="w-100">
        <table class="table" aria-describedby="train-details-header">
          <th class="d-none" id="hidden-header-2"></th>
          <tbody>
            <tr>
              <td class="pl-0 col-key">Name</td>
              <td class="pr-0 col-val">
                {{ details.trainDetails.name }}
              </td>
            </tr>
            <tr>
              <td class="pl-0 col-key">Traintype</td>
              <td class="pr-0 col-val">
                {{ details.trainDetails.type | replace: '_':'/' }}
              </td>
            </tr>
            <tr>
              <td class="pl-0 col-key">Number of active incidents</td>
              <td class="pr-0 col-val">
                {{ details.trainDetails.incidentsNumber }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="w-50 pl-4 pr-1" *ngIf="details.trainStructureOverview">
      <h4 id="train-structure-header">Train structure</h4>
      <div class="w-100">
        <table class="table" aria-describedby="train-structure-header">
          <th class="d-none" id="hidden-header-1"></th>
          <tbody>
            <tr>
              <td class="pl-0 col-key">Number of bogies</td>
              <td class="pr-0 col-val">
                {{ details.trainStructureOverview.numberBogies }}
              </td>
            </tr>
            <tr>
              <td class="pl-0 col-key">Number of wheels</td>
              <td class="pr-0 col-val">
                {{ details.trainStructureOverview.numberWheels }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <idm-fleet-map class="d-flex flex-row w-100"></idm-fleet-map>
</div>
