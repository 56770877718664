import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken } from '@ngxs/store';
import { Loading } from './loading.action';

export interface LoadingStateModel {
  running: boolean;
  type?: string;
  source?: string;
}
const INITIAL_STATE: LoadingStateModel = { running: false };
const LOADING_STATE_TOKEN = new StateToken<LoadingStateModel>('loading');

@State<LoadingStateModel>({
  name: LOADING_STATE_TOKEN,
  defaults: INITIAL_STATE,
})
@Injectable()
export class LoadingState {
  @Action(Loading.Start)
  start(
    { patchState }: StateContext<LoadingStateModel>,
    { payload }: Loading.Start
  ): void {
    patchState({ running: true, source: payload.source, type: payload.type });
  }

  @Action(Loading.End)
  end({ patchState }: StateContext<LoadingStateModel>): void {
    patchState({ running: false });
  }
}
