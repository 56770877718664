<p class="train-config-step-info">
  The last step is to check the connection of the HD-Tags to the assigned
  gateway. If the conncetion can not be estabished within 2 minutes, find more
  Information about a troubleshooting in the online manual or contact the
  service team via the online form.
</p>

<div>
  <idm-train
    [model]="model"
    [viewConfig]="trainViewConfiguration"
    (connectTagsClicked)="onConnectTagsClicked()"></idm-train>
</div>
