import { BehaviorSubject, Subject } from 'rxjs';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FleetConfigurationService,
  tagConfiguredValidator,
  tagReplacementConflictValidator,
} from './../../../../fleet';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { delay, first } from 'rxjs/operators';

import { Modal } from './../../../../core';
import { Store } from '@ngxs/store';
import { TAG_ACTION_TYPES } from './../../../enums/tag-action-types.enum';
import { TagActivationForm } from './../../../../vehicles';

@Component({
  selector: 'idm-fleet-configurator-edit-tags-modal',
  templateUrl: './fleet-configurator-edit-tags-modal.component.html',
  styleUrls: ['./fleet-configurator-edit-tags-modal.component.scss'],
})
export class FleetConfiguratorEditTagsModalComponent
  implements OnInit, OnDestroy
{
  @Input() gateways: Array<string>;
  @Input() wheel: string;
  @Input() bogie: string;
  @Input() bleId: string;
  @Input() gateway: string;
  @Input() id: string;
  @Input() actionType: TAG_ACTION_TYPES = TAG_ACTION_TYPES.new;

  @Output() selected: EventEmitter<TagActivationForm> =
    new EventEmitter<TagActivationForm>();
  @Output() removed: EventEmitter<TagActivationForm> =
    new EventEmitter<TagActivationForm>();

  public tagForm: FormGroup;
  public error: string = null;
  public configured = false;

  private ngUnsubscribe$: Subject<boolean> = new Subject();
  public saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public scanActive = false;
  private scanInput: string = null;

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (this.scanActive === true) {
      if (event.key === 'Enter') {
        this.setMac(this.parseTagQrCode(this.scanInput)).then(
          () => (this.scanInput = null)
        );
      } else {
        this.scanInput += event.key;
      }
    }
  }

  constructor(
    private store: Store,
    private fleetConfigurationService: FleetConfigurationService
  ) {}

  ngOnInit(): void {
    // Checks if gateway and bleId has been set, in that case, the slot has already been configured
    // and we have to remove it first.
    this.configured = this.gateway !== '' && this.bleId !== '';
    this.tagForm = new FormGroup({
      wheel: new FormControl(this.wheel),
      bogie: new FormControl(this.bogie),
      mac: new FormControl(
        this.actionType === TAG_ACTION_TYPES.new ? this.bleId : '',
        {
          validators: [
            Validators.required,
            Validators.pattern('^([0-9a-fA-F]{2}[:]){5}[0-9a-fA-F]{2}$'),
            tagConfiguredValidator(this.gateways),
            TAG_ACTION_TYPES.replace
              ? tagReplacementConflictValidator(this.bleId)
              : null,
          ],
        }
      ),
      gateway: new FormControl(this.gateway),
    });
    // Set default value for gateways combobo if only one is present
    if (this.gateways.length === 1) {
      this.tagForm.get('gateway').setValue(this.gateways[0]['serial']);
    }
  }

  submit(data: TagActivationForm): void {
    this.saving$.next(true);
    switch (this.actionType) {
      case 'NEW':
        this.registerNewTag(data);
        break;
      case 'REPLACE':
        this.replaceExitingTag(data);
        break;
      default:
        this.saving$.next(false);
    }
  }

  registerNewTag(data: TagActivationForm): void {
    this.fleetConfigurationService
      .getTagActivation(data.gateway, data.mac)
      .pipe(delay(1000), first())
      .subscribe({
        next: () => {
          if (this.configured && this.actionType === TAG_ACTION_TYPES.new)
            this.remove(false);
          this.error = null;
          this.selected.emit(data);
          this.saving$.next(false);
          this.hide();
        },
        error: (error: any) => {
          console.log('ERROR', error);
          this.error =
            error.status !== 500
              ? error.message || error.msg
              : 'Internal server error - pairing of tag and VCU failed.';
          this.saving$.next(false);
        },
      });
  }

  replaceExitingTag(data: TagActivationForm): void {
    this.fleetConfigurationService
      .getTagReplacement(this.bleId, data.mac)
      .pipe(delay(1000), first())
      .subscribe({
        next: () => {
          if (this.configured && this.actionType === TAG_ACTION_TYPES.new)
            this.remove(false);
          this.error = null;
          this.selected.emit(data);
          this.saving$.next(false);
          this.hide();
        },
        error: (error: any) => {
          console.log('ERROR', error);
          this.error =
            error.status !== 500
              ? error.text
              : 'Internal server error - pairing of tag and VCU failed.';
          this.saving$.next(false);
        },
      });
  }

  hide(): void {
    this.store.dispatch(new Modal.Hide());
  }

  remove(hide = true): void {
    const data = this.tagForm.value;
    this.fleetConfigurationService
      .getTagDecomissioning(data.gateway, data.mac)
      .pipe(first())
      .subscribe({
        next: () => {
          this.error = null;
          this.removed.emit(data);
          if (hide) this.hide();
        },
        error: (error: any) => {
          this.error = error.text;
        },
      });
  }

  // Template getter functions

  get mac() {
    return this.tagForm.get('mac');
  }

  // https://itnext.io/how-to-use-a-bar-qr-code-scanner-with-angular-5622cefcb94
  public toggleScan(): void {
    if (!this.scanInput) {
      this.scanActive = !this.scanActive;
      this.setMac('', false);
    }
  }

  public parseTagQrCode(input: string): string {
    const regex = /BLE_(?:[\da-fA-F]{2}[:-]){5}[\da-fA-F]{2}/;
    const matches = input.match(regex);
    return matches?.length === 1 ? matches[0].replace('BLE_', '') : null;
  }

  private setMac(mac: string, touched = true): Promise<boolean> {
    return new Promise(resolve => {
      this.tagForm.get('mac').setValue(mac);
      if (touched === true) {
        this.tagForm.get('mac').markAsTouched();
      } else {
        this.tagForm.get('mac').markAsUntouched;
      }
      setTimeout(() => resolve(true), 1);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.saving$.complete();
  }
}
