import { Store } from '@ngxs/store';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Modules
import { NotificationMessageType } from './../models/notification-message-type.enum';
import { Notifications } from './../store/notifications/notifications.action';

@Injectable()
export class HttpStatusCodeInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          switch (event.status) {
            // OK
            case 200:
            // Created
            case 201:
              if (request.url.indexOf('auth/login') > -1) {
                this.store.dispatch(
                  new Notifications.AddNotification({
                    type: NotificationMessageType.success,
                    msg: 'You are logged in',
                  })
                );
              }
              break;
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const reason =
          error && error.error && error.error.reason ? error.error.reason : '';
        const status = error.status;

        switch (status) {
          // BAD REQUEST
          case 0:
            // Hide Network error 0 until CORS problems are fixed.
            break;
          case 400:
            this.store.dispatch(
              new Notifications.AddNotification({
                type: NotificationMessageType.error,
                msg: 'An error occured, please try again later',
              })
            );
            break;
          // UNAUTHORIZED
          case 401:
            this.store.dispatch(
              new Notifications.AddNotification({
                type: NotificationMessageType.error,
                msg: 'Please check your username or password',
              })
            );
            break;
          // NOT FOUND
          case 404:
            break;
          case 409:
            break;
          default:
            // NOTE: Temporarily deactivate Error Messages for tthe telemetry data adapter api because of stacking error messages
            if (request.url.indexOf('equipment') === -1) {
              this.store.dispatch(
                new Notifications.AddNotification({
                  type: NotificationMessageType.error,
                  msg:
                    'A network error ' +
                    status +
                    ' has occured, please contact your administrator: ' +
                    reason,
                })
              );
            }
        }
        return throwError(error);
      })
    );
  }
}
