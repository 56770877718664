import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  TrainConfiguration,
  TrainViewConfiguration,
} from './../../../../vehicles';

import { FleetConfigurationService } from './../../../../fleet/services/fleet-configuration.service';
import { fadeInAndOutAnimation } from './../../../../core';

@Component({
  selector: 'idm-fleet-configurator-edit-wheel',
  templateUrl: './fleet-configurator-edit-wheel.component.html',
  animations: [fadeInAndOutAnimation],
})
export class FleetConfiguratorEditWheelComponent implements OnInit, OnDestroy {
  private subs: Array<Subscription> = new Array<Subscription>();

  public trainViewConfiguration: TrainViewConfiguration = {
    bogie: {
      wheel: {
        name: {
          editable: true,
          visible: true,
        },
        tag: {
          editable: false,
          replaceable: false,
          visible: false,
        },
      },
    },
  };
  public model: TrainConfiguration;
  public error$: BehaviorSubject<string>;

  constructor(private fleetConfigurationService: FleetConfigurationService) {}

  ngOnInit(): void {
    this.error$ = this.fleetConfigurationService.error$;
    this.subs.push(
      this.fleetConfigurationService
        .getConfiguration()
        .subscribe(configuration => {
          this.model = configuration;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
