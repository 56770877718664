export class PositionsMaintenanceModel {
  wheelName: string;
  tagId: string;
  flatspot: boolean;
  shelling: boolean;
  noIncident: boolean;
  maintenanceDone: boolean;
}
export class MaintenanceStateModel {
  trainId: string;
  inspectionDate: string;
  repairDate: string;
  positions: Array<PositionsMaintenanceModel>;
}
