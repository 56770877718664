// tslint:disable-next-line: no-namespace
export namespace Sidebar {
  export class Expand {
    static readonly type = '[Core] Expand Sidenav';
  }
  export class Collapse {
    static readonly type = '[Core] Collapse Sidenav';
  }
  export class Refresh {
    static readonly type = '[Core] Refresh Sidenav';
  }
}
