<idm-basic-modal id="contact">
  <h3>Send Message</h3>
  <form [formGroup]="contactForm" (submit)="submit(contactForm.value)">
    <label for="type" class="mb-3">Category *</label>
    <select
      class="form-control custom-select mb-4"
      id="type"
      formControlName="type"
      required>
      <option value="" class="disabled" disabled selected>
        Select a category
      </option>
      <option value="business">Issue</option>
      <option value="support">Help</option>
      <option value="feature">Feature Request</option>
    </select>
    <label for="message" class="mb-3">Message *</label>
    <textarea
      class="form-control"
      id="message"
      rows="8"
      formControlName="message"
      maxlength="1000"
      required></textarea>
    <div class="w-100 d-flex justify-content-end mt-5">
      <button
        type="button"
        class="btn btn-secondary mr-4"
        (click)="hideContact()">
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [class.disabled]="!contactForm.valid"
        [disabled]="!contactForm.valid">
        Send
      </button>
    </div>
  </form>
</idm-basic-modal>
