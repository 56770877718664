import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { CoreService, ItemsService, Modal, Sidebar } from './../../../core';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { filter, takeWhile } from 'rxjs/operators';

import { AuthenticationState } from './../../../iam';

@Component({
  selector: 'idm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public authenticated$: Observable<boolean>;
  public version = 'n.a.';
  public initialized = false;
  public vehicleId$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public isFleets$: Subject<boolean> = new Subject();
  public isDevices$: Subject<boolean> = new Subject();
  public isMaintenance$: Subject<boolean> = new Subject();
  public isConfigurator$: Subject<boolean> = new Subject();

  @Select(state => state.sidebar.collapsed)
  sidebar$: Observable<boolean>;

  constructor(
    private store: Store,
    private itemsService: ItemsService,
    private router: Router,
    private coreService: CoreService,
    private ngZone: NgZone
  ) {
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );
    this.initializeDeepLinks().then(() => {
      this.initialized = true;
    });
  }

  ngOnInit(): void {
    this.version = this.coreService.version;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const isConfigurator = event.url.indexOf('configurator') > -1;
        const isFleets = event.url.indexOf('fleets') > -1;
        const isDevices = event.url.indexOf('devices') > -1;
        const isMaintenance = event.url.indexOf('maintenance') > -1;
        this.isConfigurator$.next(isConfigurator);
        this.isDevices$.next(isDevices && !isConfigurator);
        this.isFleets$.next(isFleets && !isConfigurator);
        this.isMaintenance$.next(isMaintenance);
      });
  }

  @HostListener('click', ['$event.target'])
  clickEvent(target: HTMLElement) {
    if (
      !this.store.selectSnapshot<boolean>(state => state.sidebar.collapsed) &&
      this.isLink(target)
    ) {
      this.ngZone.runOutsideAngular(() =>
        setTimeout(() => this.store.dispatch(new Sidebar.Collapse()), 100)
      );
    }
  }

  isLink(el: HTMLElement, level = 3): boolean {
    for (let link: HTMLElement | undefined | null = el; level > 0; level--) {
      if (link.tagName === 'A') return true;
      link = link.parentElement;
    }
    return false;
  }

  initializeDeepLinks(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.itemsService.vehicleId$
        .pipe(
          filter(vehicleId => vehicleId !== null),
          takeWhile(vehicleId => vehicleId !== null)
        )
        .subscribe({
          next: vehicleId => {
            this.vehicleId$.next(vehicleId);
            resolve('');
          },
          error: () => {
            reject('');
          },
        });
    });
  }

  hideContact(): void {
    this.store.dispatch(new Modal.Hide());
  }

  showContact(): void {
    this.store.dispatch(new Modal.Show('contact'));
  }
}
