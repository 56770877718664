import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '@idm/iam';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  private api = environment.api;
  private trainsApi = this.api + '/fm/trains';

  public authenticated$: Observable<boolean>;
  public vehicleId$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(private http$: HttpClient, private store: Store) {
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );
    this.authenticated$.subscribe(auth => {
      if (auth === true) {
        this.setVehicleId();
      }
    });
  }

  public getTrainsGroupedByFleet(): Observable<any> {
    return this.http$.get(this.trainsApi + '/groupedByFleet/byFilter');
  }

  private setVehicleId(): void {
    this.getTrainsGroupedByFleet()
      .pipe(first())
      .subscribe({
        next: fleets => {
          if (fleets !== null) {
            this.vehicleId$.next(fleets[0]?.trains[0]?.id);
          }
        },
      });
  }
}
