import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'idm-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss'],
})
export class SizeDetectorComponent {
  public hidden = false;

  constructor(private elementRef: ElementRef) {}

  public hide(): void {
    this.hidden = true;
  }
}
