import {
  trigger,
  transition,
  animate,
  style,
  query,
  stagger,
} from '@angular/animations';

/**
 * Use it in a list like:
 * [@listAnimation]="devices.length"
 * Start with the ngfor after that element
 */

export const listFadeInAnimation = trigger('listAnimation', [
  transition(':increment', [
    query(
      ':enter',
      [
        style({ opacity: 0 }), // initial
        stagger('25ms', animate('250ms ease-in', style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
  ]),
]);
