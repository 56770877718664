import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GATEWAY_STATE } from '@idm/fleet';

@Component({
  selector: 'idm-fleet-gateway',
  templateUrl: './fleet-gateway.component.html',
  styleUrls: ['./fleet-gateway.component.scss'],
})
export class FleetGatewayComponent {
  @Input() state: GATEWAY_STATE;
  @Input() serial: any;
  @Input() actions = false;
  @Input() counter = false;
  @Input() tags: Array<string> = new Array<string>();

  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  public onDeleteClick() {
    this.deleteClicked.emit(this.serial);
  }
}
