import { AfterViewInit, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AuthenticationState } from '@idm/iam';
// Module
import { NotificationMessageType } from './../../models/notification-message-type.enum';
import { NotificationsState } from './../../store/notifications/notifications.state';
import { NotificationService } from './../../services/notification.service';
import { NotificationMessage } from './../../models/notification-message.model';

@Component({
  selector: 'idm-notifcation',
  templateUrl: './notifcation.component.html',
  styleUrls: ['./notifcation.component.scss'],
  animations: [
    trigger('flyinout', [
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(300),
      ]),
      transition('* => void', [
        animate(300),
        style({ transform: 'translateY(-100%)' }),
      ]),
    ]),
    trigger('slideup', [
      state('true', style({})),
      transition('* <=> true, false <=> true', [
        animate(300),
        style({ transform: 'translateY(-100%)' }),
      ]),
    ]),
  ],
})
export class NotifcationComponent implements AfterViewInit {
  public messages: NotificationMessage[] = [];
  @Select((storeState: any) => storeState.sidebar.collapsed)
  sidebar$: Observable<any>;
  public authenticated$: Observable<any>;

  constructor(
    private notificationService: NotificationService,
    private store: Store
  ) {
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );
  }

  ngAfterViewInit(): void {
    this.watchNotifications();
  }

  watchNotifications(): void {
    this.store.select(NotificationsState.lastMessage).subscribe(msg => {
      if (msg) {
        this.tickerNotification(msg);
      }
    });
  }

  tickerNotification(item: NotificationMessage): void {
    const duration = this.notificationService.calcDuration(item.msg);
    const mutableMsg = {
      type: item.type,
      msg: item.msg,
    };
    this.messages.push(mutableMsg);
    setTimeout(
      () => {
        this.removeNotification(mutableMsg);
      },
      item.type !== NotificationMessageType.error ? duration : duration * 2
    );
  }

  getNotificationIndex(item: NotificationMessage): number {
    return this.messages.findIndex(element => element === item);
  }

  removeNotification(item: NotificationMessage): void {
    const index = this.getNotificationIndex(item);
    if (index !== -1) {
      this.messages[index].action = true;
      for (let i = 0; i < this.messages.length - 1; i = i + 1) {
        if (i >= index && this.messages[i]) {
          if (this.messages[i + 1].action !== true) {
            this.messages[i + 1].action = true;
          } else {
            this.messages[i + 1].action = false;
          }
        }
      }
      this.messages.splice(index, 1);
    }
  }

  clearNotifications(): void {
    this.messages = [];
  }
}
