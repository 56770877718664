import {
  trigger,
  transition,
  animate,
  style,
  query,
  stagger,
} from '@angular/animations';

/**
 * Use it in a list like:
 * [@listAnimation]="devices.length"
 * Start with the ngfor after that element
 */

export const listFadeInAndOutAnimation = trigger('listAnimation', [
  transition(':increment', [
    query(
      ':enter',
      [
        style({ opacity: 0 }), // initial
        stagger('30ms', animate('250ms ease-in', style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
  ]),
  transition(':decrement', [
    query(
      ':leave',
      [
        style({ opacity: 1 }), // initial
        animate('250ms ease-out', style({ opacity: 0 })),
      ],
      { optional: true }
    ),
  ]),
]);
