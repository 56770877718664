import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { FileContext } from '@idm/core/models/file-context.model';
import { FileExtensionPipe } from '../pipes/file-extension.pipe';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  private api = environment.api + '/am/attachments/';
  public downloadApi = this.api + 'byIncident/byFile';

  public fileObserver$ = new Subject<any>();
  public fileCounter$ = new BehaviorSubject<number>(0);

  static ucFirst(string: string): string {
    return string[0].toUpperCase() + string.substring(1);
  }

  constructor(private http: HttpClient) {}

  public addFiles(
    sourceFiles: File,
    context: FileContext,
    multiple = false
  ): Observable<unknown> {
    const files = new Array<any>();
    const formData = new FormData();
    if (multiple) {
      files.push(sourceFiles);
      files[0].forEach((__item, i) => {
        if (!files[0][i]['error']) {
          formData.append('data', files[0][i]);
        }
      });
    } else {
      if (!sourceFiles['error']) {
        formData.append('data', sourceFiles);
        formData.append('fileName', sourceFiles.name);
        formData.append(
          'contentType',
          sourceFiles.type ? sourceFiles.type : 'application/vnd.ms-outlook'
        );
      }
    }
    return formData.has('data')
      ? this.http
          .post(
            this.api +
              'by' +
              FileManagerService.ucFirst(context.source) +
              '?' +
              context.attribute +
              '=' +
              context.id,
            formData,
            {
              reportProgress: true,
              observe: 'events',
            }
          )
          .pipe(catchError(this.errorHandling))
      : null;
  }

  public getFileList(context: FileContext): Observable<unknown> {
    return this.http
      .get(
        this.api +
          'by' +
          FileManagerService.ucFirst(context.source) +
          '?' +
          context.attribute +
          '=' +
          context.id
      )
      .pipe(catchError(this.errorHandling));
  }

  public reportFile(file: File): void {
    this.fileObserver$.next(file);
  }

  public downloadFile(
    context: FileContext,
    fileName: string
  ): Observable<unknown> {
    return this.http
      .get<Blob>(
        this.api +
          'by' +
          FileManagerService.ucFirst(context.source) +
          '/byFile?' +
          context.attribute +
          '=' +
          context.id +
          '&fn=' +
          fileName,
        { observe: 'response', responseType: 'blob' as 'json' }
      )
      .pipe(catchError(this.errorHandling));
  }

  public deleteFile(
    context: FileContext,
    fileName: string
  ): Observable<unknown> {
    return this.http
      .delete(
        this.api +
          'by' +
          FileManagerService.ucFirst(context.source) +
          '/byFile?' +
          context.attribute +
          '=' +
          context.id +
          '&fn=' +
          fileName
      )
      .pipe(catchError(this.errorHandling));
  }

  public checkFileExtension(file: File, whitelist: Array<string>): boolean {
    const ext = new FileExtensionPipe().transform(file.name).toLowerCase();
    return whitelist.find(item => item === ext) ? true : false;
  }

  public errorHandling(error: HttpErrorResponse): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
