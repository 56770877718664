<ng-template #statePill>
  <div class="w-100 badge-wrapper">
    <div
      class="badge badge-pill badge-secondary"
      [class.badge-secondary]="state === 'NOT CONFIGURED'"
      [class.badge-success]="state === 'CONNECTED'"
      [class.badge-success]="state === 'ACTIVE'"
      [class.badge-warning]="state === 'RUNNING'"
      [class.badge-danger]="state === 'ERROR'"
      [class.badge-primary]="state === 'CONFIGURED'">
      {{ state | titlecase }}
    </div>
  </div>
</ng-template>

<div class="tag" [ngClass]="labelPosition">
  <ng-container *ngIf="labelPosition.indexOf('top') > -1">
    <ng-container *ngTemplateOutlet="statePill"></ng-container>
  </ng-container>
  <svg-icon
    [ngClass]="state | lowercase | replace: ' ':'-'"
    src="assets/graphics/icon-hdtag-wifi.svg"></svg-icon>
  <ng-container *ngIf="labelPosition.indexOf('bottom') > -1">
    <ng-container *ngTemplateOutlet="statePill"></ng-container>
  </ng-container>
</div>
