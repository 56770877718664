import { Injectable } from '@angular/core';
import { State, StateToken } from '@ngxs/store';

const ITEMS_STATE_TOKEN = new StateToken<any>('items');
@State<any>({
  name: ITEMS_STATE_TOKEN,
  defaults: null,
})
@Injectable()
export class ItemsState {}
