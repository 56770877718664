import { Component, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { FleetService } from './../../../fleet/services/fleet.service';
import { Router } from '@angular/router';
import { Train } from './../../../core';

@Component({
  selector: 'idm-fleet-management',
  templateUrl: './fleet-management.component.html',
})
export class FleetManagementComponent implements OnDestroy {
  public bottomToolbar = false;
  public scope: string = null;
  public tab = '';
  public isConfigurator$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private fleetService: FleetService, private router: Router) {
    if (this.router.url.indexOf('configurator') > -1) {
      this.isConfigurator$.next(true);
    } else {
      this.isConfigurator$.next(false);
    }
    if (this.router.url.indexOf('configuration') > -1) {
      this.scope = 'fleets';
      this.tab = 'configuration';
    } else if (this.router.url.indexOf('fleets') > -1) {
      this.bottomToolbar = true;
      this.scope = 'fleets';
      this.tab = 'info';
    } else if (this.router.url.indexOf('devices') > -1) {
      this.scope = 'devices';
      this.tab = 'inventory';
    }
  }

  public trainSelected(item: Train): void {
    this.fleetService.loadDetailsByVehicle(item);
  }

  public addClicked(back: string): void {
    this.fleetService.startConfiguration(back);
  }

  public ngOnDestroy(): void {
    this.fleetService.ngOnDestroy();
  }
}
