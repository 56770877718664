<ng-container
  *ngIf="{
    authenticated: authenticated$ | async
  } as marginState">
  <div
    [class.sidebar-collapsed]="marginState.authenticated"
    [class.sidebar-expanded]="!(sidebar$ | async) && marginState.authenticated"
    class="fixed-top notification-bar w-100">
    <div
      *ngFor="let message of messages; let i = index"
      class="d-flex justify-content-between d-flex justify-content-between notification"
      [ngStyle]="{ 'z-index': -i }"
      [ngClass]="message.type"
      [@flyinout]="message"
      [@slideup]="message.action">
      <span class="material-icons">{{
        message.type !== 'success' ? message.type : 'check_circle'
      }}</span>
      <p [innerHTML]="message.msg"></p>
      <a class="material-icons ml-auto" (click)="removeNotification(message)"
        >close</a
      >
    </div>
  </div>
</ng-container>
