import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  delayWhen,
} from 'rxjs/operators';
import { fromEvent, Observable, timer } from 'rxjs';
import { Select } from '@ngxs/store';
// Modules
import {
  ProPM,
  ProPMWrapper,
  HDTag,
  HDTagWrapper,
  DEVICE_STATE,
  listFlyInAndOutAnimation,
} from '@idm/core';
import { DeviceService } from './../../services/devices.service';

@Component({
  selector: 'idm-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss'],
  animations: [listFlyInAndOutAnimation],
})
export class DeviceManagementComponent implements OnInit {
  private defaultDevice = 'propm';
  private animationsDelay = 751; // ms

  public type: string;
  public devices: Array<HDTag | ProPM | ProPMWrapper | any> = [];
  public filter: any = 'all';

  public showFilter = true;
  public showSettings = false;
  public showSearch = true;

  public loading = true;
  public error = false;
  public lines = false;
  public colored = false;
  public shadow = false;

  public deviceStates = DEVICE_STATE;

  public deviceIcon = 'internet-of-things';

  @ViewChild('searchDeviceInput', { static: true })
  searchDeviceInput: ElementRef;
  @ViewChild('searchDeviceForm', { static: true })
  searchDeviceForm: ElementRef;

  @Select((s: any) => s.sidebar.visible) sidebar$: Observable<any>;

  static convertDeviceMapping(
    type: string,
    devices: any
  ): HDTag[] | ProPM[] | HDTagWrapper[] | ProPMWrapper[] {
    switch (type) {
      case 'hdtag':
        return devices;
      case 'propm':
      default:
        return devices.devices;
    }
  }

  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.type = params.type;
      if (!this.type) {
        this.router.navigate(['/devices/', this.defaultDevice]);
      } else {
        this.searchListener();
        this.setDeviceIcon();
        if (!this.route.snapshot.queryParams.sn) {
          this.loadAllDevices(this.type);
        }
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params.sn) {
        this.filterBySerial(params.sn);
      }
    });
  }

  private delayFor = () => timer(this.animationsDelay);

  private searchListener(): void {
    fromEvent(this.searchDeviceInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter(res => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
      )
      .subscribe((input: string) => {
        this.filterBySerial(input);
      });
    fromEvent(this.searchDeviceForm.nativeElement, 'submit').subscribe(
      (event: any) => {
        event.preventDefault();
      }
    );
  }

  setDeviceIcon(): void {
    if (this.router.url.includes('propm')) {
      this.deviceIcon = 'propm';
    } else if (this.router.url.includes('hdtag')) {
      this.deviceIcon = 'hdtag';
    }
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
    if (this.showFilter && this.showSettings) {
      this.showSettings = false;
    }
  }

  toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  toggleSettings(): void {
    this.showSettings = !this.showSettings;
    if (this.showSettings && this.showFilter) {
      this.showFilter = false;
    }
  }

  loadConnections(type: string, device: any): void {
    switch (type) {
      case 'hdtag':
        console.log('HDTAG not provided atm');
        break;
      case 'propm':
        this.router.navigate(['/devices/propm'], {
          queryParams: { sn: device.device },
        });
        break;
      default:
        console.log('TYPE not supported');
    }
  }

  loadAllDevices(__type: string): void {
    this.filter = 'all';
    this.devices = [];
    this.searchDeviceInput.nativeElement.value = '';
    this.loading = true;
    this.error = false;
    this.deviceService
      .getDevices(this.type)
      .pipe(delayWhen(this.delayFor))
      .subscribe(
        devices => this.converter(devices),
        error => this.onError(error)
      );
  }

  filterBySerial(sn: string): any {
    this.deviceService.getDevicesBySerial(this.type, sn).subscribe(
      devices => {
        this.error = false;
        this.filter = '';
        this.loading = false;
        this.devices = [];
        this.searchDeviceInput.nativeElement.value = sn;
        setTimeout(() => {
          this.devices = DeviceManagementComponent.convertDeviceMapping(
            this.type,
            devices
          );
        }, this.animationsDelay);
      },
      error => this.onError(error)
    );
  }

  filterByStatus(status: DEVICE_STATE): void {
    if (this.filter === status) {
      this.filter = 'all';
      this.loadAllDevices(this.type);
    } else {
      this.filter = status;
      this.devices = [];
      this.loading = true;
      this.error = false;
      this.searchDeviceInput.nativeElement.value = '';
      // delay 900ms because of animation
      this.deviceService
        .getDevicesByStatus(this.type, status)
        .pipe(delayWhen(this.delayFor))
        .subscribe(
          devices => this.converter(devices),
          error => this.onError(error)
        );
    }
  }

  private converter(devices: any): void {
    this.loading = false;
    this.devices = DeviceManagementComponent.convertDeviceMapping(
      this.type,
      devices
    );
  }

  onError(__error: any): void {
    this.devices = [];
    this.loading = false;
    this.error = true;
    this.filter = '';
  }
}
