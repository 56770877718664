import { Directive } from '@angular/core';
import {
  NG_ASYNC_VALIDATORS,
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { TrainExistsValidator } from '..';

@Directive({
  selector: '[trainExists]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TrainExistsDirective,
      multi: true,
    },
  ],
})
export class TrainExistsDirective implements AsyncValidator {
  constructor(private trainExistsValidator: TrainExistsValidator) {}
  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.trainExistsValidator.validate()(control);
  }
}
