import { FleetManagementComponent } from './../fleet/components/fleet-management/fleet-management.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../iam/guards/authentication.guard';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DeviceManagementComponent, DeviceService } from '@idm/devices';

const routes: Routes = [
  {
    path: 'devices',
    redirectTo: 'devices/manager',
    pathMatch: 'full',
  },
  {
    path: 'devices/manager',
    component: FleetManagementComponent,
    canActivate: [AuthenticationGuard],
    data: {
      role: ['idm_user', 'idm_admin'],
    },
  },
  {
    path: 'devices/:type',
    component: DeviceManagementComponent,
    canActivate: [AuthenticationGuard],
    data: {
      role: ['idm_user', 'idm_admin'],
    },
  },
];

@NgModule({
  declarations: [DeviceManagementComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    InlineSVGModule.forRoot(),
    // RouterModule.forChild(routes),
  ],
  exports: [DeviceManagementComponent],
  providers: [DeviceService],
})
export class DevicesModule {}
