<div
  class="m-4 p-0 incident-content d-flex flex-column mh-100 w-100 overflow-hidden">
  <div class="'d-flex flex-column mh-100 w-100">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="d-flex ml-4 mr-4 p-0">
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav mr-auto mt-2 mt-md-0">
            <li class="nav-item active">
              <a class="pr-3 pl-3 nav-link" href="#!"
                >Incidents
                <span class="sr-only">(current)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
      class="pr-4 pl-5 pt-3 pb-3 d-flex flex-row incident-content-header"
      *ngIf="train; else placeholder">
      <!-- Currently just a placeholder, remove? -->
      <div class="mr-3 content-header-image"></div>
      <div class="flex-column w-100">
        <h4 class="pb-0 mb-0" *ngIf="train?.name; else skeleton">
          {{ train?.name }}
        </h4>
        <p
          class="m-0 p-0 mt-2"
          *ngIf="train?.fleet; else skeleton"
          [@fade]="!train?.fleet">
          {{ train?.fleet }}
        </p>
      </div>
    </div>
    <div class="d-flex flex-grow-1 h-100 w-100 overflow-hidden incident-view">
      <idm-incident-list></idm-incident-list>
      <div
        class="flex-row incident-details-container p-4 w-100"
        *ngIf="incidents$ | async">
        <idm-incident-details></idm-incident-details>
      </div>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="pr-4 pl-5 pt-3 pb-3 d-flex flex-row incident-content-header">
    <!-- Currently just a placeholder, remove? -->
    <div class="mr-3 content-header-image"></div>
    <div class="flex-column w-100">
      <ng-container *ngTemplateOutlet="skeleton"></ng-container>
      <ng-container *ngTemplateOutlet="skeleton"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #skeleton>
  <div>
    <ngx-skeleton-loader
      #skeleton
      count="1"
      appearance="line"
      animation="pulse"
      [theme]="{
        width: '33%',
        'margin-bottom': '0',
        'margin-top': '5px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>
