<div class="container h-100 size-detector d-xl-none" *ngIf="!hidden">
  <!-- was d-xl-none, see problem eg. https://yesviz.com/devices/ipad-2020/-->
  <div class="row h-100 justify-content-center align-items-center">
    <div class="wrapper row">
      <div class="text-center device-error">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 text-lg-right text-center">
              <img
                src="assets/graphics/oops-404-robot.svg"
                class="oops-404-robot"
                alt="screen resolution not supported" />
            </div>
            <div class="col-lg-6 text-lg-left text-center my-lg-auto my-4">
              <h4>We are sorry!</h4>
              <h2>Your screen resolution is not yet supported!</h2>
              <p>
                Please consider upgrading to a minimum width of 1200px!<br />
                If the problem persists, please
                <a href="mailto:dppm.zf@zf.com">contact us</a>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
