import { BogieConfiguration } from '..';
export class TrainConfiguration {
  name?: string;
  fleet?: string;
  fleetName?: string;
  type?: string;
  // Maybe not necessary (not in blueprint and in configuration)
  tenant?: string;
  // Should be refactored
  trainStructure: {
    // Not necessary = bogies.length
    numberOfBogies?: number;
    bogies: Array<BogieConfiguration>;
  };
  gateways?: Array<any>;
}
