import { AuthenticationState } from '../../../iam/store/authentication/authentication.state';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
  selector: 'idm-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  public authenticated$: Observable<boolean>;
  public today: Date = new Date();

  constructor(private store: Store) {
    this.authenticated$ = this.store.select(
      AuthenticationState.isAuthenticated
    );
  }
}
