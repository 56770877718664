<div
  class="m-4 p-0 fleet-content mh-100 w-100 d-flex flex-column overflow-hidden">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="d-flex ml-4 mr-4 p-0">
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav mr-auto mt-2 mt-md-0 ml-4">
          <li class="nav-item" [class.active]="tab === 'info'">
            <a
              class="pr-3 pl-3 nav-link"
              (click)="setTab('info')"
              [class.disabled]="(isConfigurator$ | async) === true"
              >Info
            </a>
          </li>
          <li class="nav-item" [class.active]="tab === 'inventory'">
            <a
              class="pr-3 pl-3 nav-link"
              (click)="setTab('inventory')"
              [class.disabled]="(isConfigurator$ | async) === true"
              >Component Inventory
            </a>
          </li>
          <li class="nav-item" [class.active]="tab === 'configuration'">
            <a class="pr-3 pl-3 nav-link" (click)="setTab('configuration')"
              >Configuration
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Header will be the same? -->
  <div
    class="pr-4 pl-5 pt-3 pb-3 d-flex flex-row fleet-content-header"
    *ngIf="train; else placeholder">
    <!-- Currently just a placeholder, remove? -->
    <div class="mr-3 content-header-image"></div>
    <div class="flex-column w-100">
      <h4 class="pb-0 mb-0" *ngIf="train?.name; else skeleton">
        {{ train?.name }}
      </h4>
      <p
        class="m-0 p-0 mt-2"
        *ngIf="train?.fleet; else skeleton"
        [@fade]="!train?.fleet">
        {{ train?.fleet }}
      </p>
    </div>
  </div>

  <!-- This is were the tab content changes -->
  <div
    class="d-flex flex-grow-1 h-100 w-100 overflow-hidden fleet-details-container"
    *ngIf="tab">
    <!-- REFACTOR: Use routes for component mappings consequently-->
    <idm-fleet-details
      [class.d-none]="tab !== 'info'"
      class="w-100"></idm-fleet-details>
    <idm-fleet-inventory
      [class.d-none]="tab !== 'inventory'"
      class="w-100"></idm-fleet-inventory>
    <!-- Don't use ngif for the router-outlet, won't work-->
    <div [class.d-none]="!(isConfigurator$ | async)" class="w-100">
      <router-outlet></router-outlet>
    </div>

    <div
      [class.d-none]="tab !== 'configuration'"
      class="w-100"
      *ngIf="(isConfigurator$ | async) === false">
      <ng-container *ngIf="tab === 'configuration'">
        <idm-fleet-configuration> </idm-fleet-configuration>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="pr-4 pl-5 pt-3 pb-3 d-flex flex-row fleet-content-header">
    <!-- Currently just a placeholder, remove? -->
    <div class="mr-3 content-header-image"></div>
    <div class="flex-column w-100">
      <ng-container *ngTemplateOutlet="skeleton"></ng-container>
      <ng-container *ngTemplateOutlet="skeleton"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #skeleton>
  <div>
    <ngx-skeleton-loader
      #skeleton
      count="1"
      appearance="line"
      [animation]="(isConfigurator$ | async) === false ? 'pulse' : false"
      [theme]="{
        width: '33%',
        'margin-bottom': '0',
        'margin-top': '5px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>
