import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
// Module
import {
  Authentication,
  AuthenticationStateModel,
} from './authenticaton.actions';

import * as moment from 'moment';
import { AuthenticationService } from '@idm/iam/services/authentication.service';
const INITIAL_STATE: AuthenticationStateModel = {
  accessToken: null,
  refreshToken: null,
  iss: null,
  tenantName: null,
  tenantId: null,
  username: null,
  realname: null,
  email: null,
  roles: null,
  expires: null,
};
const AUTHENTICATION_STATE_TOKEN = new StateToken<AuthenticationStateModel>(
  'authentication'
);
@State<AuthenticationStateModel>({
  name: AUTHENTICATION_STATE_TOKEN,
  defaults: INITIAL_STATE,
})
// "iss": "https://im.zf-v2x.com/auth/realms/dppm-1a1a1a1a1a1a",
@Injectable()
export class AuthenticationState {
  @Selector()
  static accessToken(state: AuthenticationStateModel): string | null {
    return state.accessToken;
  }
  @Selector()
  static iss(state: AuthenticationStateModel): string | null {
    return state.iss;
  }
  @Selector()
  static tenantId(state: AuthenticationStateModel): string | null {
    return state.tenantId;
  }
  @Selector()
  static tenantName(state: AuthenticationStateModel): string | null {
    return state.tenantName;
  }
  @Selector()
  static username(state: AuthenticationStateModel): string | null {
    return state.username;
  }
  @Selector()
  static realname(state: AuthenticationStateModel): string | null {
    return state.realname;
  }
  @Selector()
  static email(state: AuthenticationStateModel): string | null {
    return state.email;
  }
  @Selector()
  static roles(state: AuthenticationStateModel): string[] | null {
    return state.roles;
  }
  @Selector()
  static refreshToken(state: AuthenticationStateModel): string | null {
    return state.refreshToken;
  }
  @Selector()
  static isAuthenticated(state: AuthenticationStateModel): boolean {
    return state.accessToken !== null;
  }
  @Selector()
  static isValid(state: AuthenticationStateModel): any {
    return (now: moment.Moment) => {
      return now.isBefore(moment.unix(state.expires));
    };
  }
  @Selector()
  static isRefreshable(state: AuthenticationStateModel): any {
    const jwt = new JwtHelperService();
    const refTokenDec = jwt.decodeToken(state.refreshToken);
    return (now: moment.Moment) => {
      return now.isBefore(moment.unix(refTokenDec.exp));
    };
  }

  constructor(private authenticationService: AuthenticationService) {}

  @Action(Authentication.Login)
  login(
    ctx: StateContext<AuthenticationStateModel>,
    action: Authentication.Login
  ): Observable<any> {
    return this.authenticationService.login(action.payload).pipe(
      tap(result => {
        ctx.patchState({
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          iss: result.iss,
          tenantName: result.tenantName,
          tenantId: result.tenantId,
          realname: result.realname,
          username: result.username,
          email: result.email,
          roles: result.realm_access?.roles,
          expires: result.expires,
        });
      })
    );
  }

  @Action(Authentication.Logout)
  logout(ctx: StateContext<AuthenticationStateModel>): void {
    ctx.patchState(INITIAL_STATE);
  }

  @Action(Authentication.Refresh)
  refresh(ctx: StateContext<AuthenticationStateModel>): void {
    this.authenticationService
      .refresh()
      .pipe(take(1))
      .subscribe((result: any) => {
        ctx.patchState({
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          expires: result.expires,
        });
      });
  }
}
