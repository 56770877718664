import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '../store/authentication/authentication.state';
import { Authentication } from '../store/authentication/authenticaton.actions';

import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate(): boolean {
    let accessToken = null;
    const authenticated: any = this.store.selectSnapshot(
      AuthenticationState.isValid
    )(moment());
    // There seems to be some kind of glitch with the NgXS Store Handling, it should be resetted on
    // start, but sometimes the old (but invalid) key is stored in the Local Storage, in this case
    // we currently log out the user and reset the local storage authentication entry
    const storage = JSON.parse(localStorage.getItem('authentication'));
    if (storage) {
      accessToken = storage.accessToken;
    }
    if (authenticated || accessToken !== null) {
      this.store.dispatch(Authentication.Logout);
    }
    return true;
  }
}
