<form class="form">
  <div class="form-group mb-0">
    <label
      class="mb-1"
      for="datepicker"
      *ngIf="label"
      [class.disabled]="disabled"
      >{{ label }}</label
    >
    <div class="input-group zf-datefield">
      <div class="input-group-append">
        <button
          class="btn calendar"
          (click)="datepicker.toggle()"
          type="button"
          [disabled]="disabled || disabledWithDate ? 'disabled' : null">
          <span class="material-icons"> event </span>
        </button>
      </div>
      <input
        class="form-control datepicker-input"
        [class.disabled]="disabled || disabledWithDate"
        placeholder="yyyy-mm-dd"
        name="datepicker"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [disabled]="disabled ? true : false"
        [startDate]="date"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [(ngModel)]="date"
        (dateSelect)="setDate($event)"
        readonly />
    </div>
  </div>
</form>
